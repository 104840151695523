export const EVENTS = {
	"ITEM" : {
		"CREATE" : {
			"OPEN" : "CREATEOPEN",
			"CANCEL" : "CREATECANCEL",
			"DONE" : "CREATEDONE",
			"VALUECHANGE" : "CREATEVALUECHANGE",
			"ADDCATEGORY" : "ADDCATEGORY"
		},
		"EDIT" : {
			"OPEN" : "ITEMEDITOPEN",
			"CANCEL" : "ITEMEDITCANCEL",
			"DONE" : "ITEMEDITDONE"
		},
		"OPEN" : "ITEMOPEN",
		"OPEN_PUBLIC" : "ITEMOPENPUBLIC",
		"OPEN_WITH_DESIGN" : "ITEMOPENWITHDESIGN",
		"SELECT" : "ITEMSELECT",
		"SELECTALL" : "ITEMSELECTALL",
		"SELECTOR" : "ITEMSELECTORSELECT",
		"DELETE" : "ITEMDELETE",
		"DELETE_BULK" : "ITEMDELETEBULK",
		"SAVE" : "ITEMSAVE",
		"CLOSE" : "ITEMCLOSE",
		"BREADCRUMBCLICK" : "BREADCRUMBCLICK",
		"SHARE" : {
			"OPEN" : "ITEMSHAREOPEN",
			"CLOSE" : "ITEMSHARECLOSE",
			"PRIVATE" : "ITEMSHAREPRIVATE", 
			"PUBLIC" : "ITEMSHAREPUBLIC" 
		},
		"UNSHARE" : {
			"PRIVATE" : "ITEMUNSHAREPRIVATE", 
			"PUBLIC" : "ITEMUNSHAREPUBLIC" 
		},
 		"PUBLISH" : {
			"OPEN" : "PUBLISHOPEN",
			"POST" : "PUBLISHPOST",
			"CLOSE" : "PUBLISHCLOSE",
			"RELOGIN" : "PUBLISHRELOGIN"
 		},
 		"ADDTOCOLLECTION" : {
 			"OPEN" : "ADDTOCOLLECTIONOPEN",
			"CANCEL" : "ADDTOCOLLECTIONCANCEL",
			"DONE" : "ADDTOCOLLECTIONDONE",
 		},
 		"ADDTOCATALOG" : {
 			"OPEN" : "ADDTOCATALOGOPEN",
			"CANCEL" : "ADDTOCATALOGCANCEL",
			"DONE" : "ADDTOCATALOGNDONE",
 		},
 		"TAB" : {
 			"EDIT" : "TABEDIT" 
 		},
 		"PRINT" : "ITEMPRINT",
		"CLONE" : "ITEMCLONE",
		"CHANGE" : {
			"CATEGORY" : "CHANGECATEGORY"
		},
		"PROFILEIMAGE" : {
			"ADD" : "ADDPROFILEIMAGE",
			"REMOVE" : "REMOVEPROFILEIMAGE",
			"CHANGE" : "CHANGEPROFILEIMAGE"
		}
	},
	"PREDICATE" : {
		"VALUECHANGE" : "PREDICATEVALUECHANGE",
		"ADDITEM" : {
			"OPEN" : "ADDITEMOPEN",
			"CANCEL" : "ADDITEMCANCEL",
			"START" : "ADDITEMSTART",
			"EXECUTE" : "ADDITEMEXECUTE",
			"DONE" : "ADDITEMDONE",
			"REVERSE" : {
				"EXECUTE" : "ADDITEMREVERSEEXECUTE"
			}
		},
		"REMOVEITEM" : {
			"OPEN" : "REMOVEITEMOPEN",
			"START" : "REMOVEITEMSTART",
			"EXECUTE" : "REMOVEITEMEXECUTE",
			"DONE" : "REMOVEITEMDONE",
			"REVERSE" : {
				"EXECUTE" : "REMOVEITEMREVERSEEXECUTE"
			}
		},
		"MOVEITEM" : "MOVEITEM",
		"ADDIMAGE" : {
			"OPEN" : "ADDIMAGEOPEN",
			"DONE" : "ADDIMAGEDONE",
			"CANCEL" : "ADDIMAGECANCEL"
		},
		"EDITIMAGE" : {
			"DONE" : "EDITIMAGEDONE"
		},
		"REMOVEIMAGE" : {
			"DONE" : "REMOVEIMAGEDONE"
		},
		"SELECTIMAGE" :{
			"DONE" : "SELECTIMAGEDONE",
			"CANCEL" : "SELECTIMAGECANCEL"
		},
		"SUFFLEITEM" : {
			"EXECUTE" : "SUFFLEITEMEXECUTE",
			"DONE" : "SUFFLEITEMDONE"
		},
		"CHARACTERISTIC" : {
			"ADD" : "ADDCHARACTERISTIC"
		}
	},
	"PAGE" : {
		"PAGINATE" : "PAGINATE"
	},
	"FILTER" : {
		"APPLY" : "FILTERAPPLY",
		"CLEAR" : "FILTERCLEAR",
		"TOGGLE" : "FILTERTOGGLE",
		"VALUECHANGE" : "FILTERVALUECHANGE",
		"BADGE" : {
			"APPLY" : "FILTERBADGEAPPLY",
			"CLEAR" : "FILTERBADGEAPPLYCLEAR"
		},
		"TIMELINE" : {
			"APPLY" : "FILTERTIMELINEAPPLY"
		},
		"ORDER" : {
			"APPLY" : "FILTERORDERAPPLY"
		},
		"TIMESERIES" : {
			"APPLY" : "FILTERTIMESERIESAPPLY",
			"CLEAR" : "FILTERTIMESERIESCLEAR"
		},
		"SIDEBAR" : {
			"OPEN" : "FILTERSIDEBAROPEN",
			"CLOSE" : "FILTERSIDEBARCLOSE",
		}
	},
	"SORT" : {
		"APPLY" : "SORTAPPLY",
	},
 	"SEARCH" : {
		"APPLY" : "SEARCHAPPLY"
	},
	"ACTION" : {
		"EXECUTE" : "EXECUTE",
		"SELECTALL" : "SELECTALL",
		"DESELECTALL" : "DESELECTALL",
		"DELETE" : "DELETE*",
		"REFRESH" : "REFRESH"
	},
	"LISTENER" : {
		"PAUSE" : "PAUSE",
		"RESUME" : "RESUME"
	},
	"DIRTYCHECK" : "DIRTYCHECK",
	"TIMELINE" : {
		"CHANNEL" : {
			"SELECT" : "CHANNELSELECT"
		}
	},
	"SUBSCRIPTION" : {
		"SELECT" : "SUBSCRIPTIONSELECT",
		"CHECKOUT" : "SUBSCRIPTIONCHECKOUT",
		"CANCEL" : "SUBSCRIPTIONCANCEL",
		"UPGRADE" : "SUBSCRIPTIONUPGRADE",
		"RENEW" : "SUBSCRIPTIONRENEW"
	},
	"CART" : {
		"OPEN" : "CARTOPEN",
		"OPEN_DONE" : "CARTOPENDONE",
		"ADD" : "CARTADD",
		"REMOVE" : "CARTREMOVE",
		"UPDATE" : "CART",
		"CLOSE" : "CARTCLOSE",
		"CHECKOUT" : {
			"BEGIN" : "CARTCHECKOUTBEGIN",
			"MAKE_PAYMENT" : "CARTCHECKOUTMAKEPAYMENT",
			"ADD_SHIPPING_INFO" : "CARTCHECKOUTADDSHIPPINGINFO",
			"PURCHASE" : "CARTCHECKOUTPURCHASE"
		}
	},
	"CHECKOUT" : {
		"SET_DELIVERY_ADDRESS" : "SETDELIVERYADDRESS"
	},
	"WORKFLOW" : {
		"ACTION" : {
			"EXECUTE" : "WORKFLOWACTIONEXECUTE"
		}
	},
	"SHOP" : {
		"SIDEBAR" : {
			"OPEN" : "SHOPSIDEBAROPEN",
			"CLOSE" : "SHOPSIDEBARCLOSE"
		},
		"HEADER" : {
			"TRANSPARENT" : "HEADERTRANSPARENT",
			"OPAQUE" : "HEADEROPAQUE"
		},
		"SIGNOUT" : "SHOPSIGNOUT",
		"PRODUCT" : {
			"VIEW" : "SHOPPRODUCTVIEW" // Added for G4 Analytics View
		},
		"USER" : {
			"LOGIN" : "SHOPUSERLOGIN", // Added for G4 Analytics View
			"SIGNUP" : "SHOPUSERSIGNUP" // Added for G4 Analytics View
		},
		"ERROR" : "SHOPERROR" // Added for G4 Analytics View
	},
	"SHOPDESIGNER" : {
		"SECTION" : {
			"ADD" : "SHOPADDSECTION",
			"BG_IMAGE" : {
				"ADD" : "ADDSECTIONBACKGROUNDIMAGE",
				"REMOVE" : "REMOVESECTIONBACKGROUNDIMAGE",
			},
		},
		"PAGE" : {
			"ADD" : "SHOPADDPAGE"
		},
		"BANNER" : {
			"IMAGE" : {
				"ADD" : "ADDBANNERIMAGE",
				"REMOVE" : "REMOVEBANNERIMAGE",
			},
			"DETAIL" : {
				"EDIT" : "EDITBANNERDETAIL"
			}
		},
		"CONTENTSECTION" : {
			"IMAGE" : {
				"ADD" : "ADDCONTENTSECTIONIMAGE",
				"REMOVE" : "REMOVECONTENTSECTIONIMAGE",
			},
			"DESCRIPTION" : {
				"EDIT" : "EDITCONTENTSECTIONDESCRIPTION"
			},
			"FLOW" : "SWITCHCONTENTFLOWDIRECTION",
			"BLEND" : "SWITCHCONTENTBLENDMODE",
			"ALIGN" : "SWITCHCONTENTALIGN",
		}
	}
}

const createNanoEvents = () => ({
	events: {},
	emit(event, ...args) {
	  ;(this.events[event] || []).forEach(i => i(...args))
	},
	on(event, cb) {
	  ;(this.events[event] = this.events[event] || []).push(cb)
	  return () =>
		(this.events[event] = (this.events[event] || []).filter(i => i !== cb))
	}
});

class ContextEmitter {
	
	eventsContext = {};

	currentContext = "Global";

	_stack = ["Global"];

	emit = function(event) {
	    let args = [].slice.call(arguments, 1);
	    //console.log("ContextEmitter > emit : Emitting '"+event+"' for context '"+this.currentContext+"'");
	    const nanoContext = this.eventsContext[this.currentContext]
	    if(nanoContext)
	    	nanoContext.emit(event, ...args);
	    /*else
	    	console.log("!!! EMIT FAILED : Context '"+this.currentContext+"' not found");*/
	}

	on = function(event, cb) {
		let nanoContext = this.eventsContext[this.currentContext];
	    if(!nanoContext) {
	    	nanoContext = createNanoEvents();
	    	this.eventsContext[this.currentContext] = nanoContext;	
	    }
	    return nanoContext.on(event, cb);
	}

	set = function(context) {
		this.currentContext = context;
		this._stack.push(context);
	}

	unset = function() {
		this._stack.pop();
		this.currentContext = this._stack[this._stack.length-1];
	}
}

export const emitter = new ContextEmitter();
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _flattenDeep from 'lodash/flattenDeep';
import _forEach from 'lodash/forEach';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _union from 'lodash/union';
import _split from 'lodash/split';
import { action, observable, toJS } from 'mobx';
import moment from 'moment';
import storeFactory from '../utils/StoreFactory';

const DEFAULT_SORT_OPTION = {
	value : "LastUpdatedDate",
	sortMode : "desc"
};
export default class FilterStore {

	subjectInfo = {};
	activeFilter = "";
	postIntializeFilters = {};
	@observable appliedFilter = observable.map();
	@observable appliedTimeSeriesFilter = observable.map();
	@observable isLoading = false;
	@observable filterList = [];
	@observable sortOptions = [];
	@observable activeSortOption = null;

	constructor(subjectInfo){
		this.subjectInfo = subjectInfo;
		//console.log("FilterStore.  << Filter Store Initialized For >>");
		//console.log(subjectInfo);
	}

	initialize(){
		this.clear();
		this.postIntialize();
	}

	postIntialize(){
		if(this.postIntializeFilters.filterId && this.postIntializeFilters.filterValue)
			this.applyFilterSelection(this.postIntializeFilters.filterId, this.postIntializeFilters.filterValue);
		this.postIntializeFilters = {};
	}

	setPostIntializeFilters(filterId, filterValue){
		this.postIntializeFilters.filterId = filterId;
		this.postIntializeFilters.filterValue = filterValue;
	}
	
	@action
	loadFilters(p_filters){
		//console.log("FilterStore > loadFilters");
		//console.log("- Filters received from response");
		//console.log("===== START =====");
		//console.log(p_filters);
		//console.log("\n\n"); 
		if(this.filterList.length === 0){ // One Time Load Filter
			_map(p_filters, (p_filter) => {
				p_filter.isAvailable = true;
				_map(p_filter.options, (filterOption) => {
					filterOption.isAvailable = true;
					filterOption.hidden = false;
				});
				if(p_filter.options.length > 0) //skipping Categories(which has zero options), when find call results in zero item but Categories is loaded with empty options.
					this.filterList.push(p_filter);
			});
		} else { // Updating Filter List
	
			const filterIDs = _concat(_map(this.filterList, "uid"), _map(p_filters, "uid"));
			_forEach(filterIDs, (filterId) => {
				let existingFilterRef = _find(this.filterList, ["uid",filterId]);
				const newFilterRef = _find(p_filters, ["uid",filterId]);

				if(newFilterRef && existingFilterRef){ // Existing Filter FOUND in latest batch & hence valid & Available
					existingFilterRef.isAvailable = true;

					//Processing Filter Options
					if(filterId === this.activeFilter)
						return;

					const filterOptions = _union(_map(newFilterRef.options, "key"), _map(existingFilterRef.options, "key"));
					_forEach(filterOptions, (filterOption, index) => {

						let existingFilterOption = _find(existingFilterRef.options, ["key",filterOption]);
						const newFilterOption = _find(newFilterRef.options, ["key",filterOption]);
						
						if(newFilterOption && existingFilterOption){ //  Existing Filter Option found in latest batch & hence valid (checke below for availability)
							
							existingFilterOption.count = newFilterOption.count;
							if(existingFilterOption.count > 0){
								existingFilterOption.isAvailable = true;
								existingFilterOption.hidden = false;
							}
							else{
								existingFilterOption.isAvailable = false;
							}

						} else if(newFilterOption){
						 				// New Filter Option found from latest batch & hence valid & Available
							existingFilterOption = newFilterOption;
							if(existingFilterOption.count > 0){
								existingFilterOption.isAvailable = true;
								existingFilterOption.hidden = false;
							} else {
								existingFilterOption.isAvailable = false;
								existingFilterOption.hidden = true;
							}
							//existingFilterRef.options.push(existingFilterOption);
							existingFilterRef.options.splice(index, 0, existingFilterOption);

						} else if(existingFilterOption){			//Existing Filter Option not found in latest batch & hence invalid & UnAvailable
							
							existingFilterOption.isAvailable = false;
							existingFilterOption.hidden = false;
							existingFilterOption.count = 0;
						}	
								
					});
				} else if(newFilterRef){ // New Filter found from latest batch & hence valid & Available

					if(newFilterRef.options.length > 0){
						existingFilterRef = newFilterRef;
						existingFilterRef.isAvailable = true;

						_forEach(existingFilterRef.options, (existingFilterOption) => {
							if(existingFilterOption.count > 0){
								existingFilterOption.isAvailable = true;
								existingFilterOption.hidden = false;

							} else {
								existingFilterOption.isAvailable = false;
								existingFilterOption.hidden = true;
							}		
						});
						this.filterList.push(existingFilterRef);
					}
				} else if(existingFilterRef){ // Existing Filter not found in latest batch & hence invalid & UnAvailable
					
					existingFilterRef.isAvailable = false;
				}					
			});
		}
		//console.log(this.filterList);
		//console.log("===== END =====");	
	}

	setSubjectInfo(subjectInfo){
		this.subjectInfo = subjectInfo;
	}

	resetActiveSortOption(){
		this.activeSortOption = null;
	}

	set ActiveSortOption(sortOptionValue){
		this.activeSortOption = sortOptionValue;
	}

	get ActiveSortOption(){
		return this.activeSortOption;
	}

	get SortOptionList(){
		return this.sortOptions;
	}

	set SortOptionList(sortOptions){
		this.sortOptions = sortOptions;
	}

	get DefaultSortOption(){
		return DEFAULT_SORT_OPTION;
	}

	loadSortOptions(storeTypeInfo){
		if(storeTypeInfo){
            const sortOptions = _compact(_flattenDeep(_map(storeTypeInfo.REGIONS, region => {
                return _map(region, regionPredicate => {
                    if(regionPredicate.IsSortable){
                        return regionPredicate;
                    }else if(regionPredicate.Predicates){
                        return _map(regionPredicate.Predicates, regionPredicate2 => {
                            if(regionPredicate2.IsSortable){
                                return regionPredicate2;
                            }
                        });
                    }
                });
            })));
			this.SortOptionList = sortOptions;
        }
	}

	@action
	applyFilterSelection(filterId, selectedFilterOption){
		let appliedFilterOptions = this.appliedFilter.get(filterId);
		if(!appliedFilterOptions)
			appliedFilterOptions = [];
		const isFilterOptionAlreadyApplied = appliedFilterOptions.find( (appliedOption) => appliedOption === selectedFilterOption);
		//console.log("Before - currentAppliedBadges : "+appliedFilterOptions);
		this.activeFilter = filterId;
		// to mimic toggle behaviour if it exists remove or else add
		if(isFilterOptionAlreadyApplied){
			//case for remove filters
			_remove(appliedFilterOptions, (appliedOption) => appliedOption === selectedFilterOption);
		} else {
			appliedFilterOptions.push(selectedFilterOption);
		}
		//console.log("After - currentAppliedBadges : "+appliedFilterOptions)
		// if all the badges are unchecked/toggled/, remove the filter from applied & selected list
		if(appliedFilterOptions.length === 0){
			this.appliedFilter.delete(filterId);
		} else {
			this.appliedFilter.set(filterId, appliedFilterOptions);
		}
	}

	//	Added for Order
	//	filterId = 'OrderStatus' Filter
	//	selectedFilterOption = ""  ==> (In case of 'All Orders', Just remove the filter to fire a normal find call.)
	@action
	toggleFilterSelection(filterId, selectedFilterOption){
		//this.activeFilter = filterId;
		this.appliedFilter.delete(filterId);
		if(selectedFilterOption !== ""){
			let appliedFilterOptions = [selectedFilterOption];
			this.appliedFilter.set(filterId, appliedFilterOptions);
		}
	}

	//	Added for filter with start & end date-time
	@action
	applyTimeSeriesFilter(filterId, selectedStartDateTime, selectedEndDateTime){
		this.activeFilter = filterId;
		let appliedFilterOperations = this.appliedTimeSeriesFilter.get(filterId);
		if(!appliedFilterOperations){
			appliedFilterOperations = {};
			appliedFilterOperations.StartDate = selectedStartDateTime;
			appliedFilterOperations.EndDate = selectedEndDateTime;
			this.appliedTimeSeriesFilter.set(filterId, appliedFilterOperations);
		} else {
			appliedFilterOperations.StartDate = selectedStartDateTime;
			appliedFilterOperations.EndDate = selectedEndDateTime;
		}
	}

	@action
	clear(){
		this.appliedFilter.clear();
		this.activeFilter = "";
	}

	@action
	clearTimeSeriesFilter(filterId){
		if(filterId){
			this.appliedTimeSeriesFilter.delete(filterId);
		} else {
			this.appliedTimeSeriesFilter.clear();
		}	
	}

	//"([Name][eq][IPAD NoPro])([Description][eq][No, cutter])([color][eq][white,black])([weight][eq][5 ,10 ])"
	getFilterQuery(){
		let query = "";
		try {
			if(this.appliedFilter.size > 0){
				const data = toJS(this.appliedFilter);
				query = _map(data, (value, key) => {
					if(value.length > 1)
						value =  _join(value,"][");
					return `([${key}][eq][${value}])`;
				});
				query = _join(query, '');		
			}
			if(this.appliedTimeSeriesFilter.size > 0){
				const timeSeriesData = toJS(this.appliedTimeSeriesFilter);
				const timeSeriesQuery = _map(timeSeriesData, (filterInfo, filterID) => {
											const startDate = moment(filterInfo.StartDate).format('YYYY-MM-DDTHH:mm:ss');
											const endDate = moment(filterInfo.EndDate).format('YYYY-MM-DDTHH:mm:ss');
											return `([${filterID}][ge][${startDate}])([${filterID}][le][${endDate}])`;
										})
				query = query + _join(timeSeriesQuery, '');		
			}
		}
		catch (error){
			query = "";
		    storeFactory.ErrorStore.log(error, "Couldn't prepare filter query", "On get filter query");
		}
		finally {
			//console.log("Filter Query : "+query);
			return query;
		}
	}
}
import _assign from 'lodash/assign';
import _find from 'lodash/find';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import baby from '../utils/baby';
import storeFactory from '../utils/StoreFactory';
import subscriptionStore from './SubscriptionStore';

export default class AssetStore{

	@observable assetRepository = [];

	constructor(assetInfo){
		this.ASSET_INFO = assetInfo;
    }
    
	//UI Status 
    @observable isLoading = false;
    @observable currentAsset = {};
	
	@computed get AssetList() {
		return this.assetRepository;
	}

	get AssetInfo(){
		return this.ASSET_INFO;
    }
    
    get AssetType(){
		return this.ASSET_INFO.Type;
	}

	get SubscriptionStore() {
		return subscriptionStore;
	}

	getAssetById(uid) {
		return _find(this.assetRepository, {"DATA":{"uid":uid}} );
	}

	clearRepository() {
		this.assetRepository.clear();
		this.currentAsset = {};
	}

	//============================== Action Layer ===============================//
	

  	async createAsset(assetName, sourceID){
  		try{
            this.isLoading = true;
            const assetCreateData = { 
                "Name": assetName,
                "Type": this.AssetType,
				"SourceID": sourceID
            };  
	  		const response = await this.createAssetService(assetCreateData);		
			response.onComplete();
			return response.SUBJECT;
  		}
  		catch (error){
  			this.isLoading = false;
			  storeFactory.ErrorStore.log(error, "Couldn't perform Asset create", "On Create Asset Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
  	}

  	async saveAsset(assetId, assetPatch){
  		try{
  			this.isLoading = true;
			if(assetPatch.length > 0) {
				const assetInfo = {
                    "uid"  : assetId, 
                    "Type" : this.AssetType
                };	
		  		const response = await this.saveAssetService(assetInfo, assetPatch);		
				this.saveAsset_Success(response);
				return response.SUBJECT;
			}
  		}
  		catch (error){
  			this.isLoading = false;	
			  storeFactory.ErrorStore.log(error, "Couldn't perform Asset save", "On Save Asset Error");	
		}	
		finally {
			this.isLoading = false;	
		}
  	}

	@action
  	saveAsset_Success(response){
		this.currentAsset = toJS(response.SUBJECT);
		response.onComplete();
  	}

  	async deleteAsset(assetId){
  		try{
  			this.isLoading = true;
	  		const assetInfo = {
                "uid"  : assetId, 
                "Type" : this.AssetType
            };
	  		const response = await this.deleteAssetService(assetInfo);
			runInAction("ON Delete Item", () => {
				response.onComplete();
			});	
  		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't perform Asset delete", "On Delete Asset Error");	
		}	
		finally {
			this.isLoading = false;	
		}
	}
	

	//============================== Service Layer ===============================//



	async createAssetService(createData) {
		try {
			const response = await baby.post('/create', createData);
			return this.processResponseDataForCreateUpdateRead(response.data);
		}
  		catch (error) {
			console.error("createAssetService Failed with Error : "+error);
			throw error;
		}
	}

	async saveAssetService(assetInfo, assetJsonPatch) {
	  	try {
			const response = await baby.post('/update', {"Type":assetInfo.Type, "uid":assetInfo.uid, "Category" : assetInfo.Category, "PATCH" : assetJsonPatch} );
			return this.processResponseDataForCreateUpdateRead(response.data);
		}
		catch (error) {
			console.error("saveAssetService Failed with Error : "+error);
		  	throw error;
	  	}
	}

	async deleteAssetService(assetInfo) {
	  	try {
			const response = await baby.post('/delete', {"Type":assetInfo.Type, "uid":assetInfo.uid} );
			return this.processResponseDataForDelete(response.data, assetInfo);
		}
		catch (error) {
			console.error("deleteAssetService Failed with Error : "+error);
		  	throw error;
	  	}
	}

	processResponseDataForCreateUpdateRead(responseData){
		const { SUBJECT, SUBSCRIPTION_INFO, USER_CATEGORY, ...otherData } = responseData;
	    return {
    		"SUBJECT" : SUBJECT,
    		"onComplete" : this.updateRepository.bind(this, SUBJECT, SUBSCRIPTION_INFO, USER_CATEGORY),
    		...otherData
	    }
	}

	processResponseDataForDelete(responseData, assetInfo){
		const { SUBSCRIPTION_INFO, USER_CATEGORY } = responseData;
		return {
			"SUBJECT" : null,
			"onComplete" : this.removeFromRepository.bind(this, assetInfo.uid, SUBSCRIPTION_INFO, USER_CATEGORY)
		}
	}
	
	updateRepository(asset, subscriptionInfo, userCategory) {
	
		this.updateSubscriptionRepository(subscriptionInfo, userCategory);

		const assetToBeUpdated = _find(this.assetRepository, {"DATA":{"uid":asset.DATA.uid}} );
		if(assetToBeUpdated){
			_assign(assetToBeUpdated, asset);
		} else {
			asset.selected = false;
			this.assetRepository.push(asset);
		}
	}

	removeFromRepository(assetID, subscriptionInfo, userCategory) {
		this.assetRepository.remove(this.getAssetById(assetID));
		this.updateSubscriptionRepository(subscriptionInfo, userCategory);
    }

	updateSubscriptionRepository(subscriptionInfo, userCategory){
		if(subscriptionInfo)
			this.SubscriptionStore.SubscriptionInfo = subscriptionInfo;
		if(userCategory)
			this.SubscriptionStore.SubscriptionInfo.USER_CATEGORY = userCategory;
	}
}
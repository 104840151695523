import jsonPatch from '@libs/fast-json-patch';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _groupBy from 'lodash/groupBy';
import _hasIn from 'lodash/hasIn';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _pull from 'lodash/pull';
import _remove from 'lodash/remove';
import { action, computed, observable, runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';

export default class CatalogStore extends ItemStore {

  FACTORY_STORE_TYPE = "CATALOGS";

  @observable isInProgress = false;
  @observable selectedProducts = [];
  @observable selectedPages = [];
  @observable productList = [];
  @observable catalogOwnerInfo = {};
  @observable ownerContacts = [];

  /*@computed
  get Pages_old(){
    let sliceStartCount = 0;
    return _map(this.currentItem.DATA.PagesInCatalog, (pageId) => {
      const pageRef = this.currentItem.REFERENCES[pageId];
      const productCount = parseInt(pageRef.TotalPageCapacity);
      console.log("TotalPageCapacity > "+productCount);
      const productsInPage = this.productsInCatalog.slice( sliceStartCount, (sliceStartCount + productCount) );
      console.log("ProductsInPage > "+productsInPage);
      sliceStartCount = sliceStartCount + productCount;
      console.log("\n");
      return {page: pageRef, productList: productsInPage};
    });
  }*/

  /*init(){
    this.buildProducList();
  }

  buildProducList(){
    if(this.currentItem.DATA)
      this.productList = _flatten(_map(this.currentItem.DATA.PagesInCatalog, (pageId) => {
          const pageRef = this.currentItem.REFERENCES[pageId];
          const isSelected = this.selectedPages.indexOf(pageId) === -1 ? false : true;
          return _times(pageRef.TotalPageCapacity, (counter)=>{
              const productKey = _findKey(this.currentItem.REFERENCES, {'Type': 'PRODUCTS', 'PageID': pageId, 'Order': counter});
              if(productKey)
                  return {page: pageId, product: productKey};
              else
                  return {page: pageId, product: "EMPTY_PH"};
          });
      }));
  }*/
  @computed
  get CatalogName(){
    if(this.currentItem.DATA){
      return this.currentItem.DATA.Name;
    } else {
      return "";
    }
  }

  @computed
  get CatalogDescription(){
    if(this.currentItem.DATA){
      return this.currentItem.DATA.Description;
    } else {
      return "";
    }
  }

  @computed
  get CatalogCollageURL(){
    if(this.currentItem.DATA){
      const productsInCatalog = this.currentItem.DATA.ProductsInCatalog;
      const imageURLs = _compact(_map(productsInCatalog, (productId) => {
        const imageId = this.currentItem.REFERENCES[productId].ProfileImage;
        if(imageId && imageId !== ""){
          return this.currentItem.REFERENCE_IMAGES[imageId].SourceID;
        }
      }));

      let collageURL = "";
      if(imageURLs.length === 1){
        const singleImageURL = imageURLs[0];
        collageURL = "https://ucarecdn.com/" + singleImageURL + "/" + "-/overlay/" + singleImageURL + "/50px50p/center/";
        collageURL = collageURL + "-/blur/100/-/format/jpeg/-/preview/600x800/";
      } else if(imageURLs.length === 2){
        collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,100p/";
        collageURL = collageURL + "-/blur/100/-/format/jpeg/-/preview/600x800/";
      } else if(imageURLs.length === 3){
        collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/50p,100p/";
        collageURL = collageURL + "-/blur/100/-/format/jpeg/-/preview/600x800/";
      }else if(imageURLs.length > 3){
        collageURL = "https://ucarecdn.com/" + imageURLs[0] + "/" + "-/overlay/" + imageURLs[0] + "/50px50p/0p,0p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[1] + "/50px50p/100p,0p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[2] + "/50px50p/0p,100p/";
        collageURL = collageURL + "-/overlay/" + imageURLs[3] + "/50px50p/100p,100p/";
        collageURL = collageURL + "-/blur/100/-/format/jpeg/-/preview/600x800/";
      }
      return collageURL;

    } else {
      return "";
    }
  }

  set OwnerInfo(catalogOwner){
    this.catalogOwnerInfo = catalogOwner;
    this.StoreFactory.CurrencyStore.registerCurrency(catalogOwner);
  }

  @computed
  get OwnerInfo(){
    if(this.catalogOwnerInfo && this.catalogOwnerInfo.DATA){
      let profileImage = this.catalogOwnerInfo.DATA.ProfileImage;
      if(profileImage && profileImage !== ""){
        const profileImageRef = this.catalogOwnerInfo.REFERENCE_IMAGES[profileImage];
        if(profileImageRef){
          profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/`;
        } else {
          profileImage = null;
        }
      } else {
        profileImage = null;
      }
      return {...this.catalogOwnerInfo.DATA, "ProfileImage" : profileImage};
    } else {
      return null;
    }
  }

  @computed
  get Pages(){
    if(this.currentItem.DATA)
      return _map(this.currentItem.DATA.PagesInCatalog, (pageId) => {
        const pageRef = this.currentItem.REFERENCES[pageId];
        const isSelected = this.selectedPages.indexOf(pageId) === -1 ? false : true;
        return { ...pageRef, "selected":  isSelected};
      });
    else
      return [];
  }

  /*@computed
  get ProductList(){
    if(this.currentItem.DATA)
      return _map(this.currentItem.DATA.PagesInCatalog, (pageId) => {
        const pageRef = this.currentItem.REFERENCES[pageId];
        const isSelected = this.selectedPages.indexOf(pageId) === -1 ? false : true;
        return _times(pageRef.TotalPageCapacity, (counter)=>{
          const productKey = _findKey(this.currentItem.REFERENCES, {'Type': 'PRODUCTS', 'PageID': pageId, 'Order': counter});
          if(productKey)
            return {page: pageId, product: productKey};
          else
            return {page: pageId, product: "EMPTY_PH"};
        })
        //return { ...pageRef, "selected":  isSelected};
      });
    else
      return [];
  }*/

  @computed
  get ProductsInPages(){
    if(this.currentItem.DATA)
      return _groupBy(this.ProductsInCatalog, 'DATA.PageID');
    else
      return {};
  }

  @computed
  get ProductsInCatalog(){
    if(this.currentItem.DATA){
      return _map(this.currentItem.DATA.ProductsInCatalog, (productId) => {
          const productRef = this.currentItem.REFERENCES[productId];
          const isSelected = this.selectedProducts.indexOf(productId) === -1 ? false : true;
          return {...this.currentItem, "DATA" : productRef, "selected" : isSelected};
      });
    } else {
      return [];
    }  
  }

  @computed
  get CollectionsInCatalog(){
    if(this.currentItem.DATA){
      return _map(this.currentItem.DATA.CollectionsInCatalog, (collectionId) => {
          const collectionRef = this.currentItem.REFERENCES[collectionId];
          return {...this.currentItem, "DATA" : collectionRef};
      });
    } else {
      return [];
    }
  }

  @computed
  get Audiences(){
    if(this.currentItem.DATA){
      return _map(this.currentItem.DATA.Audience, (audienceId) => {
          const audienceRef = this.currentItem.REFERENCES[audienceId];
          return {
            uid   : audienceRef.uid,
            NAME  : audienceRef.Name,
            EMAIL : audienceRef.Email
          }
      });
    } else {
      return [];
    }
  }

  @computed
  get Visibility(){
    let catalogVisibility = "PRIVATE";

    if(this.currentItem.DATA){
      catalogVisibility =  this.currentItem.DATA.Visibility.toUpperCase();
    } 

    return catalogVisibility;
  }

  @computed
  get PublicSharedURL(){
    let publicLinkURL = null;

    if(this.currentItem.DATA){
      if(this.currentItem.DATA.Visibility.toUpperCase() === "PUBLIC"){
        publicLinkURL =  this.currentItem.DATA.URL;
      } else {
        publicLinkURL =  null;
      }
          
    } 

    return publicLinkURL;
  }

	@action
  updateSelectorPredicateProperty(propertyName, propertyValue){
  		if(_hasIn(this.currentItem.DATA, propertyName)) {
			   this.currentItem.DATA[propertyName].push(propertyValue);
  		} else {
			   this.currentItem.DATA[propertyName] = [propertyValue];
  		}
  		const refItems = this.currentItem.REFERENCE_OPTIONS[propertyName];
  		const refItem = _find(refItems, (refItem) => {
			   return refItem.DATA.uid === propertyValue;
  		});

  		if(_hasIn(refItem, 'selected'))
  			refItem.selected = !refItem.selected;
  		else
  			refItem.selected = true;
  }

  getOpenContentTabIDs(){
    ///return ["Details", "Photos", "Collection"];
    return [];
  }

  @action
  toggleProductSelection(productId){
    this.selectedPages = [];//Reset page selection when working on products
    if(this.selectedProducts.indexOf(productId) == -1){
      this.selectedProducts.push(productId);
    }else{
      _pull(this.selectedProducts, productId);
    }
  }

  @action
  togglePageSelection(pageId){
    this.selectedProducts = [];//Reset product selection when working on pages
    if(this.selectedPages.indexOf(pageId) == -1){
      this.selectedPages.push(pageId);
    }else{
      _remove(this.selectedPages, (selectedPage) => pageId == selectedPage);
    }
  }

  //For Page & Product Selections
  clearAllSelections(){
    this.selectedProducts = [];
    this.selectedPages = [];
  }

  //AddToCatalog menu Action from Product Container, Collection Container.
  async executeAddToCatalogAction(itemIds, itemType){
    try {
        const selectedCatalogs = this.getSelectedItems();
        if(itemType === "PRODUCTS")
          _forEach(selectedCatalogs.selectdItem, this.addProductToCatalogService.bind(this, itemIds));
        else if(itemType === "COLLECTIONS")
          _forEach(selectedCatalogs.selectdItem, this.addCollectionToCatalogService.bind(this, itemIds));
        else 
          console.error("Wrong ItemType to be added to Catalog : "+itemType);
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On executeAddToCatalogAction");
    }
    finally {
      //this.isLoading = false; 
    }
  }

  //AddPage Action from Catalog Container.
  @action
  async executeAddPageToCatalog(pageIds, catalogId){
    try {
        //this.isLoading = true; 
        const responseData = await this.addPageToCatalogService(pageIds, catalogId);
        runInAction("On Add Page To Catalog", () => {
            this.currentItem = responseData.SUBJECT;
            this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
            responseData.onComplete();
        });
        emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+'#'+"PagesInCatalog", [_last(this.currentItem.DATA.PagesInCatalog)], pageIds.length);
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On executeAddPageToCatalog");
    }
    finally {
      //this.isLoading = false; 
    }
  }

  //AddProduct Action from Catalog Container.
  @action
  async executeAddProductToCatalog(productIds, catalogId){
    try {
        //this.isLoading = true; // should be outside action/transaction to trigger UI;
        const responseData = await this.addProductToCatalogService(productIds, catalogId);
        runInAction("On Add Product To Catalog", () => {
            this.currentItem = responseData.SUBJECT;
            this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
            responseData.onComplete();
        });
        emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+'#'+"ProductsInCatalog", productIds, responseData.NoOfProductsAdded);
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On executeAddProductToCatalog");
    }
    finally {
      //this.isLoading = false; 
    }
  }

  //AddCollection Action from Catalog Container.
  @action
  async executeAddCollectionToCatalog(collectionIds, catalogId){
    try {
        //this.isLoading = true; // should be outside action/transaction to trigger UI;
        const responseData = await this.addCollectionToCatalogService(collectionIds, catalogId);
        runInAction("On Add Collection To Catalog", () => {
            this.currentItem = responseData.SUBJECT;
            this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
            responseData.onComplete();
        });
        emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+'#'+"CollectionInCatalog", collectionIds);
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On executeAddCollectionToCatalog");
    }
    finally {
      //this.isLoading = false; 
    }
  }

  @action
  async executeModifyProductsInCatalog(predicateName, predicateType, pageAndProductMappingInfoList, removedProductInfo){
    try {
        //this.isLoading = true; // should be outside action/transaction to trigger UI;
        const catalgId = this.getCurrentItem().DATA.uid;
        const responseData = await this.modifyProductsInCatalogService(catalgId, predicateName, predicateType, pageAndProductMappingInfoList, removedProductInfo);
        runInAction("On Modify Products In Catalog", () => {
          responseData.onComplete();
          if(removedProductInfo && removedProductInfo.length > 0)
            emitter.emit(EVENTS.PREDICATE.REMOVEITEM.DONE+'#'+predicateName);
          else
            emitter.emit(EVENTS.PREDICATE.SUFFLEITEM.DONE+'#'+predicateName, this.selectedProducts);
        });
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On ModifyProductsInCatalog");
    }
    finally {
      this.dirtyStatus.isDirty = false;
      //this.isLoading = false; 
    }
  }

  @action
  async executeModifyPagesInCatalog(predicateName, predicateType, pageAndProductMappingInfoList, removedPageInfo, removedProductInfo){
    try {
        //this.isLoading = true; // should be outside action/transaction to trigger UI;
        const catalgId = this.getCurrentItem().DATA.uid;
        const responseData = await this.modifyPagesInCatalogService(catalgId, predicateName, predicateType, pageAndProductMappingInfoList, removedPageInfo, removedProductInfo);
        runInAction("On Modify Pages In Catalog", () => {
          responseData.onComplete();
          if(removedPageInfo && removedPageInfo.length > 0)
            emitter.emit(EVENTS.PREDICATE.REMOVEITEM.DONE+'#'+predicateName);
          else
            emitter.emit(EVENTS.PREDICATE.SUFFLEITEM.DONE+'#'+predicateName, this.selectedPages);
        });
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute ", "On executeModifyPagesInCatalog");
    }
    finally {
      this.dirtyStatus.isDirty = false;
      //this.isLoading = false; 
    }
  }

  @action
  async executeGeneratePublicLink(){
    try {
        this.isInProgress = true; // should be outside action/transaction to trigger UI;
        const catalgId = this.getCurrentItem().DATA.uid;
        const catalgType = this.getCurrentItem().DATA.Type;
        const response = await this.generatePublicLinkService(catalgId, catalgType);
        runInAction("On Generate Public Link", () => {
          this.currentItem = response.SUBJECT;
          response.onComplete();
        });
    }
    catch (error){
        //this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't execute GeneratePublicLink", "On Generate Public Link");
    }
    finally {
      this.isInProgress = false; 
    }
  }

  @action
  async executeUnShareLink(){
    try {
        this.isInProgress = true; // should be outside action/transaction to trigger UI;
        const catalgId = this.getCurrentItem().DATA.uid;
        const catalgType = this.getCurrentItem().DATA.Type;
        const response = await this.unShareLinkService(catalgId, catalgType);
        runInAction("On UnShare Public Link", () => {
          this.currentItem = response.SUBJECT;
          response.onComplete();
        });
    }
    catch (error){
        this.ErrorStore.log(error, "Couldn't execute UnSharePublicLink ", "On UnShare Public Link");
    }
    finally {
      this.isInProgress = false; 
    }
  }
  
  @action
  async executeFetchCatalog(catalogId, accessMode, catalogOwner){

    try{
      this.isLoading = true;
      this.currentItem = {};
      const itemInfo = {
        "Type" : "CATALOGS", 
        "uid" : catalogId
      };
      if(catalogOwner){
        itemInfo["APP_OWNER"] = catalogOwner;
      }
      let response = null;
      if(accessMode === "PUBLIC"){
        response = await this.fetchCatalogWithPublicAccessService(itemInfo);
      } else { //accessMode === "PROTECTED"
        response = await this.fetchCatalogWithProtectedAccessService(itemInfo);
      }
      runInAction("On Fetch Catalog Success",() => {    
        this.currentItem = response.SUBJECT;
        this.OwnerInfo = response.USER;
        this.ModuleStore.processModuleResponseData(response.TRANSLATIONS, response.MODULES);
        response.onComplete();
      });
    } 
    catch (error){
      runInAction("On Fetch Catalog Error", () => {
        this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't perform Fetch", "On Fetch Catalog Error"); 
      });
    } 
    finally {
      this.isLoading = false; 
    }
  }

  @action
  async executeFetchPageConfiguration(){

    try{
      this.isLoading = true;
      const response = await this.fetchPageConfigurationService();
      runInAction("On Fetch Page Configuration Success",() => {
        this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
        response.onComplete();
      });
    } 
    catch (error){
      runInAction("On Fetch Page Configuration Error", () => {
        this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't perform Fetch", "On Fetch Page Configuration Error"); 
      });
    } 
    finally {
      this.isLoading = false; 
    }
  }

  @action
  async executeSendPrivateEmail(recipients){
    try {
        this.isInProgress = true;
        const catalogId = this.getCurrentItem().DATA.uid;
        const catalogType = this.getCurrentItem().DATA.Type;
        const response = await this.sendPrivateEmailService(catalogId, catalogType, recipients);
        runInAction("On Send Private Email Success",() => {    
          this.currentItem = response.SUBJECT;
          response.onComplete();
        });
    }
    catch (error){
        this.ErrorStore.log(error, "Couldn't execute SendPrivateEmail ", "On Execute SendPrivateEmail");
    }
    finally {
      this.isInProgress = false;
    }
  }

  @action
  async executeRemoveAudience(userIdToBeRemoved){
    try {
        this.isInProgress = true;
        const catalogId = this.getCurrentItem().DATA.uid;
        const catalogType = this.getCurrentItem().DATA.Type;
        const response = await this.removeAudienceService(catalogId, catalogType, userIdToBeRemoved);
        runInAction("On Remove Audience Success",() => {    
          this.currentItem = response.SUBJECT;
          response.onComplete();
        });
    }
    catch (error){
        this.ErrorStore.log(error, "Couldn't execute RemoveAudience ", "On Execute RemoveAudience");
    }
    finally {
      this.isInProgress = false;
    }
  }

  async executeFetchContacts(){
    try {
        this.isInProgress = true;
        const response = await this.fetchContactsService();
        runInAction("On execute Fetch Contacts Success",() => {    
          this.ownerContacts = response.CONTACTS;
          response.onComplete();
        });
    }
    catch (error){
        this.ErrorStore.log(error, "Couldn't execute FetchContacts ", "On Execute FetchContacts");
    }
    finally {
      this.isInProgress = false;
    }
  }

  /* ==========================================  Service Layer  ==========================================  */


  async modifyProductsInCatalogService(catalogId, predicateName, predicateType, pageAndProductMappingInfoList, removedProductInfo){
    try {
      let param = {};
      param.uid = catalogId;
      param[predicateName] = pageAndProductMappingInfoList;
      param.Type = predicateType;
      if(removedProductInfo)
        param.PRODUCT_TO_BE_REMOVED = removedProductInfo;

      const response = await baby.post('/modifyproductsincatalog', param);
      return this.processSubscriptionInfoFromResponse(response.data);
    } 
    catch (error) {
      console.error("modifyproductsincatalog Service Failed with Error : "+error);
      throw error;
    }     
  }

  async modifyPagesInCatalogService(catalogId, predicateName, predicateType, pageAndProductMappingInfoList, removedPageInfo, removedProductInfo){
    try {
      let param = {};
      param.uid = catalogId;
      param[predicateName] = pageAndProductMappingInfoList;
      param.Type = predicateType;
      if(removedProductInfo)
        param.PAGE_TO_BE_REMOVED = removedPageInfo;
      if(removedProductInfo)
        param.PRODUCT_TO_BE_REMOVED = removedProductInfo;

      const response = await baby.post('/modifypagesincatalog', param);
      return this.processSubscriptionInfoFromResponse(response.data);
    } 
    catch (error) {
      console.error("modifyPagesInCatalogService Service Failed with Error : "+error);
      throw error;
    }     
  }

  async addProductToCatalogService(productIds, catalogId){
    try{
      const catalogItem = this.getItemById(catalogId);
      const param = {
          "uid" : catalogItem.DATA.uid,
          "Type" : catalogItem.DATA.Type,
          "Category" : catalogItem.DATA.Category,
          "ProductsInCatalog" : productIds
      };
      const serviceResponse = await baby.post('/addproductstocatalog', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
      return response;
    }
    catch (error){
      this.ErrorStore.log(error, "Couldn't Add Product To Catalog", "On addProductToCatalogService Error");
    }
    finally {
    }
  }

  async addCollectionToCatalogService(collectionIds, catalogId){
    try{
      const catalogItem = this.getItemById(catalogId);
      const param = {
          "uid" : catalogItem.DATA.uid,
          "Type" : catalogItem.DATA.Type,
          "Category" : catalogItem.DATA.Category,
          "CollectionsInCatalog" : collectionIds
      };
      const serviceResponse = await baby.post('/addcollectionstocatalog', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
      return response;
    }
    catch (error){
      this.ErrorStore.log(error, "Couldn't Add Collection To Catalog", "On addCollectionToCatalogService Error");
    }
    finally {
    }
  }

  //@override
  async createItem(itemCreateInfo){
    try{
        this.isLoading = true;
        const createData = {
          ...itemCreateInfo,
          "ProductsInCatalog" : []
        }
        const serviceResponse = await baby.post('/createcatalogwithproducts', createData);
        const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
        response.onComplete();
        return response.SUBJECT;
        //navigationStore().changeModule(response.SUBJECT.DATA.Type);
        //emitter.emit(EVENTS.ITEM.OPEN, EVENTS.ITEM.OPEN, uid, response.SUBJECT.DATA.Type, response.SUBJECT.DATA.Category);
      }
      catch (error){
        this.isLoading = false;
        //this.ErrorStore.log(error, "Couldn't create", "On Create Item Error");
        throw error;
    }
    finally {
      this.isLoading = false; 
    }
  }

  async generatePublicLinkService(catalgId, catalgType){
    try {
      let param = {};
      param.uid = catalgId;
      param.Type = catalgType;
      const serviceResponse = await baby.post('/share', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("generatePublicLink Service Failed with Error : "+error);
      throw error;
    }     
  }

  async unShareLinkService(catalgId, catalgType){
    try {
      let param = {};
      param.uid = catalgId;
      param.Type = catalgType;
      const serviceResponse = await baby.post('/unshare', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("unSharePublicLink Service Failed with Error : "+error);
      throw error;
    }     
  }

  async fetchCatalogWithPublicAccessService(itemInfo) {
    try {
      const serviceResponse = await baby.post('/publicread', itemInfo);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("fetchCatalogWithPublicAccessService Failed with Error : "+error);
      throw error;
    }       
  }

  async fetchCatalogWithProtectedAccessService(itemInfo) {
    try {
      const serviceResponse = await baby.post('/protectedread', itemInfo);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("fetchCatalogWithProtectedAccessService Failed with Error : "+error);
      throw error;
    }       
  }

  async fetchPageConfigurationService() {
    try {
      const serviceResponse = await baby.post('/readpageconfiguration', {});
      const response = super.processResponseDataForGetAll(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("fetchPageConfigurationService Failed with Error : "+error);
      throw error;
    }       
  }

  async addPageToCatalogService(pageIds, catalogId){
    try{
      const catalogItem = this.getItemById(catalogId);
      const param = {
          "uid" : catalogItem.DATA.uid,
          "Type" : catalogItem.DATA.Type,
          "Category" : catalogItem.DATA.Category,
          "PagesInCatalog" : pageIds
      };
      const serviceResponse = await baby.post('/addpagestocatalog', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
      return response;
    }
    catch (error){
      this.ErrorStore.log(error, "Couldn't Add Page To Catalog", "On addPageToCatalogService Error");
    }
    finally {
    }
  }

  async sendPrivateEmailService(catalogId, catalogType, recipients){
    try {
      let param = {};
      param.uid = catalogId;
      param.Type = catalogType;
      param.Audience = recipients;
      const serviceResponse = await baby.post('/post', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("sendPrivateEmail Service Failed with Error : "+error);
      throw error;
    }     
  }

  async removeAudienceService(catalogId, catalogType, userIdToBeRemoved){
    try {
      let param = {};
      param.uid = catalogId;
      param.Type = catalogType;
      param.PATCH = [{
        "op":"remove",
        "path":"/Audience",
        "value":userIdToBeRemoved
      }];
      const serviceResponse = await baby.post('/removeaccess', param);
      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
      return response;
    } 
    catch (error) {
      console.error("removeAudience Service Failed with Error : "+error);
      throw error;
    }     
  }

  async fetchContactsService(){
    try {
      const response = await baby.get('/getcontacts');
      return this.processSubscriptionInfoFromResponse(response.data);
    } 
    catch (error) {
      console.error("fetchContactsService Failed with Error : "+error);
      throw error;
    }
  }

}
import _assign from 'lodash/assign';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import { computed, observable } from 'mobx';
import baby from '../utils/baby';
import subscriptionStore from './SubscriptionStore';

export default class SubjectStore {
	
	@observable subjectRepository = [];
	@observable subjectTypeRepository = observable.map();
	@observable subjectCountRepository = {};
	subjectError = {};

	clearRepository() {
		this.subjectRepository = [];
		this.subjectTypeRepository.clear();
		this.subjectCountRepository = {};
	}

	@computed get Subjects() {
		return this.subjectRepository;
	}

	@computed get SubjectCounts() {
		return this.subjectCountRepository;
	}

	get SubscriptionStore() {
		return subscriptionStore;
	}

	getSubjectById(uid) {
		return _find(this.subjectRepository, {"DATA":{"uid":uid}} );
	}

	getSubjectTypeById(uid){
		return this.subjectTypeRepository.get(uid);
	}

	//============================== Action Layer ===============================//

	async loadSubjectsByType(subjectInfo, page, filter, searchString, sortOption) {
		try {
			const responseData = await this.getSubjectsService(subjectInfo, page, filter, searchString, sortOption);
	    	return this.processResponseDataForGetAll(responseData);
		} 
		catch (error) {
			throw error;
		} 
  	}

  	async loadSubjectsByTypeWithReverse(subjectInfo, page, filter, searchString) {
		try {
			const responseData = await this.getSubjectsServiceWithReverse(subjectInfo, page, filter, searchString);
	    	return this.processResponseDataForGetAll(responseData);
		} 
		catch (error) {
			throw error;
		} 
  	}

  	async loadProspectByTypeWithReverse(subjectInfo, page, filter, searchString) {
		try {
			const responseData = await this.getProspectServiceWithReverse(subjectInfo, page, filter, searchString);
	    	return this.processResponseDataForGetAll(responseData);
		} 
		catch (error) {
			throw error;
		} 
  	}

  	async getSubjectByType(subjectInfo) {
  		const serviceErrorMsg = "Couldn't open "+subjectInfo.Type+"";
		try {
  			const responseData = await this.getSubjectService(subjectInfo);
  			return this.processResponseDataForCreateUpdateRead(responseData);
  		}
  		catch (error) {
			throw error;
		} 
	}

	async createSubjectByType(createData) {
		try {
			const responseData = await this.createSubjectService(createData);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
  		catch (error) {
			throw error;
		}
	}

  	async saveSubject(subjectInfo, jsonPatch) {
  		const serviceErrorMsg = "Couldn't save "+subjectInfo.Type+"";
		try {
  			const responseData = await this.saveSubjectService(subjectInfo,jsonPatch);
  			return this.processResponseDataForCreateUpdateRead(responseData);
  		}
  		catch (error) {
			throw error;
		}
  	}

  	async deleteSubject(subjectInfo) {
  		const serviceErrorMsg = "Couldn't delete "+subjectInfo.Type+"";
		try {
  			const responseData = await this.deleteSubjectService(subjectInfo);
  			return this.processResponseDataForDelete(responseData, subjectInfo);
  		}
  		catch (error) {
			throw error;
		}
  	}

  	async bulkDeleteSubject(subjectInfo) {
  		const serviceErrorMsg = "Couldn't delete "+subjectInfo.Type+"";
		try {
  			const responseData = await this.bulkDeleteSubjectService(subjectInfo);
  			return this.processResponseDataForBulkDelete(responseData, subjectInfo.SUBJECTS);
  		}
  		catch (error) {
			throw error;
		}
  	}

  	async shareSubjectByType(shareData) {
  		const serviceErrorMsg = "Couldn't share "+shareData.Type+"";
		try {
			const responseData = await this.shareSubjectService(shareData);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
  		catch (error) {
			throw error;
		}
	}

	async postSubject(subjectInfo) {
		const serviceErrorMsg = "Couldn't post "+subjectInfo.Type+"";
		try {
  			const responseData = await this.postSubjectService(subjectInfo);
  			return this.processResponseDataForCreateUpdateRead(responseData);
  		}
  		catch (error) {
			throw error;
		}
  	}

  	async bulkCloneSubject(cloneInfo) {
		try {
  			const responseData = await this.bulkCloneSubjectService(cloneInfo);
  			return responseData;
  		}
  		catch (error) {
			throw error;
		}
	}
	  
	async changeCategory(subjectInfo, jsonPatch) {
	  try {
			const responseData = await this.changeCategoryService(subjectInfo,jsonPatch);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
		catch (error) {
		  throw error;
	  }
	}

	async takeWorkflowAction(params) {
		try {
			  const responseData = await this.takeWorkflowActionService(params);
			  return this.processResponseDataForCreateUpdateRead(responseData);
		  }
		  catch (error) {
			throw error;
		}
	}

	async takeWorkflowActionAndFind(actionName, objectID, subjectInfo, page, filter, searchString, sortOption, patch) {
	  try {
			const responseData = await this.takeWorkflowActionAndFindService(actionName, objectID, subjectInfo, page, filter, searchString, sortOption, patch);
			return this.processResponseDataForGetAll(responseData);
		}
		catch (error) {
		  throw error;
	  }
	}

	async addProfileImage_Subject(subjectInfo, imageInfo) {
	  try {
			const imageInfoParam = {...subjectInfo, ...imageInfo};
			const responseData = await this.addProfileImageService(imageInfoParam);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
		catch (error) {
		  	throw error;
	  	}
	}

	async removeProfileImage_Subject(subjectInfo) {
		try {
			  const responseData = await this.removeProfileImageService(subjectInfo);
			  return this.processResponseDataForCreateUpdateRead(responseData);
		  }
		  catch (error) {
				throw error;
			}
	  }

	async addImagesInGallery_Subject(subjectInfo, imageList) {
		try {
			const imageInfoParam = {...subjectInfo, "IMAGES" : imageList};
			const responseData = await this.addImagesInGalleryService(imageInfoParam);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
		catch (error) {
			throw error;
		}
	}

	async removeImageInGallery_Subject(subjectInfo, imageId) {
		try {
			const imageInfoParam = {...subjectInfo, "ImageID" : imageId};
			const responseData = await this.removeImageInGalleryService(imageInfoParam);
			return this.processResponseDataForCreateUpdateRead(responseData);
		}
		catch (error) {
			throw error;
		}
	}

	async getReferenceOptions(referenceParams){
		try {
			const responseData = await this.getReferenceOptionsService(referenceParams);
			return responseData;
		} catch (error) {
			throw error;
		}
	}

  	/*processSubjectError(error, serviceErrorMsg){
  		let errorMsg = "";
		if(error.response) {
			if(error.response.data){
				const errorData = error.response.data;
				if(errorData.EXCEPTION === "UnknownException"){
					errorMsg = serviceErrorMsg;
				} else {
					errorMsg = errorData.MESSAGE;
				}
			} else {
				errorMsg = serviceErrorMsg;
			}
		} else {
			errorMsg = serviceErrorMsg;
		}
		return {"serviceErrorMsg" : errorMsg, "serviceError" : error};
  	}*/

  	processResponseDataForCreateUpdateRead(responseData){
		const { SUBJECT, SUBJECT_TYPE, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY, ...otherData } = responseData;
	    return {
    		"SUBJECT" : SUBJECT,
    		"onComplete" : this.updateRepository.bind(this, SUBJECT, SUBJECT_TYPE, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY),
    		...otherData
	    }
  	}

  	processResponseDataForDelete(responseData, itemInfo){
  		const { SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY } = responseData;
	    return {
    		"SUBJECT" : null,
    		"onComplete" : this.removeFromRepository.bind(this, itemInfo.uid, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY)
	    }
  	}

  	processResponseDataForBulkDelete(responseData, deleteItemList){
  		const { SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY } = responseData;
	    return {
    		"SUBJECT" : null,
    		"onComplete" : this.bulkRemoveFromRepository.bind(this, deleteItemList, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY)
	    }
  	}

  	processResponseDataForGetAll(responseData) {
		//const subjectType = responseData.SUBJECT_TYPE;
	    //const subjects = responseData.SUBJECTS;
		const { SUBJECTS, SUBJECT_TYPE, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY, ERROR, ...otherData } = responseData;
		if(ERROR){
			return this.subjectError;
		} else {
			return {
	    		"onComplete" : this.loadRepository.bind(this, SUBJECT_TYPE, SUBJECTS, SUBJECT_COUNTS, SUBSCRIPTION_INFO, USER_CATEGORY),
				SUBJECT_TYPE,
	    		...otherData
		    }
		}
	}

	processSubscriptionInfoFromResponse(responseData){

		const { SUBSCRIPTION_INFO, USER_CATEGORY, ERROR, ...otherData } = responseData;
		if(ERROR){
			return this.subjectError;
		} else {
			return {
	    		"onComplete" : this.updateSubscriptionRepository.bind(this, SUBSCRIPTION_INFO, USER_CATEGORY),
	    		...otherData
		    }
		}
	}

	removeFromRepository(itemId, subjectCounts, subscriptionInfo, userCategory) {
	   	this.subjectRepository.remove(this.getSubjectById(itemId));
	   	if(subjectCounts)
			this.subjectCountRepository = subjectCounts;
		
		this.updateSubscriptionRepository(subscriptionInfo, userCategory);
  	}

  	bulkRemoveFromRepository(deleteItemList, subjectCounts, subscriptionInfo, userCategory) {
		_map(deleteItemList, (itemId) => {
			this.subjectRepository.remove(this.getSubjectById(itemId));
		});

		if(subjectCounts)
			this.subjectCountRepository = subjectCounts;
		
		this.updateSubscriptionRepository(subscriptionInfo, userCategory);
  	}

  	updateRepository(subject, subjectType, subjectCounts, subscriptionInfo, userCategory) {
		//subject.selected = false;
		if(subjectType)
			this.subjectTypeRepository.set(subjectType.uid, subjectType);
		if(subjectCounts)
			this.subjectCountRepository = subjectCounts;

		this.updateSubscriptionRepository(subscriptionInfo, userCategory);

		const subjectIndex = _findIndex(this.subjectRepository, {"DATA":{"uid":subject.DATA.uid}} );
		const subjectToBeUpdated = this.subjectRepository[subjectIndex];
		if(subjectToBeUpdated){
			//_assign(subjectToBeUpdated, subject);
			subject.selected = subjectToBeUpdated.selected;
			this.subjectRepository.splice(subjectIndex, 1, subject);
		} else {
			subject.selected = false;
			this.subjectRepository.push(subject);
		}
  	}

	loadRepository(subjectType, subjects, subjectCounts, subscriptionInfo, userCategory) {
		let subjectList = [];
		subjects.forEach( (newSubject) => {
			const oldSubject = this.getSubjectById(newSubject.DATA.uid);
			newSubject.selected = oldSubject ? oldSubject.selected : false;
    		subjectList.push(newSubject);
		});
		this.clearRepository();
		this.subjectRepository.push(...subjectList);
		
		if(subjectType)
			this.subjectTypeRepository.set(subjectType.uid, subjectType);
		if(subjectCounts)
			this.subjectCountRepository = subjectCounts;
			
		this.updateSubscriptionRepository(subscriptionInfo, userCategory);
	}

	updateSubscriptionRepository(subscriptionInfo, userCategory){
		if(subscriptionInfo)
			this.SubscriptionStore.SubscriptionInfo = subscriptionInfo;
		if(userCategory)
			this.SubscriptionStore.SubscriptionInfo.USER_CATEGORY = userCategory;
	}

	//============================== Service Layer ===============================//
	//
	async getSubjectsService(subjectInfo, page, filter, searchString, sortOption) {
		try {
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
			if(searchString){
				param = {...param, "SEARCHSTRING": searchString, "OR" : true};
			}
			if(sortOption){
				param = {...param, "ORDER": sortOption.value, "IS_ASC" : (sortOption.sortMode === "asc")};
			}
				
			const response = await baby.post('/find', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getSubject's Service Failed with Error : "+error);
			throw error;
		}				
	}

	async getSubjectsServiceWithReverse(subjectInfo, page, filter, searchString) {
		try {
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
			if(searchString){
				param = {...param, "SEARCHSTRING": searchString, "OR" : true};
			}
			
			const response = await baby.post('/findwithreverse', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getSubject's Service With Reverse Failed with Error : "+error);
			throw error;
		}				
	}

	async getProspectServiceWithReverse(subjectInfo, page, filter, searchString) {
		try {
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
			if(searchString){
				param = {...param, "SEARCHSTRING": searchString, "OR" : true};
			}
			const response = await baby.post('/findprospectwithreverse', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getProspect's Service With Reverse Failed with Error : "+error);
			throw error;
		}				
	}

	async getSubjectService(itemInfo) {
		try {
			const response = await baby.post('/read', {"Type":itemInfo.Type, "uid":itemInfo.uid, "Category" : itemInfo.Category});
			return response.data;
		} 
		catch (error) {
			console.error("getSubjectService Failed with Error : "+error);
			throw error;
		}				
	}

	async saveSubjectService(itemInfo, productJsonPatch) {
		try {
			const response = await baby.post('/update', {"Type":itemInfo.Type, "uid":itemInfo.uid, "Category" : itemInfo.Category, "PATCH" : productJsonPatch} );
			return response.data;
		} 
		catch (error) {
			console.error("saveSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async deleteSubjectService(itemInfo) {
		try {
			const response = await baby.post('/delete', {"Type":itemInfo.Type, "uid":itemInfo.uid} );
			return response.data;
		} 
		catch (error) {
			console.error("deleteSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async bulkDeleteSubjectService(deleteInfo) {
		try {
			const response = await baby.post('/deletemultiple', deleteInfo );
			return response.data;
		} 
		catch (error) {
			console.error("bulkDeleteSubjectService Failed with Error : "+error);
			throw error;
		}
	}


	async createSubjectService(createData) {
		try {
			const response = await baby.post('/create', createData);
			return response.data;
		} 
		catch (error) {
			console.error("createSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async shareSubjectService(shareData) {
		try {
			const response = await baby.post('/share', shareData);
			return response.data;
		} 
		catch (error) {
			console.error("shareSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async postSubjectService(itemInfo) {
		try {
			const response = await baby.post('/post', itemInfo );
			return response.data;
		} 
		catch (error) {
			console.error("postSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async bulkCloneSubjectService(cloneInfo) {
		try {
			const response = await baby.post('/cloneproducts', cloneInfo );
			return response.data;
		} 
		catch (error) {
			console.error("bulkCloneSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async changeCategoryService(itemInfo, productJsonPatch) {
		try {
			const response = await baby.post('/changecategory', {"Type":itemInfo.Type, "uid":itemInfo.uid, "Category" : itemInfo.Category, "PATCH" : productJsonPatch} );
			return response.data;
		} 
		catch (error) {
			console.error("changeCategoryService Failed with Error : "+error);
			throw error;
		}
	}

	async takeWorkflowActionService(params) {
		try {
			const response = await baby.post('/takeworkflowaction', params);
			return response.data;
		} 
		catch (error) {
			console.error("takeWorkflowActionService Failed with Error : "+error);
			throw error;
		}
	}

	async takeWorkflowActionAndFindService(actionName, objectID, subjectInfo, page, filter, searchString, sortOption, patch) {
		try {
			const param = {
				...subjectInfo, 
				"FIND_WITH_FILTERS": true, 
				"FIND_WITHIN" : true, 
				"ObjectID": objectID, 
				"Action": actionName,
				"PATCH" : patch
			};

			if(filter)
				param.FILTER = filter;
			if(page)
				param.REQUESTED_PAGE = page;
			if(searchString){
				param.SEARCHSTRING = searchString;
				param.OR = true;
			}
			if(sortOption){
				param.ORDER = sortOption.value;
				param.IS_ASC = (sortOption.sortMode === "asc");
			}
			const response = await baby.post('/takeworkflowactionandfind', param);
			return response.data;
		} 
		catch (error) {
			console.error("takeWorkflowActionAndFindService Failed with Error : "+error);
			throw error;
		}
	}

	
	async addProfileImageService(imageInfo) {
		try {
			const response = await baby.post('/replaceprofileimage', imageInfo);
			return response.data;
		} 
		catch (error) {
			console.error("addProfileImageService Failed with Error : "+error);
			throw error;
		}
	}

	async removeProfileImageService(subjectInfo) {
		try {
			const response = await baby.post('/deleteprofileimage', subjectInfo);
			return response.data;
		} 
		catch (error) {
			console.error("removeProfileImageService Failed with Error : "+error);
			throw error;
		}
	}

	async addImagesInGalleryService(imageInfo) {
		try {
			const response = await baby.post('/createandaddimagestogallery', imageInfo);
			return response.data;
		} 
		catch (error) {
			console.error("addImagesInGalleryService Failed with Error : "+error);
			throw error;
		}
	}

	async removeImageInGalleryService(imageInfo) {
		try {
			const response = await baby.post('/removeimagefromgallery', imageInfo);
			return response.data;
		} 
		catch (error) {
			console.error("removeImageInGalleryService Failed with Error : "+error);
			throw error;
		}
	}

	async getReferenceOptionsService(referenceParams){
		try {
			const response = await baby.post('/getreferenceoptions', referenceParams);
			return response.data;
		} 
		catch (error) {
			console.error("getReferenceOptionsService Failed with Error : "+error);
			throw error;
		}
	}
}
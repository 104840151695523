import DefaultTheme from "./default/theme";
import BullseyeBotanicalsTheme from "./bullseye-botanicals/theme";
import RealEstateStandard from "./realestate-standard/theme";
import ToperaTheme from "./topera/theme";
import SpecialTheme from "./special/theme";
import _find from 'lodash/find';


const _themeLibrary = [
    DefaultTheme,
    BullseyeBotanicalsTheme,
    RealEstateStandard,
    ToperaTheme,
    SpecialTheme,
];

const _findTheme = function(themeID){
    const themeInfo = _find(_themeLibrary, (theme) => {
        if(theme.MetaData.id === themeID)
            return true;
        else 
            return false;
    });
    return themeInfo;
}

const _fetchTheme = async function(themeID){
    const theme = _findTheme(themeID);
    if(theme){
        const themeFolder = theme.MetaData.folder_name;
        if(themeFolder){
            const themeStyleMap = await import(`./${themeFolder}/style-mapping`);
            const themeGlobalStyle = await import(`./${themeFolder}/style-global`);
            const themeFontStyle = await import(`./${themeFolder}/style-font`);
            
            return {
                themeInfo : theme,
                styleMap : themeStyleMap.default,
                globalStyle : themeGlobalStyle.default,
                fontStyle : themeFontStyle.default,
            }
        } else {
            console.error("ThemeLibrary : Theme Folder Not Configured For Theme : "+themeID);
        }
    } else {
        console.error("ThemeLibrary : Theme Not Found : "+themeID);
    }
}

export default {
    fetchTheme : _fetchTheme
}

/*export default {
    themes : [
        {
            themeInfo : DefaultTheme,
            styleMap : DefaultTheme_StyleMapping,
            globalStyle : DefaultTheme__GlobalStyle,
        },
        {
            themeInfo : BullseyeBotanicalsTheme,
            styleMap : BullseyeBotanicalsTheme_StyleMapping,
            globalStyle : BullseyeBotanicalsTheme_GlobalStyle,
        }
    ],
}*/

export const ph_profileImageURL        =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
export const ph_profileImageURL_mini   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/lightest/-/preview/250x100/'; 
export const ph_logoImageURL           =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
export const ph_logoImageURL_srcset1   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/webp/-/quality/smart/';
export const ph_logoImageURL_slider1   =   'https://ucarecdn.com/05cf0aa7-9f64-415f-9290-4a5eedf4d05a/-/format/auto/-/quality/smart/';
export const ph_logoImageURL_slider2   =   'https://ucarecdn.com/f5411926-45ea-4b5a-a770-2ef41fd113bb/-/format/auto/-/quality/smart/';
export const ph_logoImageURL_slider3   =   'https://ucarecdn.com/72d4103a-e8c4-47d0-bc82-fa545e4ecdeb/-/format/auto/-/quality/smart/';
export const ph_logoImageURL_slider4   =   'https://ucarecdn.com/1b2a3e51-137c-4733-b0e8-a2f60079b644/-/format/auto/-/quality/smart/';
export const ph_logoImageURL_slider5   =   'https://ucarecdn.com/6619c901-5921-4df4-8d07-db5c0ee8c965/-/format/auto/-/quality/smart/';
export const ph_emptyImageURL          =   'https://ucarecdn.com/5edff897-e0fc-4817-ab81-509618ca9211/-/format/auto/-/quality/smart/';
export const ph_emptyImageURL_Square   =   'https://ucarecdn.com/5edff897-e0fc-4817-ab81-509618ca9211/-/format/auto/-/quality/smart/-/scale_crop/600x600/center/';
export const ph_emptyImageURL_banner   =   'https://ucarecdn.com/45902029-5473-4780-8a93-f1e713113343/-/format/auto/-/quality/smart/-/scale_crop/800x600/smart/';
export const ph_errorImageURL          =   'https://ucarecdn.com/6bbd7a72-e87d-4a49-92ad-a50ce3f4fefa/-/format/auto/-/quality/smart/';
export const ph_limitReachedImageURL   =   '';
export const ph_payment_indcatalog     =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
export const ph_payment_paytm          =   'https://ucarecdn.com/2634c064-8b17-45ca-b841-1bf27e681058/-/format/auto/-/quality/smart/';
export const ph_payment_mode_upi       =   'https://ucarecdn.com/7679f812-9c36-4d20-b826-d7c0c3f13cab/-/format/auto/-/quality/smart/';
export const ph_payment_mode_other     =   'https://ucarecdn.com/be59d3e7-bc8f-4b45-b15e-46467dea53b8/-/format/auto/-/quality/smart/';
export const ph_serverUnderMaint_Image =   'https://ucarecdn.com/5d8fd539-cc31-46e9-9e5f-ab61a68e28ca/-/format/auto/-/quality/smart/';
import { action, autorun, observable, runInAction } from 'mobx';

const error_401_MSG = "Please login to continue with Tridasha."
const error_Network_MSG = "Please check your network connection to continue with Tridasha."

export default class ErrorStore {

	constructor(storeFactoryInstance){
		this.storeFactory = storeFactoryInstance;
	}
	
	@observable isError = false;
	@observable isNotify = false;
	@observable isInvalidSession = false;
	@observable isNetworkError = false;
	@observable accessDenied = false;
	@observable notFound = false;
	@observable serverUnderMaintenance = false;
	@observable errorMsg = observable.box("");
	errorObject = [];//stack to store UI error timeline.
	notFoundCategory = null;

	@action.bound
	log(errorObj, errorMsg, context){
		this.resetError();
		this.triggerError(errorObj, errorMsg, context);
	}

	resetError(){
		this.isError = true;
	}

	triggerError(errorObj, errorMsg, context){
		this.isError = true;
		const error = this.processError(errorObj, errorMsg);
		this.errorMsg.set(error.serviceErrorMsg);
		console.error(context);
		console.log(error.serviceErrorMsg);
		console.log(error.serviceError);
	}

	notify(errorObj, errorMsg, context){
		this.resetNotify();
		this.triggerNotify(errorObj, errorMsg, context);
	}

	resetNotify(){
		this.isNotify = false;
	}

	@action
	triggerNotify(errorObj, errorMsg, context){
		this.isNotify = true;
		const error = this.processError(errorObj, errorMsg);
		this.errorMsg.set(error.serviceErrorMsg);
		console.error(context);
		console.log(error.serviceErrorMsg);
		console.log(error.serviceError);
	}

	is401Error(error){
		if(error.response) {
			if(error.response.data){
				const errorStatus = error.response.status;
				if(errorStatus === 401) {
					return true;
				}
			}
		}
		return false;
	}

	checkHTTPErrorStatus(errorResponse, serviceErrorMsg) {
		let errorMsg = "";
		if (errorResponse.status === 401) {
			this.isInvalidSession = true;
			errorMsg = error_401_MSG;
		}
		else if (errorResponse.status === 403) {
			this.accessDenied = true;
			errorMsg = serviceErrorMsg;
		}
		else if (errorResponse.status === 404) {
			this.notFound = true;
			errorMsg = serviceErrorMsg;
		}
		else if (errorResponse.status === 503) {
			this.serverUnderMaintenance = true;
			errorMsg = serviceErrorMsg;
		}
		else {
			errorMsg = serviceErrorMsg;
		}
		return errorMsg;
	}

	checkApplicationExceptions(errorData, errorResponse, serviceErrorMsg) {
		let errorMsg = "";
		if (errorData.EXCEPTION.ClassName === "UnknownException") {
			errorMsg = serviceErrorMsg;
		} else if (errorData.EXCEPTION.ClassName === "AuthorizationFailedException") {
			this.isInvalidSession = true;
			errorMsg = error_401_MSG;
		} else if (errorData.EXCEPTION.ClassName === "AccessDeniedException") {
			this.accessDenied = true;
			errorMsg = serviceErrorMsg;
		} else if (errorData.EXCEPTION.ClassName === "ServerUnderMantainance") {
			this.serverUnderMaintenance = true;
			errorMsg = serviceErrorMsg;
		} else if (errorData.EXCEPTION.ClassName === "UserAccountVerificationException") {
			const navigationStoreREF = this.storeFactory.GlobalNavigationStore;
			if(navigationStoreREF.currentScreenName === navigationStoreREF.SCREEN.LOGIN){ //IF the current screen is login it means the error has occurred in App, hence redirect.
				navigationStoreREF.changeView(navigationStoreREF.VIEW.USERVERIFICATION);
			}
			//IF the currrent screen is NOT login then it means the error has occured in Shop, hence don't redirect just return.
			errorMsg = serviceErrorMsg;
		} else {
			errorMsg = this.checkHTTPErrorStatus(errorResponse, errorData.EXCEPTION.Message ? errorData.EXCEPTION.Message : serviceErrorMsg);
		}
		return errorMsg;
	}

	processError(error, serviceErrorMsg){
  		let errorMsg = "";
		if(error && error.response) {
			const errorResponse = error.response;
			if(error.response.data){
				const errorData = error.response.data;
				if(errorData.EXCEPTION){
					errorMsg = this.checkApplicationExceptions(errorData, errorResponse, serviceErrorMsg);
				}
				else {
					errorMsg = this.checkHTTPErrorStatus(errorResponse, serviceErrorMsg);
				}
			} else {
				errorMsg = this.checkHTTPErrorStatus(errorResponse, serviceErrorMsg);
			}
		} else if(error && error.message === "Network Error"){
			this.isNetworkError = true;
			errorMsg = error_Network_MSG;
		}else {
			errorMsg = serviceErrorMsg;
		}
		return {"serviceErrorMsg" : errorMsg, "serviceError" : error};
  	}

  	invalidSessionCheckDisposer = autorun(() => {
		console.log("==== Error Store Autorun | InvalidSession Check Activated.....");
        if(this.isInvalidSession){
			const navigationStoreREF = this.storeFactory.GlobalNavigationStore;	
			console.error("InvalidSession FOUND !!!! ");
			switch (navigationStoreREF.currentScreenName) {
				
				case navigationStoreREF.SCREEN.CATALOG:
					//GO To Catalog's Login Screen.....
					runInAction(" accessDeniedCheckDisposer Product case ", () => {
						navigationStoreREF.changeView(navigationStoreREF.VIEW.LOGIN);
						const isRedirected = navigationStoreREF.getContextualParam("REF");
						if(!isRedirected){
							navigationStoreREF.setContextualParam("REF", {
								"redirectURLRef" : window.location.pathname
							}); 
						}
					});
					break;
			
				case navigationStoreREF.SCREEN.PRODUCT:
					//GO To Public Products's Login Screen..... 
					navigationStoreREF.changeView(navigationStoreREF.VIEW.LOGIN); 
					break;

				case navigationStoreREF.SCREEN.SHOP:
					//GO To Shop's Login Screen..... 
					if(navigationStoreREF.currentViewName !== navigationStoreREF.VIEW.SHOPLOGIN){
						navigationStoreREF.changeView(navigationStoreREF.VIEW.SHOPLOGIN);
					}
					break;

				default:
					//GO To App's Login Screen.....  
					if(navigationStoreREF.currentScreenName != navigationStoreREF.SCREEN.LOGIN){
						runInAction(" invalidSessionCheckDisposer default case ", () => {
							navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
							navigationStoreREF.setContextualParam("REF", {
								"redirectURLRef" : window.location.pathname
							});
							console.log(" >> REF : "+window.location.pathname);
							navigationStoreREF.changeModule("");
							navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE); 
						});
					}
					break;
			}
        		
	    	this.isInvalidSession = false;
        }	
    }, {name: "invalidSessionCheckDisposer"});

    networkErrorCheckDisposer = autorun(() => {
		console.log("==== Error Store Autorun | Network Error Check Activated.....");
        if(this.isNetworkError){
			console.error("Network Error FOUND !!!! ");
			const navigationStoreREF = this.storeFactory.GlobalNavigationStore;

			switch (navigationStoreREF.currentScreenName) {

				case navigationStoreREF.SCREEN.CATALOG:
					navigationStoreREF.changeView(navigationStoreREF.VIEW.LOGIN);
					this.isNetworkError = false;
					break;
			
				case navigationStoreREF.SCREEN.PRODUCT:
					navigationStoreREF.changeView(navigationStoreREF.VIEW.LOGIN);
					this.isNetworkError = false;
					break;

				//TODO add case for SHOP as well !!!
				case navigationStoreREF.SCREEN.SHOP:
					//this.isNetworkError = false;
					break;

				default:
					if(navigationStoreREF.currentScreenName != navigationStoreREF.SCREEN.LOGIN){
						runInAction(" networkErrorCheckDisposer default case ", () => {
							navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
							navigationStoreREF.setContextualParam("REF", {
								"redirectURLRef" : window.location.pathname
							});
							console.log(" >> REF : "+window.location.pathname);
							navigationStoreREF.changeModule("");
							navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE); 
						})
					}
					this.isNetworkError = false;
					break;
			}
        }	
	}, {name: "NetworkErrorCheckDisposer"});
	
	// 404 Page Not Found Scenario
	accessDeniedCheckDisposer = autorun(() => {
		console.log("==== Error Store Autorun | AccessDenied + NoFound Check Activated.....");
        if(this.accessDenied || this.notFound){
			console.error("AccessDenied Error FOUND !!!! ");
			const navigationStoreREF = this.storeFactory.GlobalNavigationStore;
			console.error("AccessDenied > Screen Name "+navigationStoreREF.currentScreenName);
			
			switch (navigationStoreREF.currentScreenName) {

				case navigationStoreREF.SCREEN.PAGE_NOT_FOUND:
					break;

				case navigationStoreREF.SCREEN.CATALOG:
					runInAction(" accessDeniedCheckDisposer Catalog case ", () => {
						navigationStoreREF.setContextualParam("missing", {
							"module" : "Catalog",
							"type" : this.notFoundCategory || "Catalog"
						});
						navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAGE_NOT_FOUND);
					});
					break;

				case navigationStoreREF.SCREEN.PRODUCT:
					runInAction(" accessDeniedCheckDisposer Product case ", () => {
						navigationStoreREF.setContextualParam("missing", {
							"module" : "Product",
							"type" : this.notFoundCategory || "Product"
						});
						navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAGE_NOT_FOUND);
					});
					break;

				case navigationStoreREF.SCREEN.SHOP:
					runInAction(" accessDeniedCheckDisposer Shop case ", () => {
						navigationStoreREF.setContextualParam("missing", {
							"module" : "Shop",
							"type" : this.notFoundCategory || "Shop"
						});
						navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAGE_NOT_FOUND);
					});
					break;

				default:
					if(navigationStoreREF.currentScreenName != navigationStoreREF.SCREEN.LOGIN){
						runInAction(" accessDeniedCheckDisposer default case ", () => {
							navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
							navigationStoreREF.setContextualParam("REF", {
								"redirectURLRef" : window.location.pathname
							});
							console.log(" >> REF : "+window.location.pathname);
							navigationStoreREF.changeModule("");
							navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE); 
						});
					}
					break;
			}

			this.accessDenied = false;
			this.notFound = false;
			this.notFoundCategory = null;
        }	
	}, {name: "accessDeniedCheckDisposer"});
	
	serverUnderMaintenanceCheckDisposer = autorun(() => {
		console.log("==== Error Store Autorun | Server Under Maintenance Check Activated.....");
        if(this.serverUnderMaintenance){
			console.error("Server Under Maintenance Error FOUND !!!! ");
			const navigationStoreREF = this.storeFactory.GlobalNavigationStore;
			console.error("Server Under Maintenance > Screen Name "+navigationStoreREF.currentScreenName);
			
			switch (navigationStoreREF.currentScreenName) {

				case navigationStoreREF.SCREEN.SERVER_UNDER_MAINTENANCE:
					break;

				default:
					navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SERVER_UNDER_MAINTENANCE);
					break;
			}

	    	this.serverUnderMaintenance = false;
        }	
    }, {name: "serverUnderMaintenanceCheckDisposer"});
}
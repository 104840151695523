class WindowUtil {

    req = null;

    initialize(httpRequest){
        this.req = httpRequest;
    }

    get Protocol(){
        if(typeof window === "undefined"){
            if(this.req){
                return this.req.protocol+":";
            }else{
                throw new Error("Request Object not set, please call WindowUtil's initialize method.")
            }
        } else {
            return window.location.protocol;
        }
    }

    get HostName(){
        if(typeof window === "undefined"){
            if(this.req){
                return this.req.hostname;
            }else{
                throw new Error("Request Object not set, please call WindowUtil's initialize method.")
            }
        } else {
            return window.location.hostname;
        }
    }

    get Port(){
        if(typeof window === "undefined"){
            if(this.req){
                const port = this.req.headers.host.split(':')[1];
                return port || "";
            }else{
                throw new Error("Request Object not set, please call WindowUtil's initialize method.")
            }
        } else {
            return window.location.port;
        }
    }

    get URL(){
        if(typeof window === "undefined"){
            if(this.req){
                const url = this.req.protocol + '://' + this.req.get('host') + this.req.originalUrl;
                return url || "";
            }else{
                throw new Error("Request Object not set, please call WindowUtil's initialize method.")
            }
        } else {
            return window.location.href;
        }
    }

    get ScreenWidth(){
        return window.screen.width;
    }
}

export default new WindowUtil();
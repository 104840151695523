const englishPack = {
	"bt_signin":"Sign In",
	"bt_signin_sec":"Already have an account ? Sign In",
	"bt_signin_ter":"Back to Login",
	"bt_forgotpassword":"Forgot Password",
	"bt_signup":"Register",
	"bt_signup_sec":"Don't have an account ? Register",
	"bt_signup_ter":"Back to Registration!",
	"bt_request_reset_link":"Request OTP",
	"bt_resetpassword":"Reset Password",
	"ph_email":"Email",
	"ph_password":"Password",
	"ph_password_register":"Password ( minimum 8 characters )",
	"ph_customername":"Name",
	"ph_mobilenumber":"Mobile Number",
	"ph_newpassword":"New Password",
	"ph_confirmpassword":"Confirm Password",
	"lb_login_header":"Login",
	"lb_login_subHeader":"Hello! Let's get started.",
	"lb_fp_header":"Did you forget your password?",
	"lb_fp_subHeader":"Please enter your registered email id and we will send you OTP.",
	"lb_register_header":"Welcome to Indcatalog",
	"lb_register_subHeader":"Join our BETA program, now!",
	"lb_rp_header":"Reset Your Password",
	"lb_rp_subHeader":"Enter your new password below.",
	"lb_register_success_header":"Registered Successfully !",
	"lb_register_success_subheader":"Please check your registered email, to verify the account.",
	"lb_register_name_error_header":"Missing Name",
	"lb_register_name_error_subheader":"Please provide your name.",
	"lb_register_email_error_header":"Invalid Email",
	"lb_register_email_error_subheader":"Please provide a valid email id",
	"lb_register_mobile_error_header":"Invalid Mobile Number",
	"lb_register_mobile_error_subheader":"Please provide a valid 10 digit mobile number.",
	"lb_fp_success_header":"Email Sent Successfully !",
	"lb_fp_success_subheader":"Please use the link in the email to reset your password.",
	"lb_rp_success_header":"Reset Password Successfull !",
	"lb_rp_success_subheader":"Please try login with new password.",
	"lb_rp_error_passwordmismatch":"Your password confirmation did not match your password.",
	"lb_uv_header_success":"Your account has been successfully verified!",
	"lb_uv_header_error":"Your account verification failed!",
	"lb_uv_loadingmessage":"Please wait while we verify your account.",
	"lb_uv_success_header":"Our team will review your application and get back to you,  shortly.",
	"lb_uv_error":"Your email account verification has failed.",
	"lb_uv_error_subheader":"Please try registration again.",
	"lb_app_logo_header":"Transform your product line into an online store or website in minutes!",
	"lb_app_logo_slider1_caption":"Apparel",
	"lb_app_logo_slider2_caption":"Accessories",
	"lb_app_logo_slider3_caption":"Footwear",
	"lb_app_logo_slider4_caption":"Jewellery",
	"lb_app_logo_slider5_caption":"Furnishings"
};

const hindiPack = {
	"bt_signin":"दाखिल हों",
	"bt_signin_sec":"पहले से खाता मौजूद है? दाखिल हों",
	"bt_signin_ter":"वापस दाखिल हों!",
	"bt_forgotpassword":"पासवर्ड भूल गए",
	"bt_signup":"सदस्य बनें",
	"bt_signup_sec":"सदस्य नहीं है? सदस्य बनें!",
	"bt_signup_ter":"पंजीकरण के लिए वापस!",
	"bt_request_reset_link":"अनुरोध रीसेट लिंक",
	"bt_resetpassword":"पासवर्ड रीसेट करें",
	"ph_email":"ईमेल",
	"ph_password":"पारण शब्द",
	"ph_password_register":"पारण शब्द (कम से कम 8 अक्षर)",
	"ph_customername":"नाम",
	"ph_mobilenumber":"मोबाइल नंबर",
	"ph_newpassword":"नया पासवर्ड",
	"ph_confirmpassword":"पासवर्ड की पुष्टि कीजिये",
	"lb_login_header":"प्रवेश",
	"lb_login_subHeader":"नमस्ते! आइए शुरू करते हैं।",
	"lb_fp_header":"क्या आप अपना पासवर्ड भूल गए ?",
	"lb_fp_subHeader":"अपना ईमेल आईडी दर्ज करें और हम आपको एक पासवर्ड रीसेट लिंक भेजेंगे।",
	"lb_register_header":"इन्डकैटलॉग में आपका स्वागत है।",
	"lb_register_subHeader":"हमारे बीटा कार्यक्रम में शामिल हों!",
	"lb_rp_header":"अपना पासवर्ड रीसेट करें",
	"lb_rp_subHeader":"नीचे अपना नया पासवर्ड डालिए।",
	"lb_register_success_header":"सफलतापूर्वक पंजीकृत !",
	"lb_register_success_subheader":"खाता सत्यापित करने के लिए कृपया अपना पंजीकृत ईमेल देखें।",
	"lb_register_name_error_header":"गुम नाम",
	"lb_register_name_error_subheader":"अपना नाम प्रदान करें।",
	"lb_register_email_error_header":"अवैध ईमेल",
	"lb_register_email_error_subheader":"एक मान्य ईमेल आईडी प्रदान करें",
	"lb_register_mobile_error_header":"अमान्य मोबाइल नंबर",
	"lb_register_mobile_error_subheader":"मान्य 10 अंकों मोबाइल नंबर प्रदान करें।",
	"lb_fp_success_header":"ईमेल सफलतापूर्वक भेजा गया!",
	"lb_fp_success_subheader":"कृपया अपना पासवर्ड रीसेट करने के लिए ईमेल में लिंक का उपयोग करें।",
	"lb_rp_success_header":"रीसेट पासवर्ड सफल!",
	"lb_rp_success_subheader":"कृपया नए पासवर्ड के साथ लॉगिन का प्रयास करें।",
	"lb_rp_error_passwordmismatch":"आपके पासवर्ड की पुष्टि आपके पासवर्ड से मेल नहीं खाती।",
	"lb_uv_header_success":"आपका खाता सफलतापूर्वक सत्यापित किया गया है!",
	"lb_uv_header_error":"आपके खाता सत्यापन में विफल रहा!",
	"lb_uv_loadingmessage":"हम आपका खाता सत्यापित तक कृपया प्रतीक्षा करें।",
	"lb_uv_success_header":"हमारी टीम आपके आवेदन की समीक्षा, जल्द ही आप तक मिल जाएगा।",
	"lb_uv_error":"आपका ईमेल खाता सत्यापन विफल हो गया है।",
	"lb_uv_error_subheader":"कृपया पुनः पंजीकरण का प्रयास करें।",
	"lb_app_logo_header":"एक ऑनलाइन स्टोर या मिनटों में वेबसाइट में अपने उत्पाद लाइन को बदलने!",
	"lb_app_logo_slider1_caption":"परिधान",
	"lb_app_logo_slider2_caption":"सामान",
	"lb_app_logo_slider3_caption":"जूते",
	"lb_app_logo_slider4_caption":"आभूषण",
	"lb_app_logo_slider5_caption":"असबाब"
};

const hindiDigitPack = ['०','१','२','३','४','५','६','७','८','९'];

const englishDigitPack = ['0','1','2','3','4','5','6','7','8','9'];

const languagePackRepository = {"en" : englishPack, "hi" : hindiPack, 'hi-digit' : hindiDigitPack, 'en-digit' : englishDigitPack};

export const languagePack = (lang) => {
	return languagePackRepository[lang];
}
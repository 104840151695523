import ShopThemeStore 			from '../../shop/store/Shop-ThemeStore';
import ThemeFactory 			from '../../shop/utils/Theme-Factory';
import NavigationRepository  	from '../store/NavigationStore';
import ErrorStore 				from '../store/ErrorStore';
import CurrencyStore 			from '../store/CurrencyStore';
import ShopAnalyticsStore 		from '../../shop/store/Shop-AnalyticsStore';


export class StoreFactory {

	_storeRepositoryInstance = null;
	_navigationRepositoryInstance = null;
	_themeFactoryInstance = null;
	_shopThemeStoreInstance = null;
	_errorStore = null;
	_currencyStore = null;
	_analyticsStore = null;

	constructor(){
		this._navigationRepositoryInstance = new NavigationRepository();
		this._shopThemeStoreInstance = new ShopThemeStore();
		this._themeFactoryInstance = new ThemeFactory();
		this._errorStore = new ErrorStore(this);
		this._currencyStore = new CurrencyStore();
	}

	_generateUniqueKey(subjectType, subjectInfo){
		let comboKey = subjectType; //Same as ${subjectInfo.Type}
		if(subjectInfo){
			if(subjectInfo.uid){
				comboKey = comboKey + "#" + subjectInfo.uid;
			}
			if(subjectInfo.PREDICATE_TYPE){
				comboKey = comboKey + "#" + subjectInfo.PREDICATE_TYPE;
			}
			if(subjectInfo.ContainerType){
				comboKey = comboKey + "#" + subjectInfo.ContainerType;
				//delete data.ContainerType;
			}
		}
		//console.log("KEY: Generated Key for : ");
		//console.log("i/p : "+JSON.stringify(data));
		//console.log("o/p : "+comboKey);
		return comboKey;
	}

	set StoreRepository(storeRepositoryInstance){
		this._storeRepositoryInstance = storeRepositoryInstance;
	}

	get StoreRepository(){
		return this._storeRepositoryInstance;
	}

	get NavigationRepository(){
		return this._navigationRepositoryInstance;
	}

	get NavigationStore(){
		return this._navigationRepositoryInstance.currentNavigationStore();
	}

	get GlobalNavigationStore(){
		return this._navigationRepositoryInstance.currentNavigationStore(true);
	}

	/**
	 * Moved module store to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and (LanguageStore, ErrorStore)
	 */
	get ModuleStore(){
		return this.StoreRepository.ModuleStore;
	}

	get ThemeFactory(){
		return this._themeFactoryInstance;
	}

	get ShopThemeStore(){
		return this._shopThemeStoreInstance;
	}

	/**
	 * Moved shopUserStore to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and ErrorStore
	 */
	get ShopUserStore(){
		return this.StoreRepository.ShopUserStore;
	}

	/**
	 * Moved shopLoginStore to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and ErrorStore
	 */
	get ShopLoginStore(){
		return this.StoreRepository.ShopLoginStore;
	}

	get ErrorStore(){
		return this._errorStore;
	}

	get CurrencyStore(){
		return this._currencyStore;
	}

	get AnalyticsStore(){
		return this._analyticsStore;
	}
	

	//param1 - standard subject type based on modules
	//param2 - subject type based on relationship (Predicate)
	fetchStore(SUBJECT_TYPE, X_SUBJECT_TYPE){
		
		let subjectInfo = X_SUBJECT_TYPE ? {...X_SUBJECT_TYPE} : {"Type" : SUBJECT_TYPE};
		const storeKey = this._generateUniqueKey(SUBJECT_TYPE, subjectInfo);
		let store = this.StoreRepository.getStore(storeKey);

		if(!store){
			store = this.StoreRepository.initializeStore(SUBJECT_TYPE, subjectInfo);
			this.StoreRepository.addStore(storeKey, store);
		}

		if(store && !store.StoreFactory)
			store.StoreFactory = this;

		return store;
	}

	removeStore(context){
		const storeKey = this._generateUniqueKey(context);
		this.StoreRepository.removeStore(storeKey);
	}

	resetStore(){
		this.StoreRepository.resetRepository();
	}

	deHydrateStore(){
		return this.StoreRepository.deHydrateRepository();
	}

	hydrateStore(initialStoreData){
		
		this.StoreRepository.hydrateRepository(initialStoreData);
		
		//Hydrating CurrencyStore...
		const publicShopStore = this.fetchStore('PUBLICWEBSHOPS');
		if(publicShopStore && publicShopStore.shopOwnerInfo){
			this.CurrencyStore.registerCurrency(publicShopStore.shopOwnerInfo);
		}else{
			console.error("ERROR !!! Could not hydrate CurrencyStore....");
		}

		//Initialize Analytics Store
		const shop = publicShopStore.getCurrentItem();
		if(shop){
			const analyticsID = shop.DATA.GoogleAnalyticsID;
			if(analyticsID){
				const ownerSubscriptionPlan = publicShopStore.OwnerSubscriptionPlan;
				if(publicShopStore.SubscriptionStore.IsFeatureAuthorizedFor(ownerSubscriptionPlan, "storeAnalytics"))
					this.initializeAnalyticsStore(ownerSubscriptionPlan);
			}
		}
	}

	initializeServerStoreFactory(){
		const storeFactoryInstance = new StoreFactory();
		return storeFactoryInstance;
	}

	//For SSR (Hydration of data on client side)
	initializeFactory(){
		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_STORE_FACTORY__) &&
			(window.__INITIAL_STATE_STORE_FACTORY__ !== "__INITIAL_STATE_STORE_FACTORY__") )
		{
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_STORE_FACTORY__ FOUND ");
			this.hydrateStore(window.__INITIAL_STATE_STORE_FACTORY__);
			delete window.__INITIAL_STATE_STORE_FACTORY__;
		}
	}

	async initializeThemeFactory(){

		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_THEME__) &&
			(window.__INITIAL_STATE_THEME__ !== "__INITIAL_STATE_THEME__") )
		{
			const themeID = window.__INITIAL_STATE_THEME__;
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_THEME__ FOUND : " + themeID);
			await this.ThemeFactory.loadTheme(themeID);
			delete window.__INITIAL_STATE_THEME__;
		}
	}

	initializeAnalyticsStore(){
		if(this._analyticsStore === null){
			this._analyticsStore = new ShopAnalyticsStore(this);
			this._analyticsStore.register();
		}
	}
}

export default new StoreFactory();
import jsonPatch from '@libs/fast-json-patch';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import { action, computed, observable, runInAction, toJS } from "mobx";
import ItemStore from "../../0-common/store/ItemStore";
import baby from '../../0-common/utils/baby';

const SUBJECT_TYPE = "ADDRESS";

export default class ShopAddressStore extends ItemStore {

    FACTORY_STORE_TYPE = "SHOPADDRESS";

    @observable newAddress = {};
    @observable currentEditAddress = {};

    @observable addressValidStatus = {
		name : {
			empty : false,
			invalid : false,
			msg : ""
		},
		streetAddress : {
			empty : false,
			invalid : false,
			msg : ""
		},
		pincode : {
			empty : false,
			invalid : false,
			msg : ""
		},
        city : {
			empty : false,
			invalid : false,
			msg : ""
		},
        state : {
			empty : false,
			invalid : false,
			msg : ""
		},
        country : {
			empty : false,
			invalid : false,
			msg : ""
		},
		mobileNumber : {
			empty : false,
			invalid : false,
			msg : ""
		}
	}

    constructor(subjectInfo){
		super(subjectInfo);
    }
    
	@computed
	get AddressForEdit(){
		return this.currentEditAddress;
    }

    @computed
	get isAddressNameInValid(){
		return (this.addressValidStatus.name.empty);
	}

    @computed
	get addressNameErrorMsg(){
		return this.addressValidStatus.name.msg;
	}

    @computed
	get isAddressMobileNumberInValid(){
		return (this.addressValidStatus.mobileNumber.empty || this.addressValidStatus.mobileNumber.invalid);
	}

	@computed
	get addressMobileNumberErrorMsg(){
		return this.addressValidStatus.mobileNumber.msg;
	}

    @computed
	get isAddressStreetAddressInValid(){
		return (this.addressValidStatus.streetAddress.empty || this.addressValidStatus.streetAddress.invalid);
	}

	@computed
	get addressStreetAddressErrorMsg(){
		return this.addressValidStatus.streetAddress.msg;
	}

    @computed
	get isAddressPincodeInValid(){
		return (this.addressValidStatus.pincode.empty || this.addressValidStatus.pincode.invalid);
	}

	@computed
	get addressPincodeErrorMsg(){
		return this.addressValidStatus.pincode.msg;
	}

    @computed
	get isAddressCityInValid(){
		return (this.addressValidStatus.city.empty);
	}

	@computed
	get addressCityErrorMsg(){
		return this.addressValidStatus.city.msg;
	}

    @computed
	get isAddressStateInValid(){
		return (this.addressValidStatus.state.empty);
	}

	@computed
	get addressStateErrorMsg(){
		return this.addressValidStatus.state.msg;
	}

    @computed
	get isAddressCountryInValid(){
		return (this.addressValidStatus.country.empty);
	}

	@computed
	get addressCountryErrorMsg(){
		return this.addressValidStatus.country.msg;
	}

    @action.bound
	resetAddressValidStatus(fieldName){
		if(fieldName){
			this.addressValidStatus[fieldName] = {
				empty : false,
				invalid : false,
				msg : ""
			}
		} else {
			this.addressValidStatus = {
				name : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                streetAddress : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                pincode : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                city : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                state : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                country : {
                    empty : false,
                    invalid : false,
                    msg : ""
                },
                mobileNumber : {
                    empty : false,
                    invalid : false,
                    msg : ""
                }
			}
		}
	}

    @action
    reset(){
        this.newAddress = {}
        this.currentEditAddress = {};
        this.clearRepository();
    }
    
    @action
    setAddressForEdit(addressID){
        const addressToBeEdited = _find(this.ItemList, {"DATA":{"uid":addressID}} );
        if(addressToBeEdited){
            this.currentEditAddress = toJS(addressToBeEdited);
            this.patchObserver = jsonPatch.observe(this.currentEditAddress.DATA);
        }
	}

    initializeNewAddress(){
        this.newAddress.DATA = {};
        this.patchObserver = jsonPatch.observe(this.newAddress.DATA);
    }

    @action
	updateNewAddressProperty(propertyName, propertyValue) {
		this.newAddress.DATA[propertyName] = propertyValue;
    }

    @action
    editAddressProperty(propertyName, propertyValue) {
		this.currentEditAddress.DATA[propertyName] = propertyValue;
    }

    validateAddressForm(addressData){
        let isAddressFormValid = true;

        //Name Field validation
		if( _isEmpty(addressData.Name) ){
			this.addressValidStatus.name.empty = true;
			this.addressValidStatus.name.msg = "Please enter your name."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.name.empty = false;
		}

        //MobileNumber Field validation
        const addressMobileNumber = addressData.addressmobilenumber ? addressData.addressmobilenumber+"" : "";//isEmpty doesn't work for SomeNumber data type, hence stringifying
		const mobileNumberCheckRegex = /^\d{10}$/;
		const isMobileNumberValid = mobileNumberCheckRegex.test(addressMobileNumber);
		if( _isEmpty(addressMobileNumber) ){//isEmpty doesn't work for SomeNumber data type, hence stringifying
			this.addressValidStatus.mobileNumber.empty = true;
			this.addressValidStatus.mobileNumber.msg = "Please enter your mobile number."
			isAddressFormValid = false;
		} else if(!isMobileNumberValid){
			this.addressValidStatus.mobileNumber.invalid = true;
			this.addressValidStatus.mobileNumber.msg = "Mobile number must be valid and contain 10 digits."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.mobileNumber.empty = false;
			this.addressValidStatus.mobileNumber.invalid = false;
		}

        //StreetAddress Field validation
		if( _isEmpty(addressData.streetaddress) ){
			this.addressValidStatus.streetAddress.empty = true;
			this.addressValidStatus.streetAddress.msg = "Please enter your street address."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.streetAddress.empty = false;
		}

        //PinCode Field validation
        const addressPinCode = addressData.pincode;
		const pinCodeCheckRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
		const isPinCodeValid = pinCodeCheckRegex.test(addressPinCode);
		if( _isEmpty(addressPinCode) ){
			this.addressValidStatus.pincode.empty = true;
			this.addressValidStatus.pincode.msg = "Please enter your pincode."
			isAddressFormValid = false;
		} else if(!isPinCodeValid){
			this.addressValidStatus.pincode.invalid = true;
			this.addressValidStatus.pincode.msg = "Pincode number must be valid."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.pincode.empty = false;
			this.addressValidStatus.pincode.invalid = false;
		}

        //City Field validation
		if( _isEmpty(addressData.city) ){
			this.addressValidStatus.city.empty = true;
			this.addressValidStatus.city.msg = "Please enter your city."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.city.empty = false;
		}

        //State Field validation
		if( _isEmpty(addressData.state) ){
			this.addressValidStatus.state.empty = true;
			this.addressValidStatus.state.msg = "Please enter your state."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.state.empty = false;
		}

        //Country Field validation
		if( _isEmpty(addressData.country) ){
			this.addressValidStatus.country.empty = true;
			this.addressValidStatus.country.msg = "Please enter your country."
			isAddressFormValid = false;
		} else {
			this.addressValidStatus.country.empty = false;
		}

        return isAddressFormValid;
    }
    
    @action
    async addNewAddress(){
        try{
            //this.isLoading = true; //loading status handled by the caller
            const isAddressFormValid = this.validateAddressForm(this.newAddress.DATA);
			if( !isAddressFormValid ){
				//this.isLoading = false; //loading status handled by the caller
				return null;
			}
            const patch =  jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
                const ownerUserID = this.SUBJECT_INFO.uid;
                const addressOwnerPatch = {
                    "op":"add", 
                    "path":"/Addresses", 
                    "value": ownerUserID
                };
                patch.push(addressOwnerPatch);
				const itemInfo = {
                    "Name" : this.newAddress.DATA.Name,
                    "Type" : "ADDRESS"
                };
                const response = await this.addNewAddressService(itemInfo, patch);
                runInAction("ON Add New Address", () => {
                    jsonPatch.unobserve(this.newAddress.DATA, this.patchObserver);
                    this.newAddress = {};
                    this.dirtyStatus.isDirty = false;
					response.onComplete();
                });
                return response.SUBJECT.DATA.uid;
			}
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            //this.isLoading = false;	//loading status handled by the caller
        }
    }

    @action
    async updateAddress(){
        try{
            this.isLoading = true;
            const isAddressFormValid = this.validateAddressForm(this.currentEditAddress.DATA);
			if( !isAddressFormValid ){
				this.isLoading = false;
				return null;
			}
            const patch =  jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
				const itemInfo = {
                    "Type" : "ADDRESS",
                    "uid" : this.currentEditAddress.DATA.uid
                };
                const response = await this.updateAddressService(itemInfo, patch);
                runInAction("ON Update Address", () => {
                    jsonPatch.unobserve(this.currentEditAddress.DATA, this.patchObserver);
                    this.currentEditAddress = {};
                    this.dirtyStatus.isDirty = false;
					response.onComplete();
				});
                return response.SUBJECT.DATA.uid;
			}
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    @action
    cancelAddressChanges(mode){
        try{
            this.isLoading = true;
            if(mode === "EDIT"){
                jsonPatch.unobserve(this.currentEditAddress.DATA, this.patchObserver);
                this.currentEditAddress = {};
            } else {
                jsonPatch.unobserve(this.newAddress.DATA, this.patchObserver);
                this.newAddress = {};
            }
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't cancelAddressChanges", "ON cancelAddressChanges");
        }
        finally {
            this.isLoading = false;
            this.resetAddressValidStatus();
        }
    }

    @action
    async removeAddress(addressID){
        try{
            this.isLoading = true;
            const itemInfo = {
                "Type" : "ADDRESS",
                "uid" : addressID
            };
            const response = await this.removeAddressService(itemInfo);
            runInAction("ON Remove Address", () => {
                response.onComplete();
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    //============================== Service Layer ===============================//

    async addNewAddressService(itemInfo, newAddressJsonPatch) {
		try {
            const shopStore = this.StoreFactory.fetchStore('PUBLICWEBSHOPS');
            let appOwnerID = null;
            let shopID = null;
            if(shopStore.OwnerInfo){
                appOwnerID = shopStore.OwnerInfo.SourceID;
                shopID = shopStore.ShopID;
            }
            const params = {
                "APP_OWNER": appOwnerID,
                "ShopID": shopID,
                "Type":itemInfo.Type, 
                "Name":itemInfo.Name, 
                "Category" : itemInfo.Category, 
                "PATCH" : newAddressJsonPatch
            };
			const response = await baby.post('/createaddress', params);
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("addNewAddressService Failed with Error : "+error);
			throw error;
		}
    }

    async updateAddressService(itemInfo, updateAddressJsonPatch) {
		try {
            const shopStore = this.StoreFactory.fetchStore('PUBLICWEBSHOPS');
            let appOwnerID = null;
            let shopID = null;
            if(shopStore.OwnerInfo){
                appOwnerID = shopStore.OwnerInfo.SourceID;
                shopID = shopStore.ShopID;
            }
            const params = {
                "APP_OWNER": appOwnerID,
                "ShopID": shopID,
                "Type":itemInfo.Type, 
                "uid":itemInfo.uid, 
                "Category" : itemInfo.Category, 
                "PATCH" : updateAddressJsonPatch
            };
			const response = await baby.post('/updateaddress',  params);
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("updateAddressService Failed with Error : "+error);
			throw error;
		}
    }

    async removeAddressService(itemInfo) {
		try {
            const shopStore = this.StoreFactory.fetchStore('PUBLICWEBSHOPS');
            if(shopStore.OwnerInfo){
                itemInfo.APP_OWNER = shopStore.OwnerInfo.SourceID;
                itemInfo.ShopID = shopStore.ShopID;
            }
			const response = await baby.post('/deleteaddress', itemInfo );
			return this.processResponseDataForDelete(response.data, itemInfo);
		} 
		catch (error) {
			console.error("removeAddressService Failed with Error : "+error);
			throw error;
		}
    }
    
    //@Override
    async getSubjectsService(subjectInfo, page, filter, searchString) {
		try {
            const shopStore = this.StoreFactory.fetchStore('PUBLICWEBSHOPS');
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
            if(searchString){
                param = {...param, "SEARCHSTRING": searchString, "OR" : true};
            }
            
            if(shopStore.OwnerInfo){
                param.APP_OWNER = shopStore.OwnerInfo.SourceID;
                param.ShopID = shopStore.ShopID;
            }
			const response = await baby.post('/getaddresses', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getSubject's Service Failed with Error : "+error);
			throw error;
		}				
	}
}
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { action, runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';

export default class OrderStore extends ItemStore {

  FACTORY_STORE_TYPE = "ORDERS";
  
  getItemCountInOrder(order){
    if(order && order.DATA){
        const itemsInOrder = order.DATA.ItemsInOrder;
        if(itemsInOrder){
            let itemQuantity = 0;
            _forEach(itemsInOrder, (orderItemID) => {
                const orderItem = order.REFERENCES[orderItemID];
                if(orderItem){
                    itemQuantity = itemQuantity + orderItem.Quantity;
                }
            })
            return itemQuantity;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
  }

  getItemsInOrder(order){
    if(order && order.DATA){
        const itemsInOrder = order.DATA.ItemsInOrder;
        if(itemsInOrder){
          return _map(itemsInOrder, (orderItemID) => {
              const orderItem = order.REFERENCES[orderItemID];
              if(orderItem.ProductsInOrder && orderItem.ProductsInOrder.length > 0){
                  const productID = orderItem.ProductsInOrder[0];
                  const product = order.REFERENCES[productID];
                  const CATEGORY_MASTERS_Size = product.CATEGORY_MASTERS_Size;
                  return {
                      DATA: { ...orderItem, CATEGORY_MASTERS_Size },
                      OPTIONS : order.OPTIONS,
                      REFERENCE_IMAGES : order.REFERENCE_IMAGES,
                      Quantity : orderItem.Quantity,
                      Option : orderItem.Option,
                      OrderItemID : orderItem.uid
                  };
              }
              
          });
      } else {
          return [];
      }
    } else {
        return [];
    }
  }

  getProductInOrderItem(order, orderItem){
    if(order && orderItem && orderItem.DATA){
        const productsInOrder = orderItem.DATA.ProductsInOrder;
        if(productsInOrder){
          const productID = productsInOrder[0];
          const product = order.REFERENCES[productID];
          return {
            DATA: { ...product },
            REFERENCE_IMAGES : order.REFERENCE_IMAGES,
          };
      } else {
          return null;
      }
    } else {
        return null;
    }
  }

  async loadOrdersForSeller(currentPage = 1){
    try {
			  this.isLoading = true;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
        const sortOption = this.filterStore.ActiveSortOption;
		    if(filter || searchString || sortOption)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await this.loadOrdersForSellerService(this.SUBJECT_INFO, currentPage, filter, searchString, sortOption);
		    runInAction("On Load Orders For Seller", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
          if(!_isEmpty(response.CATEGORIES)){
            filterList.push(response.CATEGORIES);
          }
          filterList.push(...response.FILTERS);
          this.filterStore.loadFilters(filterList);
          this.filterStore.loadSortOptions(response.SUBJECT_TYPE);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch Order for Seller", "On Load All Orders For Seller Error");
		}
		finally {
			this.isLoading = false;	
		}
  }

  async refreshOrderForSeller(orderId) {
    try {
        this.isLoading = true; // should be outside action/transaction to trigger UI;
        const itemInfo = {
            "uid" : orderId,
            "Type" : "ORDERS"
        };
        const response = await super.getSubjectByType(itemInfo);
        this.saveItem_Success(response);
    }
    catch (error){
        this.isLoading = false;
        this.ErrorStore.log(error, "Couldn't Refresh Order for Seller", "On Refresh Order For Seller Error");
    }
    finally {
        this.isLoading = false;	
    }
  }

  async loadOrdersForCustomer(customerID,currentPage=1) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
		    if(filter || searchString)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await this.loadOrdersForCustomerService(this.SUBJECT_INFO, customerID, currentPage, filter, searchString);
		    runInAction("On Load Order for Customer", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch Order for Customer", "On Load All Orders For Customer Error");
		}
		finally {
			this.isLoading = false;	
		}
    }

  

  //@Override
  @action
	executePagination(activePage){
		this.loadOrdersForSeller(activePage);	
	}

  /* ==========================================  Service Layer  ==========================================  */

    async loadOrdersForSellerService(subjectInfo, page, filter, searchString, sortOption) {
      try {
        let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
        if(filter)
          param = {...param, "FILTER": filter};
        if(page)
          param = {...param, "REQUESTED_PAGE": page};
        if(searchString){
          param = {...param, "SEARCHSTRING": searchString, "OR" : true};
        }
        if(sortOption){
          param = {...param, "ORDER": sortOption.value, "IS_ASC" : (sortOption.sortMode === "asc")};
        }
        const response = await baby.post('/getordersforseller', param);
        return this.processResponseDataForGetAll(response.data);
      } 
      catch (error) {
        this.ErrorStore.log(error, "Couldn't loadOrdersForSeller Service", "On loadOrdersForSellerService Error");
        throw error;
      } 
    }

    async loadOrdersForCustomerService(subjectInfo, customerID, page, filter, searchString) {
      try {
              let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
              if(customerID)
                  param = {...param, "CustomerID": customerID};
              if(filter)
                  param = {...param, "FILTER": filter};
              if(page)
                  param = {...param, "REQUESTED_PAGE": page};
              if(searchString){
                  param = {...param, "SEARCHSTRING": searchString, "OR" : true};
              }
              
              const response = await baby.post('/getordersforcustomer', param);
              return this.processResponseDataForGetAll(response.data);
      } 
      catch (error) {
              this.ErrorStore.log(error, "Couldn't loadOrdersForCustomerService Service", "On loadOrdersForCustomerService Error");
        throw error;
      } 
    }

    //@Override
    async takeWorkflowActionAndFindService(actionName, objectID, subjectInfo, page, filter, searchString, sortOption, patch) {
      try {
          const param = {
              ...subjectInfo, 
              "FIND_WITH_FILTERS": true, 
              "FIND_WITHIN" : true,
              "ObjectID": objectID, 
              "Action": actionName,
              "PATCH" : patch
          };
          if(filter)
              param.FILTER = filter;
          if(page)
              param.REQUESTED_PAGE = page;
          if(searchString){
              param.SEARCHSTRING = searchString;
              param.OR = true;
          }
          if(sortOption){
            param.ORDER = sortOption.value;
            param.IS_ASC = (sortOption.sortMode === "asc");
          }
          const response = await baby.post('/takeorderworkflowactionandfind', param);
          return response.data;
      } 
      catch (error) {
          console.error("ORDER takeWorkflowActionAndFindService For Order Failed with Error : "+error);
          throw error;
      }
    }

}
import {observable, action, runInAction, computed} from 'mobx';
import _forEach from 'lodash/forEach'; 
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import baby from '../../0-common/utils/baby';
import ItemStore from "../../0-common/store/ItemStore";
import {paymentProperties} from "../../0-common/utils/AppProperties";
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';

export default class PublicOrderStore extends ItemStore{

    FACTORY_STORE_TYPE = "PUBLICORDERSTORE";

    constructor(subjectInfo){
        super(subjectInfo);
        this.paymentEnvironmentType = process.env.paymentEnvironment;
    }

    @observable isAddInProgress = false;
    @observable isPaymentLoading = false;
    @observable isPaymentProcessing = false;
    @observable paymentStatusInfo = "";
    @observable openCartView = false;
    @observable notifyAddedToCart = false;
    @observable isLoading_SetDeliveryAddress = false;

    @action
    reset(){
        this.isAddInProgress = false;
        this.isPaymentLoading = false;
        this.isPaymentProcessing = false;
        this.paymentStatusInfo = "";
        this.openCartView = false;
        this.notifyAddedToCart = false;
        this.isLoading_SetDeliveryAddress = false;
        this.currentItem = {};
        this.totalItemCount.set(0);
        this.clearRepository();
    }

    @computed
    get ItemCountInCart(){
        if(this.getCurrentItem()){
            const itemsInOrder = this.currentItem.DATA.ItemsInOrder;
            if(itemsInOrder){
                let itemQuantity = 0;
                _forEach(itemsInOrder, (orderItemID) => {
                    const orderItem = this.currentItem.REFERENCES[orderItemID];
                    if(orderItem){
                        itemQuantity = itemQuantity + orderItem.Quantity;
                    }
                })
                return itemQuantity;
            } else {
                return 0;
            }
        }else {
            return 0;
        }
    }

    @computed
    get ItemsInCart(){
        if(this.getCurrentItem()){
            const itemsInOrder = this.currentItem.DATA.ItemsInOrder;
            if(itemsInOrder){
                return _map(itemsInOrder, (orderItemID) => {
                    const orderItem = this.currentItem.REFERENCES[orderItemID];
                    if(orderItem.ProductsInOrder && orderItem.ProductsInOrder.length > 0){
                        const productID = orderItem.ProductsInOrder[0];
                        return {
                            DATA: { ...this.currentItem.REFERENCES[productID], DiscountedPrice : orderItem.Price, RetailPrice : orderItem.RetailPrice},
                            OPTIONS : this.currentItem.OPTIONS,
                            REFERENCE_IMAGES : this.currentItem.REFERENCE_IMAGES,
                            Quantity : orderItem.Quantity,
                            Option : orderItem.Option,
                            OrderItemID : orderItem.uid
                        };
                    }
                });
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    getProductOptionAddedInCartFor(productID){
        let productOptionAddedInCart = {};
        if(this.getCurrentItem()){
            const itemsInOrder = this.currentItem.DATA.ItemsInOrder;
            if(itemsInOrder){
                _forEach(itemsInOrder, (orderItemID) => {
                    const orderItem = this.currentItem.REFERENCES[orderItemID];
                    if(orderItem.ProductsInOrder && orderItem.ProductsInOrder.length > 0){
                        const orderItemProductID = orderItem.ProductsInOrder[0];
                        if(orderItemProductID === productID){
                            productOptionAddedInCart[orderItem.Option] = true;
                        }
                    }
                });
            }
        }
        return productOptionAddedInCart;
    }

    getItemCountInOrder(order){
        if(order && order.DATA){
            const itemsInOrder = order.DATA.ItemsInOrder;
            if(itemsInOrder){
                let itemQuantity = 0;
                _forEach(itemsInOrder, (orderItemID) => {
                    const orderItem = order.REFERENCES[orderItemID];
                    if(orderItem){
                        itemQuantity = itemQuantity + orderItem.Quantity;
                    }
                })
                return itemQuantity;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    getItemsInOrder(order){
        if(order && order.DATA){
            const itemsInOrder = order.DATA.ItemsInOrder;
            if(itemsInOrder){
                return _map(itemsInOrder, (orderItemID) => {
                    const orderItem = order.REFERENCES[orderItemID];
                    if(orderItem.ProductsInOrder && orderItem.ProductsInOrder.length > 0){
                        const productID = orderItem.ProductsInOrder[0];
                        const product = order.REFERENCES[productID];
                        const CATEGORY_MASTERS_Size = product.CATEGORY_MASTERS_Size;
                        return {
                            DATA: { ...orderItem, CATEGORY_MASTERS_Size },
                            OPTIONS : order.OPTIONS,
                            REFERENCE_IMAGES : order.REFERENCE_IMAGES,
                            Quantity : orderItem.Quantity,
                            Option : orderItem.Option,
                            OrderItemID : orderItem.uid
                        };
                    }
                    
                });
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    @action
    initializeCart(activeOrder){
        this.currentItem = activeOrder;
        /*
        *   Commented below, to avoid adding the Order of status OrderInProgress/OrderPaymentPending in the SubjectRepository.
        *   Bcoz, SubjectRepository orders are completed one's and are used to show Buyer's Order Timeline.
        */
        //this.updateRepository(activeOrder);
    }

    @action
    async executeAddProductToCart(sellerID, shopID, productInfo){
        try {
            const {productId, productOption, productOptionText} = productInfo;
            this.isLoading = true;
            this.isAddInProgress = true;
            let param = {
                "APP_OWNER" : sellerID,
                "ShopID"    : shopID,
                "Product"   : productId,
                "Quantity"  : 1,
                "Option"    : productOption,
                "OptionValue" : productOptionText,
                "ShopDomain" : `${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}`
            }
            const response = await this.addProductToCartService(param);
            runInAction("On AddProductToCart Success",() => {
                this.currentItem = response.SUBJECT;
                this.notifyAddedToCart = true;
                response.onComplete();
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute AddProductToCart ", "On Execute AddProductToCart");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Add To Cart' : ${error.message}`, productInfo.context);
        }
        finally {
            this.isLoading = false;
            this.isAddInProgress = false;
        }
    }

    @action
    async executeUpdateProductInCart(sellerID, shopID, productInfo, productQuantity){
        try {
            this.isLoading = true;
            let param = {
                "APP_OWNER"     : sellerID,
                "ShopID"        : shopID,
                "OrderID"       : this.currentItem.DATA.uid,
                "OrderItemID"   : productInfo.OrderItemID,
                "Quantity"      : productQuantity,
                "Option"        : productInfo.productOption
            }
            const response = await this.updateProductInCartService(param);
            runInAction("On UpdateProductInCart Success",() => {
                this.currentItem = response.SUBJECT;
                response.onComplete();
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute UpdateProductInCart ", "On Execute UpdateProductInCart");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Update Product In Cart' : ${error.message}`, productInfo.context);
        }
        finally {
            this.isLoading = false;
        }
    }

    @action
    async executeRemoveProductFromCart(sellerID, shopID, productToBeRemoved){
        try {
            this.isLoading = true;
            let param = {
            "APP_OWNER" : sellerID,
            "ShopID"    : shopID,
            "OrderID" : this.currentItem.DATA.uid,
            "OrderItemIDToBeRemoved" : productToBeRemoved.OrderItemID
            };
            const response = await this.removeProductFromCartService(param);
            runInAction("On RemoveProductFromCart Success",() => {
                this.currentItem = response.SUBJECT;
                response.onComplete();
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute RemoveProductToCart ", "On Execute RemoveProductToCart");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Remove Product From Cart' : ${error.message}`, productToBeRemoved.context);
        }
        finally {
            this.isLoading = false;
        }
    }

    @action
    async executeFetchCart(sellerID, shopID){
        try {
            this.isLoading = true;
            this.openCartView = true;
            const param = {
                "APP_OWNER" : sellerID,
                "ShopID"    : shopID
            };
            const response = await this.fetchCartService(param);
            runInAction("On get cart Success",() => {
                if(response.SUBJECT)
                    this.currentItem = response.SUBJECT;
                /*
                *   Commented below, to avoid adding the Order of status OrderInProgress/OrderPaymentPending in the SubjectRepository.
                *   Bcoz, SubjectRepository orders are completed one's and are used to show Buyer's Order Timeline.
                */
                //response.onComplete(); 
                //this.openCartView = true;
                
                emitter.emit(EVENTS.CART.OPEN_DONE);//Added to capture G4 analytics event.
            });
        }
        catch (error){
            this.openCartView = false;
            this.ErrorStore.log(error, "Couldn't execute FetchCart ", "On Execute FetchCart");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Fetch Cart' : ${error.message}`, "Service Handler");
        }
        finally {
            this.isLoading = false;
        }
    }

    async loadOrdersForBuyer(sellerID, shopID, currentPage = 1) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
		    if(filter || searchString)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await this.loadOrdersForBuyerService(this.SUBJECT_INFO, sellerID, shopID, currentPage, filter, searchString);
		    runInAction("On Load Order for buyer", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch Order for Buyer", "On Load All Orders For Buyer Error");
		}
		finally {
			this.isLoading = false;	
		}
    }

    async refreshOrderForBuyer(orderId, sellerID, shopID) {
        try {
            this.isLoading = true; // should be outside action/transaction to trigger UI;
            const param = {
                "uid" : orderId,
                "Type" : "ORDERS",
                "APP_OWNER" : sellerID,
                "ShopID"    : shopID
            };
            const response = await this.refreshOrderForBuyerService(param);
            this.saveItem_Success(response);
        }
        catch (error){
            this.isLoading = false;
            this.ErrorStore.log(error, "Couldn't Refresh Order for Buyer", "On Refresh Order For Buyer Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    @action
    async executeAddPayment(paymentSettingId, paymentProvider, paymentOrderID, totalAmount, currency, shopOwnerInfo, shopID, userInfo){
        try {
            this.isPaymentLoading = true;
            
            const paymentInfo = {
                "OrderId" : this.currentItem.DATA.uid,
                "PaymentSettingId" : paymentSettingId,
                "PaymentOrderId" : paymentOrderID,
                "TransactionAmount" : totalAmount,
                "TransactionCurrency" : currency,
                "CustomerId" : "N/A",
                "PaymentProvider" : paymentProvider,
                "APP_OWNER" : shopOwnerInfo.SourceID,
                "ShopID" : shopID
            }
            await this.initiatePaymentService(paymentInfo);
            emitter.emit(EVENTS.CART.CHECKOUT.MAKE_PAYMENT, "PaypalPaymentGateway");
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute AddPayment ", "On Execute AddPayment");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Make Payment - PaypalPaymentGateway' : ${error.message}`, "Service Handler");
        }
        finally {
            this.isPaymentLoading = false;
        }
    }
        
    @action
    async executeInitiatePayment(paymentSettingId, paymentProvider, totalAmount, currency, shopOwnerInfo, shopID, userInfo){
        try {
            this.isPaymentLoading = true;
            
            const paymentInfo = {
                "OrderId" : this.currentItem.DATA.uid,
                "PaymentSettingId" : paymentSettingId,
                "TransactionAmount" : totalAmount,
                "TransactionCurrency" : currency,
                "CustomerId" : "N/A",
                "PaymentProvider" : paymentProvider,
                "APP_OWNER" : shopOwnerInfo.SourceID,
                "ShopID" : shopID
            }
            
            const response = await this.initiatePaymentService(paymentInfo);
            this.isPaymentLoading = false;
            let options = {
                "key": paymentProperties.PAYMENT_ENVIRONMENT_KEY[this.paymentEnvironmentType], // Enter the Key ID generated from the Dashboard
                "amount": totalAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": currency,
                "name": shopOwnerInfo.Name,
                "description": `${this.currentItem.DATA.Name}`,
                "image": shopOwnerInfo.ProfileImage ? `${shopOwnerInfo.ProfileImage}-/format/auto/-/quality/lightest/-/preview/250x100/` : '',
                "order_id": response.TransactionToken, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": this.callback_processRazorpayOrderPayment.bind(this, this.currentItem.DATA.SourceID, shopOwnerInfo.SourceID, shopID),
                "prefill": {
                    "name": userInfo.DATA.Name,
                    "email": userInfo.DATA.Email,
                    "contact": userInfo.DATA.MobileNumber
                },
                "notes": {
                },
                "theme": {
                    "color": "#f1ba32"
                }
            };

            if(response.PaymentMethod === "upi_only"){
                options.config = {
                    "display": {
                        "hide": [
                            {method: "card"},
                            {method: "netbanking"},
                            {method: "wallet"},
                            {method: "emi"}
                        ],
                        "preferences": {
                            show_default_blocks: true // Should Checkout show its default blocks?
                        }
                    }
                }
            }

            var rzp1 = new Razorpay(options);
            rzp1.on('payment.failed', (response) => {
                console.log(response.error.code);
                console.log(response.error.description);
                console.log(response.error.source);
                console.log(response.error.step);
                console.log(response.error.reason);
                console.log(response.error.metadata.order_id);
                console.log(response.error.metadata.payment_id);
            });
            rzp1.open();
            emitter.emit(EVENTS.CART.CHECKOUT.MAKE_PAYMENT, "IndcatalogPaymentGateway");
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute MakePayment ", "On Execute MakePayment");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Make Payment - IndcatalogPaymentGateway' : ${error.message}`, "Service Handler");
        }
        finally {
            this.isPaymentLoading = false;
        }
    }

    callback_processRazorpayOrderPayment = async (orderID, sellerID, shopID, response) => {
        try {
            this.isPaymentProcessing = true;
            const PaymentProvider = "IndcatalogPaymentGateway";
            const razorpay_payment_id = response.razorpay_payment_id;
            const razorpay_order_id = response.razorpay_order_id;
            const razorpay_signature = response.razorpay_signature;
            const APP_OWNER = sellerID;
            const ShopID = shopID;
            const paymentOrderResponse = {razorpay_payment_id, razorpay_order_id, razorpay_signature, PaymentProvider, APP_OWNER, ShopID};
            await this.processOrderPaymentService(paymentOrderResponse);
            runInAction("On processOrderPaymentService IndcatalogPaymentGateway Success",() => {
                this.NavigationStore.changeScreen(this.NavigationStore.SCREEN.PAYMENT);
                this.NavigationStore.setContextualParam("Order", {
                    "paymentOrderId" : razorpay_order_id,
                    "paymentId" : razorpay_payment_id,
                    "orderId" : orderID,
                });
                emitter.emit(EVENTS.CART.CHECKOUT.PURCHASE, razorpay_order_id);
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute callback_processRazorpayOrderPayment ", "On Execute callback_processRazorpayOrderPayment");
            emitter.emit(EVENTS.SHOP.ERROR, `On callback 'Post Payment - IndcatalogPaymentGateway' : ${error.message}`, "Service Handler");
        }
        finally {
            this.isPaymentProcessing = false;
        }
    }

    callback_processPaypalOrderPayment = async (orderID, sellerID, shopID, response) => {
        try {
            this.isPaymentProcessing = true;
            const PaymentProvider = "PaypalPaymentGateway";
            const PaymentOrderId = response.paymentOrderId;
            const PaymentStatus = response.paymentStatus;
            const APP_OWNER = sellerID;
            const ShopID = shopID;
            const paymentOrderResponse = {PaymentOrderId, PaymentStatus, PaymentProvider, APP_OWNER, ShopID};
            await this.processOrderPaymentService(paymentOrderResponse);
            runInAction("On processOrderPaymentService PaypalPaymentGateway Success",() => {
                this.NavigationStore.changeScreen(this.NavigationStore.SCREEN.PAYMENT);
                this.NavigationStore.setContextualParam("Order", {
                    "paymentOrderId" : PaymentOrderId,
                    "orderId" : orderID,
                });
                emitter.emit(EVENTS.CART.CHECKOUT.PURCHASE, PaymentOrderId);
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute callback_processPaypalOrderPayment ", "On Execute callback_processPaypalOrderPayment");
            emitter.emit(EVENTS.SHOP.ERROR, `On callback 'Post Payment - PaypalPaymentGateway' : ${error.message}`, "Service Handler");
        }
        finally {
            this.isPaymentProcessing = false;
        }
    }
    
    async executeCheckPaymentStatus(paymentOrderId, paymentId, shopOwnerInfo, shopID){
        try {
            this.isLoading = true;
            const paymentOrderInfo = {
                "PaymentOrderId" : paymentOrderId,
                "PaymentId" : paymentId,
                "APP_OWNER" : shopOwnerInfo.SourceID,
                "ShopID" : shopID
            };
            const response = await this.checkPaymentStatusService(paymentOrderInfo);
            runInAction("On CheckPaymentStatus Success",() => {
            this.paymentStatusInfo = response.PaymentStatus;
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute CheckPayment Status ", "On Execute checkPaymentStatus");
        }
        finally {
        this.isLoading = false;
        }
    }

    async setDeliveryAddress(addressID, shopOwnerInfo, shopID){
        try {
            this.isLoading_SetDeliveryAddress = true;
            let param = {
                "OrderID" : this.currentItem.DATA.uid,
                "Type" : "ORDERS",
                "DeliveryAddressID" : addressID,
                "APP_OWNER" : shopOwnerInfo.SourceID,
                "ShopID" : shopID,
            };
            const response = await this.setDeliveryAddressService(param);
            runInAction("On setDeliveryAddress Success",() => {
                this.currentItem = response.SUBJECT;
                response.onComplete();
            });
            return true;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute setDeliveryAddress ", "On Execute setDeliveryAddress");
            emitter.emit(EVENTS.SHOP.ERROR, `On Execute 'Set DeliveryAddress' : ${error.message}`, "Checkout Page");
            return false;
        }
        finally {
            this.isLoading_SetDeliveryAddress = false;
        }
    }

    async removeDeliveryAddress(shopOwnerInfo, shopID){
        try {
        this.isLoading = true;
        let param = {
            "OrderID" : this.currentItem.DATA.uid,
            "Type" : "ORDERS",
            "APP_OWNER" : shopOwnerInfo.SourceID,
            "ShopID" : shopID,
        };
        const response = await this.removeDeliveryAddressService(param);
        runInAction("On removeDeliveryAddress Success",() => {
            this.currentItem = response.SUBJECT;
            response.onComplete();
        });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute removeDeliveryAddress ", "On Execute removeDeliveryAddress");
        }
        finally {
            this.isLoading = false;
        }
    }

    //@Override
    async executeWorkflowAction(actionName, objectID, sellerID, shopID, patch){
		try{
			this.isLoading = true;
            const params = {
				"Type": "ORDERS",
				"ObjectID": objectID,
                "Action": actionName,
                "APP_OWNER": sellerID,
                "ShopID" : shopID
			};
            if(patch && patch.length>0){
                params.PATCH=patch;
            }
			const response = await super.takeWorkflowAction(params);
			this.saveItem_Success(response);
		}
		catch (error){
		  	this.ErrorStore.log(error, "Couldn't execute Shop Order Workflow Action", "On Execute Shop Order Workflow Action");	
	  }	
	  finally {
		  this.isLoading = false;
	  }
	}


    /* ==========================================  Service Layer  ==========================================  */

    async addProductToCartService(productToBeAdded){
        try{
            const serviceResponse = await baby.post('/addproductincart', productToBeAdded);
            const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
            return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add Product To Cart", "On addProductToCartService Error");
            throw error;
        }
    }

    async removeProductFromCartService(productToBeRemoved){
        try{
            const serviceResponse = await baby.post('/removeproductfromcart', productToBeRemoved);
            const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
            return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't remove Product From Cart", "On removeProductFromCartService Error");
            throw error;
        }
    }

    async updateProductInCartService(productToBeUpdated){
        try{
            const serviceResponse = await baby.post('/updateproductincart', productToBeUpdated);
            const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
            return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't update product in Cart", "On updateProductInCartService Error");
            throw error;
        }
    }

    async fetchCartService(requestParam){
        try{
            const serviceResponse = await baby.post('/getcart', requestParam);
            const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
            return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't fetch Cart", "On fetchCartService Error");
            throw error;
        }
    }

    async loadOrdersForBuyerService(subjectInfo, sellerID, shopID, page, filter, searchString) {
		try {
            let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
            if(sellerID)
                param = {...param, "APP_OWNER": sellerID};
            if(shopID)
                param = {...param, "ShopID": shopID};
            if(filter)
                param = {...param, "FILTER": filter};
            if(page)
                param = {...param, "REQUESTED_PAGE": page};
            if(searchString){
                param = {...param, "SEARCHSTRING": searchString, "OR" : true};
            }
            
            const response = await baby.post('/getordersforbuyer', param);
            return this.processResponseDataForGetAll(response.data);
		} 
		catch (error) {
            this.ErrorStore.log(error, "Couldn't loadOrdersForBuyerService Service", "On loadOrdersForBuyerService Error");
			throw error;
		} 
    }

    async setDeliveryAddressService(setDeliveryAddressParam){
        try{
        const serviceResponse = await baby.post('/setdeliveryaddress', setDeliveryAddressParam);
        const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
        return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't setDeliveryAddressService ", "On setDeliveryAddressService Error");
            throw error;
        }
    }

    async removeDeliveryAddressService(removeDeliveryAddressParam){
        try{
        const serviceResponse = await baby.post('/removedeliveryaddress', removeDeliveryAddressParam);
        const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
        return response;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't removeDeliveryAddressService ", "On removeDeliveryAddressService Error");
            throw error;
        }
    }

    async initiatePaymentService(paymentInfo){
        try{
            const serviceResponse = await baby.post('/initiatepayment', paymentInfo);
            return serviceResponse.data;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't initiatePayment Service", "On initiatePaymentService Error");
            throw error;
        }
    }

    async processOrderPaymentService(paymentOrderResponse){
        try{
            await baby.post('/processorderpayment', paymentOrderResponse);
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't execute processOrderPaymentService ", "On processOrderPaymentService Error");
            throw error;
        }
    }

    async checkPaymentStatusService(paymentOrderInfo){
        try{
            const serviceResponse = await baby.post('/checkpaymentstatus', paymentOrderInfo);
            return serviceResponse.data;
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't checkpaymentstatus Service", "On checkpaymentstatusService Error");
            throw error;
        }
    }

    async refreshOrderForBuyerService(param){
        try {
            const response = await baby.post('/getorderforbuyer', param);
            return this.processResponseDataForCreateUpdateRead(response.data);
        } 
        catch (error) {
            this.ErrorStore.log(error, "Couldn't refreshOrderForBuyerService Service", "On refreshOrderForBuyerService Error");
            throw error;
        } 
    }
}
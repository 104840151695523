import { value } from '../../../store/LanguageStore';
import { VerticalConfig } from "../VerticalConfig";
import {languagePack} from './language-pack';

const ph_profileImageURL        =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
const ph_profileImageURL_mini   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/lightest/-/preview/250x100/'; 
const ph_logoImageURL           =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';

const ph_logoImageURL_slider1   =   'https://ucarecdn.com/05cf0aa7-9f64-415f-9290-4a5eedf4d05a/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider2   =   'https://ucarecdn.com/f5411926-45ea-4b5a-a770-2ef41fd113bb/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider3   =   'https://ucarecdn.com/72d4103a-e8c4-47d0-bc82-fa545e4ecdeb/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider4   =   'https://ucarecdn.com/1b2a3e51-137c-4733-b0e8-a2f60079b644/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider5   =   'https://ucarecdn.com/6619c901-5921-4df4-8d07-db5c0ee8c965/-/format/auto/-/quality/smart/';

class LifestyleVerticalConfig extends VerticalConfig{

    //@override
    getSliderConfig(){
        return {
            header : value('lb_app_logo_header'),
            images: [ph_logoImageURL_slider1, ph_logoImageURL_slider2, ph_logoImageURL_slider3, ph_logoImageURL_slider4, ph_logoImageURL_slider5],
            caption: `${value('lb_app_logo_slider1_caption')} | ${value('lb_app_logo_slider2_caption')} | ${value('lb_app_logo_slider3_caption')} | ${value('lb_app_logo_slider4_caption')} | ${value('lb_app_logo_slider5_caption')}`,
        }
    }

    //@override
    /*getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "test",
            shop_subdomain : "testshop"
        }
    }*/

    getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "lifestyle",
            shop_subdomain : "lifestyleshop"
        }
    }

    //@override
    getLogoConfig(){
        return {
            profileImage : ph_profileImageURL,
            profileImage_mini : ph_profileImageURL_mini,
            logoImage : ph_logoImageURL
        }
    }

    //@override
    getInventoryConfig(){
        return {
            visible : true,
        }
    }

    //@override
    getLanguagePack(lang){
        return languagePack(lang);
    }
}

export default new LifestyleVerticalConfig();
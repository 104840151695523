const theme = {
    meta_info :{
        id: "Special",
        name: "Special Theme",
        folder_name: "special"
    },
    root : {
        primaryFont : "Playfair Display, Helvetica",//--primary-font
        secondaryFont : "Playfair Display, Helvetica",//--secondary-font

        primaryColor : "#313131",//--primary-color
        secondaryColor : "#f3f0f1",//--secondary-color

        primaryColorDark : "#171717",//--primary-color-dark
        secondaryColorDark : "#e0dfdf",//--secondary-color-dark

        backgroundColor : "#f3f0f1",//--bg-color
        
        buttonPrimaryColor : "var(--secondary-color)",
        buttonPrimaryBackgroundColor : "var(--primary-color)",
        buttonPrimaryBorderColor : "var(--primary-color)",
        
        buttonPrimaryHoverColor : "var(--secondary-color)",
        buttonPrimaryHoverBackgroundColor : "var(--primary-color)",
        buttonPrimaryHoverBorderColor : "var(--primary-color)",

        buttonSecondaryColor : "var(--primary-color-dark)",
        buttonSecondaryBackgroundColor : "var(--secondary-color)",
        buttonSecondaryBorderColor : "var(--secondary-color)",

        buttonSecondaryHoverColor : "var(--primary-color-dark)",
        buttonSecondaryHoverBackgroundColor : "var(--secondary-color)",
        buttonSecondaryHoverBorderColor : "var(--secondary-color)",

        documentPaddingTop : "2rem", //--padding-top
        documentPaddingRight : "4rem", //--padding-right
        documentPaddingBottom : "2rem", //--padding-bottom
        documentPaddingLeft : "4rem", //--padding-left
    },
    header : {
        height : '6.43rem',
        backgroundColor : 'white',
        fontSize : '1.28571429rem',
        primaryColor : 'black',
        secondaryColor : 'black'
    },
    footer : {
        height : 'auto',
        backgroundColor : 'var(--bg-color)',
        fontSize : '0.85rem',
        primaryColor : 'black',
        secondaryColor : 'black'
    }
}

export default {

    get RootTheme(){
        return theme.root;
    },

    get HeaderTheme(){
        return theme.header;
    },

    get FooterTheme(){
        return theme.footer;
    },

    get Theme(){
        return theme;
    },

    get MetaData(){
        return theme.meta_info;
    }
};
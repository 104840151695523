import storeFactory from '../utils/StoreFactory';
import AssetStore from './AssetStore';

class ImageStore extends AssetStore {

	constructor(){
		super({"Type" : "IMAGE"});
	}

	/*async createImage(imageList, onImageCreateSuccessHandler, onImageCreateFailureHandler){

		try{
			this.isLoading = true;
			let saveImageResponseList = [];
			for(const image of imageList){
				try{
					const createResponse = await super.createAsset(image.Name);
					
					const imageId = createResponse.DATA.uid;
					const imagePatchData = [
						{"op":"add", "path":"/OriginalSourceID", "value": image.OriginalSourceID},
						{"op":"add", "path":"/SourceID", "value": image.SourceID},
						{"op":"add", "path":"/width", "value": image.width},
						{"op":"add", "path":"/height", "value": image.height}
					];	
					const saveImageResponse = await super.saveAsset(imageId, imagePatchData);
					saveImageResponseList.push(saveImageResponse);
					//return saveImageResponse;
				}		
				catch (error){
					console.error("On Update Image Error");
					onImageCreateFailureHandler(error, image);
					//throw error;
				}
			};
			onImageCreateSuccessHandler(saveImageResponseList);
		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't create", "On Create Image Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
	}*/

	async editImage(imageInfo, imageID){

		try{
			this.isLoading = true;
			const imagePatchData = [
				{"op":"remove", "path":"/SourceID", "value": ""},
				{"op":"add", "path":"/SourceID", "value": imageInfo.SourceID},

				{"op":"remove", "path":"/width", "value": ""},
				{"op":"add", "path":"/width", "value": imageInfo.width},

				{"op":"remove", "path":"/height", "value": ""},
				{"op":"add", "path":"/height", "value": imageInfo.height}
			];	
			await super.saveAsset(imageID, imagePatchData);
		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't Edit", "On Edit Image Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
	}

	/*async deleteImage(imageId){
		
		try{
			this.isLoading = true;
			await super.deleteAsset(imageId);
		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't delete", "On Delete Image Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
	}*/
	  
	async createUserProfileImage(imageName, sourceID, imagePatchData){

		try{
			this.isLoading = true;	
			const createResponse = await super.createAsset(imageName, sourceID);
			try{
					const imageId = createResponse.DATA.uid;
					const saveImageResponse = await super.saveAsset(imageId, imagePatchData);
					return saveImageResponse;
			}		
			catch (error){
				console.error("On save Profile Image Error");
				throw error;
			}
		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't create Profile Image", "On Create Profile Image Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
  	}
}

export default new ImageStore();
import _forIn from 'lodash/forIn';
import _assignWith from 'lodash/assignWith';
import _assign from 'lodash/assign';

import { action, toJS } from 'mobx';

import ItemStore 				from '../store/ItemStore';

import ProductStore 			from '../../app/store/ProductStore';
import CollectionStore 			from '../../app/store/CollectionStore';
import CatalogStore 			from '../../app/store/CatalogStore';
import OrderStore 				from '../../app/store/OrderStore';
import CustomersStore          from '../../app/store/CustomerStore'
import ShopStore				from '../../app/store/ShopStore';
import AppAddressStore			from '../../app/store/App-AddressStore';

import PublicShopStore			from '../../shop/store/PublicShopStore';
import ShopSectionStore 		from '../../shop/store/Shop-SectionStore';
import ShopCollectionStore 		from '../../shop/store/Shop-CollectionStore';
import PublicOrderStore 		from '../../shop/store/PublicOrderStore';
import ShopAddressStore 		from '../../shop/store/Shop-AddressStore';

import ShopSearchStore 			from '../../shop/store/Shop-SearchStore';
import SearchStore 				from '../store/SearchStore';

import ModuleStore 				from '../store/ModuleStore';
import shopLoginStore 			from '../../shop/store/Shop-LoginStore';
import shopUserStore 			from '../../shop/store/Shop-UserStore';


//Added for stringify in deHydrateStore
String.prototype.escapeSpecialChars = function() {
    return this.replace(/[']/g, "\\'")
			   .replace(/\\"/g, '\\\\"')
			   .replace(/\\n/g, '\\\\n')
			   .replace(/\\r/g, '\\\\r')
			   .replace(/\\t/g, '\\\\t')
			   .replace(/\\b/g, '\\\\b')
			   .replace(/\\f/g, '\\\\f');
};

export default class StoreRepository {

    _storeRepository = null;
	_moduleStoreInstance = null;

	constructor(){
		this._storeRepository = {};
		this._moduleStoreInstance = new ModuleStore();
	}

	/**
	 * Moved module store to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and (LanguageStore, ErrorStore)
	 */
	get ModuleStore(){
		return this._moduleStoreInstance;
	}

	/**
	 * Moved shopUserStore to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and ErrorStore
	 */
	get ShopUserStore(){
		return shopUserStore;
	}

	/**
	 * Moved shopLoginStore to StoreRepository, because its was creating a cycling dependency bridge between StoreFactory and ErrorStore
	 */
	get ShopLoginStore(){
		return shopLoginStore;
	}

	addStore(storeKey, store){
		this._storeRepository[storeKey] = store;
	}

	getStore(storeKey){
		const store = this._storeRepository[storeKey];
		return store;
	}

    initializeStore(SUBJECT_TYPE, subjectInfo){

		let store = null;

		switch(SUBJECT_TYPE) {

			case "PRODUCTS" : {
				store =  new ProductStore(subjectInfo);
				break;
			}

			case "COLLECTIONS" : {
				store = new CollectionStore(subjectInfo);
				break;
			}

			case "CATALOGS" : {
				store = new CatalogStore(subjectInfo);
				break;
			}
			
			case "ORDERS" : {
				store = new OrderStore(subjectInfo);
				break;
			}

			case "USER" : {
				store = new CustomersStore(subjectInfo);
				break;
			}
			
			case "WEBSHOPS" : {
				store =  new ShopStore(subjectInfo);
				break;
			}

			case "APPADDRESS" : {
				store = new AppAddressStore(subjectInfo);
				break;
			}
			
			/********************** LIVE SHOP ***************************/
			
			case "WEBSECTIONS" : {
				store = new ShopSectionStore(subjectInfo);
				break;
			}

			case "SHOPCOLLECTIONS" : {
				store = new ShopCollectionStore(subjectInfo);
				break;
			}
			
			case "PUBLICWEBSHOPS" : {
				store = new PublicShopStore(subjectInfo);
				break;
			}

			case "PUBLICORDERSTORE" : {
				store = new PublicOrderStore(subjectInfo);
				break;
			}

			case "SHOPADDRESS" : {
				store = new ShopAddressStore(subjectInfo);
				break;
			}

			default : {
				console.error("Store Type found : " + SUBJECT_TYPE)
				console.error("Preparing Generic Store for : " + SUBJECT_TYPE)
				store = new ItemStore(subjectInfo);
				break;
			}
		}
		return store;	
	}

    @action
	removeStore(storeKey){
		const store = this._storeRepository[storeKey];
		if(store && store.clearRepository){
			store.clearRepository();
		}
		delete this._storeRepository[storeKey];
	}

    @action
	resetRepository(){
		_forIn(this._storeRepository, (store, storeKey) => {
			if(store && store.clearRepository){
				store.clearRepository();
			}
		})
		this._storeRepository = {};
	}

    deHydrateRepository(){
		const repositoryData = JSON.stringify(toJS(this._storeRepository), (key, value) => {
			if(key === "storeFactoryRef")
				return null;
			else
				return value;
		});
		return repositoryData.escapeSpecialChars();
	}

    hydrateRepository(storeData){
        const storeRepositoryData = JSON.parse(storeData);
        _forIn(storeRepositoryData, (hydratedStore, storeKey) => {
			const SUBJECT_TYPE = hydratedStore.FACTORY_STORE_TYPE;
			const subjectInfo = hydratedStore.SUBJECT_INFO;
			const storeToBeHydrated = this.initializeStore(SUBJECT_TYPE, subjectInfo);
			this._storeRepository[storeKey] = _assignWith(storeToBeHydrated, hydratedStore, (objValue, srcValue, key) => {
				switch (key) {
					case "filterStore":
						return _assignWith(objValue, srcValue, (filterObjValue, filterSrcValue, filterKey) => {
							switch (filterKey) {
								case "appliedFilter":
								case "appliedTimeSeriesFilter":
									filterObjValue.replace(filterSrcValue);
									return filterObjValue;
							}
						});
					
					case "imageStore":
						return _assign(objValue, srcValue);

					case "totalItemCount":
						objValue.set(srcValue);
						return objValue;

					case "subjectTypeRepository":
						objValue.replace(srcValue);
						return objValue;

					case "navigationStoreREF":
					case "moduleStoreREF":
						return objValue;

					case "searchStore":
						if(srcValue){
							if(storeKey === 'PUBLICWEBSHOPS'){
								return new ShopSearchStore(srcValue.subjectInfo);
							} else {
								return new SearchStore(srcValue.subjectInfo);
							}
						}
				}
			});
		});
    }
}
import { action, observable } from "mobx";
import _find from 'lodash/find';
import _map from 'lodash/map';
import { Header, Label } from "semantic-ui-react";
import React from 'react';

const currencySymbolMap = {
    inr : "₹",
    usd : "$",
    aud : "$",
    brl : "R$",
    cad : "$",
    cny : "¥",
    czk : "Kč",
    dkk : "kr",
    eur : "€",
    hkd : "$",
    huf : "ft",
    ils : "₪",
    jpy : "¥",
    myr : "RM",
    mxn : "$",
    twd : "$",
    nzd : "$",
    nok : "kr",
    php : "₱",
    pln : "zł",
    gbp : "£",
    rub : "₽",
    sgd : "$",
    sek : "kr",
    chf : "CHF",
    thb : "฿",
}

const currencyLocaleCodeMap = {
    inr : "en-IN",
    usd : "en-US",
    aud : "en-AU",
    brl : "pt-BR",
    cad : "fr-CA",
    cny : "zh-CN",
    czk : "cs-CZ",
    dkk : "da-DK",
    eur : "en-IE",
    hkd : "zh-HK",
    huf : "hu-HU",
    ils : "iw-IL",
    jpy : "ja-JP",
    myr : "ms-MY",
    mxn : "es-MX",
    twd : "zh-TW",
    nzd : "en-NZ",
    nok : "no-NO",
    php : "en-PH",
    pln : "pl-PL",
    gbp : "en-GB",
    rub : "ru-RU",
    sgd : "en-SG",
    sek : "sv-SE",
    chf : "de-CH",
    thb : "th-TH",
}

export default class CurrencyStore{

    defaultCurrencyCode = "inr";
    defaultCurrency = {
        key: "default_inr",
        text: "INR",
        value: "default_inr",
        source: "inr",
        symbol: currencySymbolMap["inr"],
        format : this.formatCurrency.bind(this, "inr")
    };
    currencyOptionList = [];
    @observable currency = null;

    get Currency(){
        return (this.currency || this.defaultCurrency);
    }

    set Currency(currencyInfo){
        this.currency = currencyInfo;
    }

    get CurrencyOptions(){
        return this.currencyOptionList;
    }

    set DefaultCurrency(defCurrency){
        this.defaultCurrency = defCurrency;
    }

    formatCurrency(currencyCode, data, withDecimal = true){
        const _currencyCode = currencyCode || this.defaultCurrencyCode;
        const locale = currencyLocaleCodeMap[_currencyCode.toLowerCase()];
        if(withDecimal){
            return new Intl.NumberFormat(locale, {style: 'currency', currency: _currencyCode.toUpperCase()}).format(data);
        }else{
            return new Intl.NumberFormat(locale, {style: 'currency', currency: _currencyCode.toUpperCase()}).format(data).replace(/\D00(?=\D*$)/, '');
        }
    }

    resetCurrency(userInfo){
        this.currency = null;
        this.currencyOptionList = _map(userInfo.OPTIONS.Currency, (currencyOption) => {
            return {
                ...currencyOption,
                symbol: currencySymbolMap[currencyOption.source],
                content : (
                    <Header size="small" style={{fontWeight: "100"}}>
                        <Label style={{float: "right", fontFamily: "lato"}} basic color='blue'>{currencyOption.source.toUpperCase()}</Label>{currencyOption.text}
                    </Header>
                ),
                format : this.formatCurrency.bind(this, currencyOption.source)
            }
        });
        this.DefaultCurrency = _find(this.currencyOptionList, ["source", this.defaultCurrencyCode]);
    }

    @action
    registerCurrency(userInfo){
        if(userInfo && userInfo.DATA){
            this.resetCurrency(userInfo);
            if(userInfo.DATA.Currency){
                const currencyInfo = _find(this.CurrencyOptions, ["key", userInfo.DATA.Currency]);
                if(currencyInfo){
                    this.Currency = currencyInfo
                }
            }
        }
    }


}
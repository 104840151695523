import jsonPatch from '@libs/fast-json-patch';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import _difference from 'lodash/difference';
import _last from 'lodash/last';

import { action, computed, observable, runInAction, toJS } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';
import { ph_emptyImageURL_Square, ph_payment_indcatalog, ph_payment_mode_other, ph_payment_mode_upi, ph_payment_paytm } from '../../0-common/utils/DefaultImage';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import storeFactory from '../../0-common/utils/StoreFactory';

const defaultShopPage = {
	name: "Settings",
	uid: "Settings"
}

export default class ShopStore extends ItemStore {

	FACTORY_STORE_TYPE = "WEBSHOPS";

	constructor(subjectInfo){
		super(subjectInfo);
		if(typeof window !== "undefined") {
			let _baseURL = `${window.location.protocol}//${window.location.hostname}`;
			if(window.location.port){
				_baseURL = `${_baseURL}:${window.location.port}`;
			}
			this.nodeBaby = baby.create({
				baseURL: _baseURL,
				withCredentials: true
			});
		}else{
			this.nodeBaby = baby;
		}
	}

	sectionAddedToPage = false;
	@observable isPublishing = false;
	@observable isUnPublishing = false;
	@observable verifyingShopForPublishing = false;
	@observable activePageID = "";
	@observable confirmActivePageForDeletion = false;
	@observable shopOwnerInfo = {};
	@observable savingShopLinkSetting = false;
	@observable savingDeliverySetting = false;
	@observable currentShopForEdit = {};
	@observable productRemovalException = {
		COLLECTIONS_CONTAINING_PRODUCT : [],
		SECTIONS_CONTAINING_PRODUCT : [],
		PRODUCT_TO_BE_REMOVED : {}
	};
	@observable isBannerImageLoading = false;
	@observable isSectionLoading = false;
	@observable shopMobileNumberInvalid = false;
	@observable shopInfoStatus = {
		mobileNumber : {
			invalid : false,
			msg : ""
		},
		email : {
			invalid : false,
			msg : ""
		}
	}

	@observable themes = [];
	@observable shopURLStatus = {
		empty : false,
		alreadyExist : false,
		msg : ""
	}
	
	@computed
	get isShopURLInValid(){
		return (this.shopURLStatus.empty || this.shopURLStatus.alreadyExist)
	}

	@computed 
	get shopURLErrorMsg(){
		return this.shopURLStatus.msg;
	}

	@action.bound
	resetShopURLStatus(){
		this.shopURLStatus = {
			empty : false,
			alreadyExist : false,
			msg : ""
		}
	}

	@observable isLoadingDashboardDate = false;
	dashboardData = {
		reports: []
	};
	
	/********* Payment Settings **********/
	@observable isLoadingPaymentConfig = false;
	@observable isLoadingPaymentDetails = false;
	@observable isRemovingUserPaymentDetail = false;
	selectedPaymentGateway = {};
	selectedPaymentMode = {};
	@observable paymentSettingsConfig = {};
	@observable shopPaymentDetail = {
		"paymentGateway" : "",
		"paymentMode" : "",
		"accountInfo" : {},
		"accountInfoReadOnly" : {}
	};
	@observable paymentAccountFieldValidStatus = {}

	@computed
	get isLoadingPaymentInfo(){
		return (this.isLoadingPaymentConfig || this.isLoadingPaymentDetails);
	}

	isPaymentAccountFieldInValid(fieldID){
		const fieldStatusInfo = this.paymentAccountFieldValidStatus[fieldID];
		if(fieldStatusInfo){
			return (fieldStatusInfo.empty || fieldStatusInfo.invalid);
		} else {
			return false;
		}
	}

	paymentAccountFieldErrorMsg(fieldID){
		const fieldStatusInfo = this.paymentAccountFieldValidStatus[fieldID];
		if(fieldStatusInfo){
			return fieldStatusInfo.msg;
		} else {
			return "";
		}
	}

	@action
	resetPaymentAccountFieldStatus = (fieldID) => {
		this.paymentAccountFieldValidStatus[fieldID] = {
			empty : false,
			invalid : false,
			msg : ""
		}
	}

	/*paymentGatewayDetails = {
        "IndcatalogPaymentGateway" : {
            "label" : "Tridasha Payment Gateway",
            "description": "Trusted & conveninet way to pay",
            "image" : ph_payment_indcatalog,
            "isRecommended" : true
        },
        "PaytmPaymentGateway" : {
            "label" : "Paytm Payment Gateway",
            "description": "Safe & secure payments",
            "image" : ph_payment_paytm,
            "isRecommended" : false
        }
    };
    paymentModeDetails = {
        "upi_only" : {
            "label" 	: "UPI Only",
            "meta"		: "",
            "charges" 	: "Charges : 0.30% + GST",
            "image" 	: ph_payment_mode_upi
        },
        "other_payments" : {
            "label" 	: "UPI + Other Payments",
            "meta"		: "CC, DC, Netbanking, Wallet, UPI",
            "charges" 	: "Charges : 3.0% + GST",
            "image" 	: ph_payment_mode_other
        }
	};*/
	
	@computed
	get isShopMobileNumberInValid(){
		return (this.shopInfoStatus.mobileNumber.invalid);
	}

	@computed
	get shopMobileNumberErrorMsg(){
		return this.shopInfoStatus.mobileNumber.msg;
	}

	@computed
	get isShopEmailInValid(){
		return (this.shopInfoStatus.email.invalid);
	}

	@computed
	get shopEmailErrorMsg(){
		return this.shopInfoStatus.email.msg;
	}

	@action.bound
	resetShopInfoStatus(fieldName){
		if(fieldName){
			this.shopInfoStatus[fieldName] = {
				invalid : false,
				msg : ""
			}
		} else {
			this.shopInfoStatus = {
				mobileNumber : {
					invalid : false,
					msg : ""
				},
				email : {
					invalid : false,
					msg : ""
				}
			}
		}
	}

	@computed
	get DefaultShopPageID(){
		const shop = super.getCurrentItem();
		if(shop){
			const pageRef = _find(shop.REFERENCES, {"CategorySourceID" : defaultShopPage.name});
			if(pageRef)
				return pageRef.uid;
			else 
				return defaultShopPage.uid;
		}
	}

	@computed
	get ActivePage(){
		if(this.activePageID === ""){
			return this.DefaultShopPageID;
		} else {
			return this.activePageID;
		}
	}

	set ActivePage(pageID){
		this.activePageID = pageID;
	}

	@computed
	get Pages(){
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			return _map(shop.DATA.PagesInShop, (pageID) => {
				return shop.REFERENCES[pageID];
			});
		} else {
			return [];
		}
	}

	@computed
	get OwnerInfo(){
		if(this.shopOwnerInfo && this.shopOwnerInfo.DATA){
			let profileImage = this.shopOwnerInfo.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.shopOwnerInfo.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
			return {...this.shopOwnerInfo.DATA, "ProfileImage" : profileImage};
		} else {
			return null;
		}
	}

	set OwnerInfo(shopOwner){
		this.shopOwnerInfo = shopOwner;
	}

	@computed
	get ShopLogo(){
		let profileImage = ph_emptyImageURL_Square;
		if(this.getCurrentItem()){
			if(this.currentItem.DATA.ProfileImage && this.currentItem.DATA.ProfileImage !== ""){
				const profileImageRef = this.currentItem.REFERENCE_IMAGES[this.currentItem.DATA.ProfileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/auto/-/quality/smart/`;
				}
			}
		}
		return profileImage;
	}

	@computed
	get ShopName(){
		let shopName = "";
		if(this.getCurrentItem()){
			shopName = this.currentItem.DATA.Name;
		}

		return shopName;
	}

	@computed
	get ShopCategory(){
		const shop = this.getCurrentItem();
		if(shop){
			return shop.DATA.CategorySourceID;
		} else {
			return null;
		}
	}

	get ShopTheme(){
		return storeFactory.ShopThemeStore;
	}

	get ShopID(){
		if(this.getCurrentItem()){
			return this.getCurrentItem().DATA.uid;
		} else {
			return null;
		}
	}

	@computed
	get HeaderSection(){
		let headerSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'HeaderSection'){
					headerSection = sectionInfo;
					return false;
				}
			});
		}
		return headerSection;
	}

	@computed
	get FooterSection(){
		let footerSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'FooterSection'){
					footerSection = sectionInfo;
					return false;
				}
			});
		}
		return footerSection;
	}

	@computed
	get FooterTagline(){
		let footerTagline = null;
		if(this.getCurrentItem()){
			const footerSection = this.FooterSection;
			if(footerSection)
				footerTagline = footerSection.Description;
		}

		return footerTagline;
	}

    @computed
	get DeliveryCharge(){
		const shop = this.getCurrentItem();
		if(shop){ 
			const currency = this.StoreFactory.CurrencyStore.Currency;
			if(shop.DATA.DeliveryType=="StandardShipping")
				return currency.format(shop.DATA.DeliveryCharge);
			else
				return "Free"
		}
		else{
			return "Free";
		}  
	}

	get ShopAnalyticsInfo(){
		const shop = this.getCurrentItem();
		const shopAnalyticsInfo = {};
		if(shop){
			shopAnalyticsInfo.GoogleAnalyticsID = shop.DATA.GoogleAnalyticsID;
			shopAnalyticsInfo.GooglePropertyID = shop.DATA.GooglePropertyID;
		}
		return shopAnalyticsInfo;
	}

	/*
	* NOTE
	* this.patchObserver is used for both shopForEdit and this.currentItem.
	* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unsed yet for this.currentItem.
	* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
	*/
	get ShopForEdit(){
		return this.currentShopForEdit;
	}

	initalizeShopForEdit(){
		this.currentShopForEdit = toJS(this.currentItem);
		this.patchObserver = jsonPatch.observe(this.currentShopForEdit.DATA);
	}

	reset(){
		this.activePageID = "";
		this.selectedPaymentGateway = {};
		this.selectedPaymentMode = {};
		this.paymentSettingsConfig = {};
		this.shopPaymentDetail = {
			"paymentGateway" : "",
			"paymentMode" : "",
			"accountInfo" : {},
			"accountInfoReadOnly" : {}
		};
		this.paymentAccountFieldValidStatus = {};
	}

	async openItem() {
		try {
			this.isLoading = true;
			this.currentItem = {};
			const itemInfo = this.NavigationStore.activeItem;		
			const response = await super.getSubjectByType(itemInfo);
			runInAction("On Shop Open Item",() => {		
				this.currentItem = toJS(response.SUBJECT);
				this.patchObserver = jsonPatch.observe(this.currentItem.DATA);

				this.ShopTheme.ThemeID = response.SUBJECT.DATA.Theme;
				this.ShopTheme.Theme = response.THEME;

				response.onComplete();
			});
		}
		catch (error){
			runInAction("On Shop Open Item Error", () => {
				if(!this.ErrorStore.is401Error(error)){
					this.NavigationStore.resetActvieItem();
					this.NavigationStore.changeView(this.NavigationStore.VIEW.HOME);
				}
				this.isLoading = false;
				this.ErrorStore.log(error, "Couldn't perform Shop open", "On Shop Open Item Error");		
			});
		}
		finally {
			this.isLoading = false;
		}
  	}

	@action
	async savePageName(pageID, newName){
		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const pageRef = shop.REFERENCES[pageID];
				const oldName = pageRef.Name;
				if(oldName !== newName){
					const addNamePatch 	= {op : "add", path : "/Name", value : newName };
					const removeNamePatch 	= {op : "remove", path : "/Name" , value : oldName };
					patch.push(removeNamePatch);
					patch.push(addNamePatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : pageID,
					Type : "WEPAGES",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Page Name", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[pageID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform savePageName ", "On savePageName Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async savePageTitle(pageID, newTitle){
		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const pageRef = shop.REFERENCES[pageID];
				const oldTitle = pageRef.Title || "";
				if(oldTitle !== newTitle){
					const addNamePatch 	= {op : "add", path : "/Title", value : newTitle };
					const removeNamePatch 	= {op : "remove", path : "/Title" , value : oldTitle };
					patch.push(removeNamePatch);
					patch.push(addNamePatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : pageID,
					Type : "WEPAGES",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Page Name", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[pageID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform savePageTitle ", "On savePageTitle Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async savePageDescription(pageID, newDescription){
		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const pageRef = shop.REFERENCES[pageID];
				const oldDescription = pageRef.Description;
				if(oldDescription !== newDescription){
					const addDescriptionPatch 	= {op : "add", path : "/Description", value : newDescription };
					const removeDescriptionPatch 	= {op : "remove", path : "/Description" , value : oldDescription };
					patch.push(removeDescriptionPatch);
					patch.push(addDescriptionPatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : pageID,
					Type : "WEPAGES",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Page Description", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[pageID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform savePageDescription ", "On savePageDescription Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async saveSectionName(sectionID, newTitle){

		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const sectionRef = shop.REFERENCES[sectionID];
				const oldTitle = sectionRef.Name;
				if(oldTitle !== newTitle){
					const addNamePatch 	= {op : "add", path : "/Name", value : newTitle };
					const removeNamePatch 	= {op : "remove", path : "/Name" , value : oldTitle };
					patch.push(removeNamePatch);
					patch.push(addNamePatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : sectionID,
					Type : "WEBSECTIONS",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Section Name", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[sectionID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform saveSectionName ", "On saveSectionName Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async saveSectionDescription(sectionID, newRichTextDescription, newTextDescription){
		//console.log("Section ID : "+sectionID);
		//console.log("New Desc : "+newRichTextDescription);
		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const sectionRef = shop.REFERENCES[sectionID];
				const oldRichTextDescription = sectionRef.Description_RT;
				if(oldRichTextDescription !== newRichTextDescription){
					const addRichTextDescriptionPatch 	= {op : "add", path : "/Description_RT", value : newRichTextDescription };
					if(oldRichTextDescription){
						const removeRichTextDescriptionPatch 	= {op : "remove", path : "/Description_RT" , value : oldRichTextDescription };
						patch.push(removeRichTextDescriptionPatch);
					}
					patch.push(addRichTextDescriptionPatch);

					const addDescriptionPatch 	= {op : "add", path : "/Description", value : newTextDescription };
					const removeDescriptionPatch 	= {op : "remove", path : "/Description" , value : sectionRef.Description };

					patch.push(removeDescriptionPatch);
					patch.push(addDescriptionPatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : sectionID,
					Type : "WEBSECTIONS",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Section Description", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[sectionID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform saveSectionDescription ", "On saveSectionDescription Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async addProductToShop(productIDs){
		let shopUID = "";
		try{
			const shop = super.getCurrentItem();
			if(shop){
				shopUID = shop.DATA.uid;
				const params = {
					ProductsInShop : productIDs,
					ShopID : shopUID
				};
				const response = await this.addProductToShopService(params);
				runInAction("ON addProductToShop", () => {
					response.onComplete();
				});
			}
			
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform addProductToShop ", "On addProductToShop Error");
		}	
		finally {
			const eventContext = '#ProductsInShop#'+shopUID;
			emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+eventContext);
		}
	}

	@action
	async addCollectionToSection(sectionID, propertyName, propertyValues){
		try{
			const shop = super.getCurrentItem();
			if(shop){
				const params = {
					SectionID : sectionID,
					CollectionsInSection : propertyValues,
					ShopID : shop.DATA.uid
				};
				const response = await this.addCollectionToSectionService(params);
				runInAction("ON addCollectionToSection", () => {
					response.onComplete();
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform addCollectionToSection ", "On addCollectionToSection Error");
		}	
		finally {
			const eventContext = '#'+propertyName+'#'+sectionID;
			emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+eventContext);
		}
	}

	@action
	async removeProductFromShop(productIDs){
		let shopUID = "";
		try{
			const shop = super.getCurrentItem();
			if(shop){
				shopUID = shop.DATA.uid;
				const params = {
					ProductID : productIDs[0],
					ShopID : shopUID
				};
				const response = await this.removeProductFromShopService(params);
				runInAction("ON removeProductFromShop", () => {
					this.productRemovalException.COLLECTIONS_CONTAINING_PRODUCT = response.COLLECTIONS_CONTAINING_PRODUCT;
					this.productRemovalException.SECTIONS_CONTAINING_PRODUCT = response.SECTIONS_CONTAINING_PRODUCT;
					this.productRemovalException.PRODUCT_TO_BE_REMOVED = response.PRODUCT_TO_BE_REMOVED;
					response.onComplete();
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform removeProductFromShop ", "On removeProductFromShop Error");
		}	
		finally {
			const eventContext = '#ProductsInShop#'+shopUID;
			emitter.emit(EVENTS.PREDICATE.REMOVEITEM.DONE+eventContext);
		}
	}

	@action
	async removeCollectionFromSection(sectionID, propertyName, propertyValues){
		try{
			const shop = super.getCurrentItem();
			if(shop){
				const params = {
					SectionID : sectionID,
					CollectionID : propertyValues[0],
					ShopID : shop.DATA.uid
				};
				await this.removeCollectionFromSectionService(params);
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform removeCollectionFromSection ", "On removeCollectionFromSection Error");
		}	
		finally {
			const eventContext = '#'+propertyName+'#'+sectionID;
			emitter.emit(EVENTS.PREDICATE.REMOVEITEM.DONE+eventContext);
		}
	}

	async verifyShopBeforePublishing(){
		try{
			this.verifyingShopForPublishing = true;
			const shop = super.getCurrentItem();
			if(shop){
				const shopID = shop.DATA.uid;
				await this.verifyShopBeforePublishingService(shopID);
				return true;
			}
		}
		catch (error){
			if(error.response && error.response.data && error.response.data.EXCEPTION){
				const exceptionType = error.response.data.EXCEPTION.ClassName;
				switch(exceptionType){
					case "InvalidPaymentInfoException" :
						this.ErrorStore.notify(error, "Couldn't perform publish shop ", "On publishShop Error > InvalidPaymentInfoException");
						break;

					case "ShopURLNotVerifiedException" :
						this.ErrorStore.notify(error, "Couldn't perform publish shop ", "On publishShop Error > ShopURLNotVerifiedException");
						break;
					
					case "NoProductsInShopException" :
						this.ErrorStore.notify(error, "Couldn't perform publish shop ", "On publishShop Error > NoProductsInShopException");
						break;
					
					case "LinkedAccountIdNotSetException" :
						this.ErrorStore.notify(error, "Couldn't perform publish shop ", "On publishShop Error > LinkedAccountIdNotSetException");
						break;
					
					case "ShopEmailCannotBeBlankException" :
						this.ErrorStore.notify(error, "Couldn't perform publish shop ", "On publishShop Error > ShopEmailCannotBeBlankException");
						break;

					default :
						this.ErrorStore.log(error, "Couldn't perform publish shop ", "On publishShop Error");
				}
			} else {
				this.ErrorStore.log(error, "Couldn't perform publish shop ", "On publishShop Error");
			}
			return false;
		}	
		finally {
			this.verifyingShopForPublishing = false;
		}
	}

	async publishShop(){
		try{
			this.isPublishing = true;
			const shop = super.getCurrentItem();
			if(shop){
				const shopID = shop.DATA.uid;
				const response = await this.publishShopService(shopID);
				this.saveItem_Success(response);
				this.initalizeShopForEdit();
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform publish shop ", "On publishShop Error");
		}	
		finally {
			this.isPublishing = false;
		}
	}

	async unPublishShop(){
		try{
			this.isUnPublishing = true;
			const shop = super.getCurrentItem();
			if(shop){
				const shopID = shop.DATA.uid;
				const response = await this.unPublishShopService(shopID);
				this.saveItem_Success(response);
				this.initalizeShopForEdit();
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform unPublish shop ", "On unPublishShop Error");
		}	
		finally {
			this.isUnPublishing = false;
		}
	}

	@action
	async saveShopLinkSettings(shopLinkType, shopLinkURL){
		try{
			this.savingShopLinkSetting = true;
			const shop = super.getCurrentItem();
			if(shop){

				if(shopLinkURL === ""){
					this.shopURLStatus.empty = true;
					this.shopURLStatus.msg = "Shop link cannot be empty.";
					return false;
				}
				const saveShopLinkSettingsParams = {
					"uid" : shop.DATA.uid,
					"URLType" : shopLinkType,
					"URL" : shopLinkURL
				}
				const response = await this.saveShopLinkSettingsService(saveShopLinkSettingsParams);
				this.saveItem_Success(response);
				this.initalizeShopForEdit();
			}
			return true;
		}
		catch (error){
			if(error.response && error.response.data && error.response.data.EXCEPTION){
				const exceptionType = error.response.data.EXCEPTION.ClassName;
				if(exceptionType === "DuplicateShopURLException"){
					runInAction("DuplicateShopURLException", () => {
						this.shopURLStatus.alreadyExist = true;
						this.shopURLStatus.msg = "Shop link with above name already exist.";
						return false;
					});
				} else {
					return true;
				}
			} else {
				this.ErrorStore.log(error, "Couldn't perform saveShopLinkSettings ", "On saveShopLinkSettings Error");
				return true;
			}
			
		}	
		finally {
			this.savingShopLinkSetting = false;
		}
	}
	
	@action 
	async saveDeliverySettings(deliveryType,deliveryCharge){
		try{
			this.savingDeliverySetting = true;
			const shop = this.ShopForEdit;
			if(shop){
				shop.DATA.DeliveryType = deliveryType;
				shop.DATA.DeliveryCharge = deliveryCharge;
				this.saveSettingsChanges();
			}
			return true;
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform saveDeliverySettings ", "On saveDeliverySettings Error");
			return true;
		}
		finally{
			this.savingDeliverySetting = false;
		}
	}

	//@OVERRIDE
	/*
	* NOTE
	* this.patchObserver is used for both shopForEdit and this.currentItem.
	* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unused yet for this.currentItem.
	* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
	* 
	* INCASE of shop Logo, the response from adding profile image is used to call 'this.saveItem_Success(response);' which points the
	* 'this.patchObserver' to this.currentItem, HENCE had to override and call initalizeShopForEdit();
	*/
	async addProfileImage(imageInfo){
		try{
			await super.addProfileImage(imageInfo);
			this.initalizeShopForEdit();
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't add profile image for SHOP ", "On SHOP > Add Profile Image Error");
		}
		finally{
			this.isProfileImageLoading = false;
		}
	}

	/*
	* NOTE
	* this.patchObserver is used for both shopForEdit and this.currentItem.
	* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unused yet for this.currentItem.
	* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
	*/
	async saveSettingsChanges(){
		try{
			this.isLoading = true;
			const patch = jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
				const itemInfo = {"Type":"WEBSHOPS", "uid":this.currentShopForEdit.DATA.uid};	
				const response = await this.saveSubject(itemInfo, patch);		
				runInAction("ON Save Shop Settings", () => {
					this.isLoading = true;
					this.saveItem_Success(response);
					this.initalizeShopForEdit();
				});
				return true;
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't save shop settings changes", "On Save Shop Setting Error");
			return false;
		}
		finally {
			this.isLoading = false;	
		}
	}

	cancelSettingChanges(){
		try{
			this.initalizeShopForEdit();
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't cancel Shop Setting changes", "ON Cancel Shop Setting Changes");
		}
	}

	async addBannerImageForSection(sectionInfo, imageInfo){
		console.log(" >addBannerImageForSection")
		try{
			this.isBannerImageLoading = true;
			const response = await super.addProfileImage_Subject(sectionInfo, imageInfo);
			runInAction("ON addBannerImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
				const imageID = response.SUBJECT.DATA.ProfileImage;
				const imageRef = response.SUBJECT.REFERENCE_IMAGES[imageID];
				this.currentItem.REFERENCE_IMAGES[imageID] = imageRef;
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't add banner image for section", "On Add Banner Image For Section Error");
		}
		finally{
			this.isBannerImageLoading = false;
		}
	}

	async removeBannerImageForSection(sectionInfo){
		console.log(" >removeBannerImageForSection")
		try{
			this.isBannerImageLoading = true;
			const response = await super.removeProfileImage_Subject(sectionInfo);
			runInAction("ON removeBannerImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't remove banner image for section", "On remove Banner Image For Section Error");
		}
		finally{
			this.isBannerImageLoading = false;
		}
	}

	async saveBannerInfo(sectionId, newTitle, oldTitle, newRichTextBannerDescription, oldRichTextBannerDescription, newBannerDescription, oldBannerDescription){
		try{
			let patch = [];
			if(newTitle !== oldTitle){
				patch.push({op : "add", path : "/Name", value : newTitle});
				patch.push({op : "remove", path : "/Name", value : oldTitle});
			}
			if(newBannerDescription !== oldBannerDescription){
				patch.push({op : "add", path : "/Description", value : newBannerDescription});
				patch.push({op : "remove", path : "/Description", value : oldBannerDescription});
			}
			if(newRichTextBannerDescription !== oldRichTextBannerDescription){
				patch.push({op : "add", path : "/Description_RT", value : newRichTextBannerDescription});
				if(oldRichTextBannerDescription)
					patch.push({op : "remove", path : "/Description_RT", value : oldRichTextBannerDescription});
			}
			if(patch.length > 0){
				this.isLoading = true;
				const itemInfo = {"Type":"WEBSECTIONS", "uid":sectionId};	
				const response = await this.saveSubject(itemInfo, patch);		
				runInAction("ON Save Banner Info", () => {
					this.isLoading = true;
					this.currentItem.REFERENCES[sectionId] = response.SUBJECT.DATA;
					this.initalizeShopForEdit();
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't save Banner info changes", "On Save Banner Info Error");
		}
		finally {
			this.isLoading = false;	
		}
	}

	/**** Content Section ****/

	async addContentImageForSection(sectionInfo, imageInfo){
		console.log(" >addContentImageForSection")
		try{
			this.isSectionLoading = true;
			const sectionImageInfoParam = {...sectionInfo, "IMAGES" : [imageInfo]};
			const response = await this.addContentImageForSectionService(sectionImageInfoParam);
			runInAction("ON addContentImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
				const imageIDList = response.SUBJECT.DATA.ContentImage;
				_forEach(imageIDList, (imageID) => {
					const imageRef = response.SUBJECT.REFERENCE_IMAGES[imageID];
					this.currentItem.REFERENCE_IMAGES[imageID] = imageRef;
				});
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't add content image for section", "On addContentImageForSection Error");
		}
		finally{
			this.isSectionLoading = false;
		}
	}

	async removeContentImageForSection(sectionInfo, imageID){
		console.log(" >removeContentImageForSection")
		try{
			this.isSectionLoading = true;
			const sectionImageInfoParam = {...sectionInfo, "ImageID" : imageID};
			const response = await this.removeContentImageForSectionService(sectionImageInfoParam);
			runInAction("ON removeContentImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
				delete this.currentItem.REFERENCES[imageID];
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't remove content image for section", "On removeContentImageForSection Error");
		}
		finally{
			this.isSectionLoading = false;
		}
	}

	async addBackgroundImageForSection(sectionInfo, imageInfo){
		console.log(" >addBackgroundImageForSection")
		try{
			this.isSectionLoading = true;
			const response = await super.addProfileImage_Subject(sectionInfo, imageInfo);
			runInAction("ON addBackgroundImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
				const imageID = response.SUBJECT.DATA.ProfileImage;
				const imageRef = response.SUBJECT.REFERENCE_IMAGES[imageID];
				this.currentItem.REFERENCE_IMAGES[imageID] = imageRef;
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't add background image for section", "On addBackgroundImageForSection Error");
		}
		finally{
			this.isSectionLoading = false;
		}
	}

	async removeBackgroundImageForSection(sectionInfo){
		console.log(" >removeBackgroundImageForSection")
		try{
			this.isSectionLoading = true;
			const response = await super.removeProfileImage_Subject(sectionInfo);
			runInAction("ON removeBackgroundImageForSection Response", () => {
				this.currentItem.REFERENCES[sectionInfo.uid] = response.SUBJECT.DATA;
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't remove background image for section", "On removeBackgroundImageForSection Error");
		}
		finally{
			this.isSectionLoading = false;
		}
	}

	async saveContentDescriptionForSection(sectionInfo, newRichTextContentDescription, newContentDescription){
		try{
			this.isSectionLoading = true;
			const patch = [
				{op : "add", path : "/Description", value : newContentDescription },
				{op : "remove", path : "/Description", value : sectionInfo.DATA.Description },

				{op : "add", path : "/Description_RT", value : newRichTextContentDescription },
				{op : "remove", path : "/Description_RT", value : sectionInfo.DATA.Description_RT || "" }
			]
			const itemInfo = {"Type":"WEBSECTIONS", "uid":sectionInfo.DATA.uid};	
			const response = await this.saveSubject(itemInfo, patch);		
			runInAction("ON Save Content Description For Section", () => {
				this.isSectionLoading = true;
				this.currentItem.REFERENCES[sectionInfo.DATA.uid] = response.SUBJECT.DATA;
				this.initalizeShopForEdit();
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't Save Content Description For Section", "On saveContentDescriptionForSection Error");
		}
		finally {
			this.isSectionLoading = false;	
		}
	}

	async toggleContentBlendMode(sectionInfo, blendMode){
		try{
			this.isSectionLoading = true;
			const oldBlendModeValue = sectionInfo.DATA.ContentBlendMode ? sectionInfo.DATA.ContentBlendMode[0] : null;
			const patch = [{op : "add", path : "/ContentBlendMode", value : blendMode }];
			if(oldBlendModeValue){
				patch.push({op : "remove", path : "/ContentBlendMode", value :  oldBlendModeValue});
			}
			const itemInfo = {"Type":"WEBSECTIONS", "uid":sectionInfo.DATA.uid};	
			const response = await this.saveSubject(itemInfo, patch);		
			runInAction("ON Toggle Content Blend Mode For Section", () => {
				this.isSectionLoading = true;
				this.currentItem.REFERENCES[sectionInfo.DATA.uid] = response.SUBJECT.DATA;
				this.initalizeShopForEdit();
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't toggle Content Blend Mode For Section", "On toggleContentBlendMode Error");
		}
		finally {
			this.isSectionLoading = false;	
		}
	}

	async toggleContentFlowDirection(sectionInfo, flowDirection){
		try{
			this.isSectionLoading = true;
			const oldFlowValue = sectionInfo.DATA.ContentFlowDirection ? sectionInfo.DATA.ContentFlowDirection[0] : null;
			const patch = [{op : "add", path : "/ContentFlowDirection", value : flowDirection }];				
			if(oldFlowValue){
				patch.push({op : "remove", path : "/ContentFlowDirection", value : oldFlowValue});
			}
			const itemInfo = {"Type":"WEBSECTIONS", "uid":sectionInfo.DATA.uid};	
			const response = await this.saveSubject(itemInfo, patch);		
			runInAction("ON Toggle Content Flow Direction For Section", () => {
				this.isSectionLoading = true;
				this.currentItem.REFERENCES[sectionInfo.DATA.uid] = response.SUBJECT.DATA;
				this.initalizeShopForEdit();
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't toggle Content Flow Direction For Section", "On toggleContentFlowDirection Error");
		}
		finally {
			this.isSectionLoading = false;	
		}
	}

	async toggleContentAlign(sectionInfo, align){
		try{
			this.isSectionLoading = true;
			const oldAlignValue = sectionInfo.DATA.ContentAlign ? sectionInfo.DATA.ContentAlign[0] : null;
			const patch = [{op : "add", path : "/ContentAlign", value : align }];
			if(oldAlignValue){
				patch.push({op : "remove", path : "/ContentAlign", value :  oldAlignValue});
			}
			const itemInfo = {"Type":"WEBSECTIONS", "uid":sectionInfo.DATA.uid};	
			const response = await this.saveSubject(itemInfo, patch);		
			runInAction("ON Toggle Content Align For Section", () => {
				this.isSectionLoading = true;
				this.currentItem.REFERENCES[sectionInfo.DATA.uid] = response.SUBJECT.DATA;
				this.initalizeShopForEdit();
			});
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't toggle Content Align For Section", "On toggleContentAlign Error");
		}
		finally {
			this.isSectionLoading = false;	
		}
	}


	/*********** Payment Settings *****************/

	async addShopPaymentDetail(){
		try{
			this.isLoading = true;
			const accountInfo = this.shopPaymentDetail.accountInfo;
			const shop = super.getCurrentItem();

			const paymentDataPatch = _map(accountInfo, (value, key) => {
				return {
					"op":"add", 
					"path":`/${key}`, 
					"value": value
				}
			});	
			paymentDataPatch.push({
				"op":"add", 
				"path":"/AllowedPaymentMode", 
				//"value": selectedPaymentMode.value
				"value": this.selectedPaymentMode.value
			});
			const paymentInfo = {
				"Type":"PAYMENTSETTINGS", 
				"Category" : this.selectedPaymentGateway.value, 
				"PATCH" : paymentDataPatch,
				"ShopID" : shop.DATA.uid
			};
			const response = await this.addShopPaymentDetailService(paymentInfo);
			runInAction("On Add Shop Payment Detail",() => {		
				this.isLoading = true;
				this.saveItem_Success(response);
				/*
				* NOTE
				* this.patchObserver is used for both shopForEdit and this.currentItem.
				* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unused yet for this.currentItem.
				* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
				* In the above case, response is used to call 'this.saveItem_Success(response);' which points the
				* 'this.patchObserver' to this.currentItem, HENCE had to override and call initalizeShopForEdit();
				*/
				this.initalizeShopForEdit();
			});
			return true;
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't Add Shop Payment Detail", "ON addShopPaymentDetail");
		}
		finally {
			this.isLoading = false;	
		}
	}

	async updateShopPaymentDetail(){
		try{
			this.isLoading = true;
			let paymentDataPatch = [];
			let categoryDataPatch = [];
			const shop = super.getCurrentItem();
			const accountInfo = this.shopPaymentDetail.accountInfo;
			const originalAccountInfo = this.shopPaymentDetail.accountInfoReadOnly;

			//Get Account Detail fields specific to selected Payment Gateway to build patch.
			const paymentGatewayTypeInfo = this.paymentSettingsConfig.REFERENCES[this.selectedPaymentGateway.key];
			const accountDetailRegion = paymentGatewayTypeInfo.REGIONS.AccountDetail;

			//Build account detail data patch for change in value.
			_forEach(accountDetailRegion, (accountDetailField) => {
				const predicateId = accountDetailField.uid;
				const newPredicateValue = accountInfo[predicateId];
				const oldPredicateValue = originalAccountInfo[predicateId];
				if(oldPredicateValue){
					if( oldPredicateValue !== newPredicateValue){
						paymentDataPatch.push({"op":"remove", "path":`/${predicateId}`, "value": oldPredicateValue});
						paymentDataPatch.push({"op":"add", "path":`/${predicateId}`, "value": newPredicateValue});
					}
				} else if(newPredicateValue){
					paymentDataPatch.push( {"op":"add", "path":`/${predicateId}`, "value": newPredicateValue});
				}
			});

			//Build AllowedPaymentMode data patch for change in value.
			/*if(selectedPaymentMode && selectedPaymentMode.value !== this.shopPaymentDetail.paymentMode){
				paymentDataPatch.push({"op":"remove", "path":"/AllowedPaymentMode", "value": this.shopPaymentDetail.paymentMode});
				paymentDataPatch.push({"op":"add", "path":"/AllowedPaymentMode", "value": selectedPaymentMode.value});
			}*/

			const paymentInfo = {	
				"Type":"PAYMENTSETTINGS", 
				"Category" : this.selectedPaymentGateway.value, 
				"uid": this.selectedPaymentGateway.paymentSettingID, 
				"PATCH" : paymentDataPatch,
				"CATEGORYPATCH" : categoryDataPatch,
				"ShopID" : shop.DATA.uid
			};
			
			const response = await this.updateShopPaymentDetailService(paymentInfo);
			runInAction("On Update Shop Payment Detail",() => {		
				this.isLoading = true;
				this.saveItem_Success(response);
				/*
				* NOTE
				* this.patchObserver is used for both shopForEdit and this.currentItem.
				* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unused yet for this.currentItem.
				* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
				* In the above case, response is used to call 'this.saveItem_Success(response);' which points the
				* 'this.patchObserver' to this.currentItem, HENCE had to override and call initalizeShopForEdit();
				*/
				this.initalizeShopForEdit();
			});
			return true;
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't Update Shop Payment Detail", "ON updateShopPaymentDetail");
		}
		finally {
			this.isLoading = false;
		}
	}

	validatePaymentAccountForm(){
		const accountInfo = this.shopPaymentDetail.accountInfo;
		//Get Account Detail fields specific to selected Payment Gateway to build patch.
		const paymentGatewayTypeInfo = this.paymentSettingsConfig.REFERENCES[this.selectedPaymentGateway.key];
		const accountDetailRegion = paymentGatewayTypeInfo.REGIONS.AccountDetail;

		let isPaymentAccountFormValid = true;
		_forEach(accountDetailRegion, (accountDetailField) => {
			const predicateId = accountDetailField.uid;
			const newPredicateValue = accountInfo[predicateId];
			if(_isEmpty(newPredicateValue)){
				this.paymentAccountFieldValidStatus[predicateId] = {
					empty : true,
					msg : `Please enter ${accountDetailField.Name.toLowerCase()}.`
				}
				isPaymentAccountFormValid = false;
			} 
		});
		return isPaymentAccountFormValid;
	}


	async verifyPaymentAccountCredential(){
		let isValid = false;
		try{
			this.isLoadingPaymentDetails = true;
			const accountInfo = this.shopPaymentDetail.accountInfo;
			const paymentGatewayTypeInfo = this.paymentSettingsConfig.REFERENCES[this.selectedPaymentGateway.key];
			const accountDetailRegion = paymentGatewayTypeInfo.REGIONS.AccountDetail;

			let paymentAccountCredentialData = {
				"PaymentProvider" : this.selectedPaymentGateway.key
			};
			_forEach(accountDetailRegion, (accountDetailField) => {
				const predicateId = accountDetailField.uid;
				const newPredicateValue = accountInfo[predicateId];
				paymentAccountCredentialData[predicateId] = newPredicateValue;
			});
			isValid = await this.verifyPaymentAccountCredentialService(paymentAccountCredentialData);
			if(!isValid){
				this.paymentAccountFieldValidStatus[accountDetailRegion[0].uid] = {
					invalid : true,
					msg : "Your Merchant Id or Merchant Secret is incorrect"
				}
				this.paymentAccountFieldValidStatus[accountDetailRegion[1].uid] = {
					invalid : true,
					msg : ""
				}
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't verify Payment Account Credential", "ON verifyPaymentAccountCredential");
			this.paymentAccountFieldValidStatus[accountDetailRegion[0].uid] = {
				invalid : true,
				msg : "Error validating Merchant Id & Merchant Secret, Please try after some time."
			}
			this.paymentAccountFieldValidStatus[accountDetailRegion[1].uid] = {
				invalid : true,
				msg : ""
			}
		}
		finally {
			this.isLoadingPaymentDetails = false;
			return isValid;
		}
	}

	async removeShopPaymentDetail(paymentSettingsId, paymentSettingsCategory){
		try{
			this.isRemovingUserPaymentDetail = true;
			const shop = super.getCurrentItem();
			const paymentInfo = {
				"Type":"PAYMENTSETTINGS", 
				"uid" : paymentSettingsId, 
				"Category" : paymentSettingsCategory,
				"ShopID" : shop.DATA.uid
			};
			const response = await this.removeShopPaymentDetailService(paymentInfo);
			runInAction("On Remove Shop Payment Detail",() => {
				this.saveItem_Success(response);
				this.shopPaymentDetail = {
					"paymentGateway" : "",
					"paymentMode" : "",
					"accountInfo" : {},
					"accountInfoReadOnly" : {}
				};
				/*
				* NOTE
				* this.patchObserver is used for both shopForEdit and this.currentItem.
				* right now this.currentItem is only used to show data and NOT receive any modifications, hence this.patchObserver is unused yet for this.currentItem.
				* all the modification in SHOP is either done on shopForEdit or exclusive patch is generated without this.patchObserver;
				* In the above case, response is used to call 'this.saveItem_Success(response);' which points the
				* 'this.patchObserver' to this.currentItem, HENCE had to override and call initalizeShopForEdit();
				*/
				this.initalizeShopForEdit();
			});
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't remove Shop Payment Detail", "ON removeShopPaymentDetail");
		}
		finally {
			this.isRemovingUserPaymentDetail = false;	
		}
	}

	async getShopPaymentDetail(paymentSettingID, paymentSettingCategory){
		try{
			this.isLoadingPaymentDetails = true;
			const shop = super.getCurrentItem();
			if(paymentSettingID && paymentSettingCategory){
				const paymentInfo = {
					"Type":"PAYMENTSETTINGS", 
					"Category" : paymentSettingCategory, 
					"uid" : paymentSettingID,
					"ShopID" : shop.DATA.uid
				};
				const response = await this.getShopPaymentDetailService(paymentInfo);
				runInAction("ON Get Shop Payment Details", () => {
					this.shopPaymentDetail = {
						"paymentGateway" : response.DATA.Category,
						"paymentMode" : response.DATA.AllowedPaymentMode[0],
						"accountInfo" : {
							"LinkedAccountContactNumber" : response.DATA.LinkedAccountContactNumber,
							"LinkedAccountNumber" : response.DATA.LinkedAccountNumber,
							"LinkedAccountType" : response.DATA.LinkedAccountType,
							"LinkedAccountIFSCCode" : response.DATA.LinkedAccountIFSCCode,
							"LinkedAccountBeneficiaryName" : response.DATA.LinkedAccountBeneficiaryName,
							"LinkedAccountId" : response.DATA.LinkedAccountId,
							"MerchantID" : response.DATA.MerchantID,
							"MerchantKey" : response.DATA.MerchantKey
						},
						"accountInfoReadOnly" : {
							"LinkedAccountContactNumber" : response.DATA.LinkedAccountContactNumber,
							"LinkedAccountNumber" : response.DATA.LinkedAccountNumber,
							"LinkedAccountType" : response.DATA.LinkedAccountType,
							"LinkedAccountIFSCCode" : response.DATA.LinkedAccountIFSCCode,
							"LinkedAccountBeneficiaryName" : response.DATA.LinkedAccountBeneficiaryName,
							"LinkedAccountId" : response.DATA.LinkedAccountId,
							"MerchantID" : response.DATA.MerchantID,
							"MerchantKey" : response.DATA.MerchantKey
						}
					};
				});
				
			}
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't Get Shop Payment Detail", "ON getShopPaymentDetail");
		}
		finally {
			this.isLoadingPaymentDetails = false;	
		}
	}

	async getPaymentSettingsConfig(){
		try{
			this.isLoadingPaymentConfig = true;
			const response = await this.getPaymentSettingsConfigService();
			runInAction("ON Get PaymentSettings Config", () => {
				this.paymentSettingsConfig = response;
				/*//Setting the Default Payment Gateway to 'IndcatalogPaymentGateway' .
				if(this.paymentSettingsConfig.PAYMENT_GATEWAY){
					const IndcatalogPaymentGateway = _find(this.paymentSettingsConfig.PAYMENT_GATEWAY, ['key', 'IndcatalogPaymentGateway']);
					this.selectedPaymentGateway = IndcatalogPaymentGateway;
				}*/
				//Setting the Default Payment Mode to 'other_payments' .
				if(this.paymentSettingsConfig.PAYMENT_MODE){
					const other_Payment_Mode = _find(this.paymentSettingsConfig.PAYMENT_MODE, ['key', 'other_payments']);
					this.selectedPaymentMode = other_Payment_Mode;
				}
		  	});
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't get Payment Settings Config", "ON getPaymentSettingsConfig");
		}
		finally {
			this.isLoadingPaymentConfig = false;	
		}
	}

	async getThemes(){
		try{
			this.isLoading = true;
			const response = await this.getThemesService();
			runInAction("ON Get Themes", () => {
				this.themes = response.THEMES;
		  	});
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't get themes", "ON getThemes");
		}
		finally {
			this.isLoading = false;	
		}
	}

	async fetchTheme(themeID){
		try{
			this.isLoading = true;
			await storeFactory.ThemeFactory.loadTheme(themeID);
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't get themes", "ON getThemes");
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action
	async changeSectionLayoutTheme(sectionID, newSectionThemeID){

		let patch = [];
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const sectionRef = shop.REFERENCES[sectionID];
				const oldSectionThemeID = sectionRef.ThemeSectionID;
				if(oldSectionThemeID !== newSectionThemeID){
					const addThemeSectionIDPatch 	= {op : "add", path : "/ThemeSectionID", value : newSectionThemeID };
					const removeThemeSectionIDPatch 	= {op : "remove", path : "/ThemeSectionID" , value : oldSectionThemeID };
					patch.push(removeThemeSectionIDPatch);
					patch.push(addThemeSectionIDPatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : sectionID,
					Type : "WEBSECTIONS",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Change Section Theme Layout", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[sectionID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform changeSectionLayoutTheme ", "On changeSectionLayoutTheme Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async addSectionToPage(sectionID, pageID){
		let shopUID = "";
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				shopUID = shop.DATA.uid;
				const params = {
					SectionsInPage : [sectionID],
					PageID : pageID,
					ShopID : shopUID
				};
				const response = await this.addSectionToPageService(params);
				runInAction("ON addSectionToPage", () => {
					this.isLoading = true;
					this.saveItem_Success(response);
					this.initalizeShopForEdit();
					this.sectionAddedToPage = true;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform addSectionToPage ", "On addSectionToPage Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async deleteSectionFromPage(sectionID, pageID){
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			if(shop){
				const shopUID = shop.DATA.uid;
				const params = {
					SectionID : sectionID,
					PageID : pageID,
					ShopID : shopUID
				};
				const response = await this.deleteSectionFromPageService(params);
				runInAction("ON deleteSectionFromPage", () => {
					this.isLoading = true;
					this.saveItem_Success(response);
					this.initalizeShopForEdit();
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform deleteSectionFromPage ", "On deleteSectionFromPage Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async addPage(){
		try{
			this.isLoading = true;
			const shop = super.getCurrentItem();
			let newAddedPageID = null;
			if(shop){
				const shopUID = shop.DATA.uid;
				const currentThemeID = this.ShopTheme.ThemeID;
				const newPageThemeID = `${_capitalize(currentThemeID)}-AddNewPage`;
				const params = {
					PagesInShop : [newPageThemeID],
					ShopID : shopUID
				};
				const response = await this.addPagesToShopService(params);
				if(response.SUBJECT){
					const pageIDs = shop.DATA.PagesInShop;
					const newPageIDs = response.SUBJECT.DATA.PagesInShop;
					const newAddedPageList = _difference(newPageIDs, pageIDs);
					if(newAddedPageList && newAddedPageList.length > 0)
						newAddedPageID = newAddedPageList[0];
				}
				runInAction("ON addPage", () => {
					this.isLoading = true;
					this.saveItem_Success(response);
					this.initalizeShopForEdit();
					if(newAddedPageID)
						this.activePageID = newAddedPageID;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform addPage ", "On addPage Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async deletePage(pageID){
		try{
			this.isLoading = true;
			this.confirmActivePageForDeletion = false;
			const shop = super.getCurrentItem();
			let nextActivePageID = null;
			if(shop){
				const shopUID = shop.DATA.uid;
				const params = {
					PageID : pageID,
					ShopID : shopUID
				};
				const response = await this.deletePageService(params);
				if(response.SUBJECT)
					nextActivePageID = _last(response.SUBJECT.DATA.PagesInShop);
				runInAction("ON deletePage", () => {
					this.isLoading = true;
					this.saveItem_Success(response);
					this.initalizeShopForEdit();
					if(nextActivePageID)
						this.activePageID = nextActivePageID;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform deletePage ", "On deletePage Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	@action
	async saveFooterTagline(newFooterDescription){

		let patch = [];
		try{
			this.isLoading = true;
			const footerSection = this.FooterSection;
			const sectionID = footerSection.uid;
			const shop = super.getCurrentItem();
			if(shop){
				const sectionRef = shop.REFERENCES[sectionID];
				const oldDescription = sectionRef.Description;
				if(oldDescription !== newFooterDescription){
					const addNamePatch 	= {op : "add", path : "/Description", value : newFooterDescription };
					const removeNamePatch 	= {op : "remove", path : "/Description" , value : oldDescription };
					patch.push(removeNamePatch);
					patch.push(addNamePatch);
				}
			}
			if(patch.length > 0){
				const itemInfo = {
					uid : sectionID,
					Type : "WEBSECTIONS",
					Category : ""
				};
				const response = await super.saveSubject(itemInfo, patch);
				runInAction("ON Save Footer Tagline", () => {
					const savedSection = response.SUBJECT;
					shop.REFERENCES[sectionID] = savedSection.DATA;
				});
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform saveFooterTagline ", "On saveFooterTagline Error");
		}	
		finally {
			this.isLoading = false;
		}
	}

	async getDashboardDate(_startDate, _endDate, _dateDimension, _propertyID){
		try{
			this.isLoadingDashboardDate = true;
			/*
			const requestParam = `propertyID=${_propertyID}&startDate=${_startDate}&endDate=${_endDate}&dateDimension=${_dateDimension}`;
			const serviceResponse = await baby({ url: `/dashboard?${requestParam}`, baseURL: 'http://localhost:8081'});
			*/
			const dashboardRequestParam = {
				propertyID: _propertyID, 
				startDate: _startDate, 
				endDate: _endDate, 
				dateDimension: _dateDimension
			};
			const serviceResponse = await this.nodeBaby.get(`/dashboard`, {params: dashboardRequestParam});
			if(serviceResponse.data.reports){
				this.dashboardData = serviceResponse.data;
				return true;
			}else{
				return false;
			}
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform saveFooterTagline ", "On saveFooterTagline Error");
		}	
		finally {
			this.isLoadingDashboardDate = false;
		}
	}


	/* ==========================================  Service Layer  ==========================================  */

	//@override
	async createItem(itemCreateInfo){
		try{
			this.isLoading = true;
			const createData = {...itemCreateInfo}
			const serviceResponse = await baby.post('/createwebshop', createData);
			const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
			response.onComplete();
			return response.SUBJECT;
		}
		catch (error){
			this.isLoading = false;
			//this.ErrorStore.log(error, "Couldn't create web shop", "On Create Web Shop Error");
			throw error;
		}
		finally {
		  this.isLoading = false; 
		}
	}

	//@override
	async getSubjectService(itemInfo) {
		try {
			const response = await baby.post('/readshop', {"Type":itemInfo.Type, "uid":itemInfo.uid, "Category" : itemInfo.Category});
			return response.data;
		} 
		catch (error) {
			console.error("Shop getSubjectService Failed with Error : "+error);
			throw error;
		}				
	}

	async verifyShopBeforePublishingService(shopID){
		try{
			const publishParams = {
			  "uid" : shopID,
			  "Type" : "WEBSHOPS"
			}
			const serviceResponse = await baby.post('/verifyshopbeforepublishing', publishParams);
			return serviceResponse;
		}
		catch (error){
			throw error;
		}
	}

	async publishShopService(shopID){
		try{
			const publishParams = {
			  "uid" : shopID,
			  "Type" : "WEBSHOPS"
			}
			const serviceResponse = await baby.post('/publish', publishParams);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			throw error;
		}
	}

	async unPublishShopService(shopID){
		try{
			const unPublishParams = {
			  "uid" : shopID,
			  "Type" : "WEBSHOPS"
			}
			const serviceResponse = await baby.post('/unpublish', unPublishParams);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't publish web shop", "On UnPublish Web Shop Error");
			throw error;
		}
	}

	async saveShopLinkSettingsService(saveShopLinkSettingsParams){
		try{
			const serviceResponse = await baby.post('/saveshoplink', saveShopLinkSettingsParams);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			throw error;
		}
	}

	async addProductToShopService(params){
		try{
			const serviceResponse = await baby.post('/addproductstoshop', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't addProductToShopService", "On addProductToShopService Error");
			throw error;
		}
	}

	async addCollectionToSectionService(params){
		try{
			const serviceResponse = await baby.post('/addcollectionstosection', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't addCollectionToSectionService", "On addCollectionToSectionService Error");
			throw error;
		}
	}

	async removeProductFromShopService(params){
		try{
			const serviceResponse = await baby.post('/removeproductfromshop', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		}
		catch (error){
			throw error;
		}
	}

	async removeCollectionFromSectionService(params){
		try{
			const serviceResponse = await baby.post('/removecollectionfromsection', params);
			return serviceResponse;
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't removeCollectionFromSectionService", "On removeCollectionFromSectionService Error");
			throw error;
		}
	}

	//@Override
	async deleteSubjectService(itemInfo) {
		try {
			const response = await baby.post('/deleteshop', {"Type":itemInfo.Type, "uid":itemInfo.uid} );
			return response.data;
		} 
		catch (error) {
			console.error("Shop deleteSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	//@Override
	async bulkDeleteSubjectService(deleteInfo) {
		try {
			const response = await baby.post('/deletemultipleshops', deleteInfo );
			return response.data;
		} 
		catch (error) {
			console.error("Shop bulkDeleteSubjectService Failed with Error : "+error);
			throw error;
		}
	}

	async addShopPaymentDetailService(paymentInfo) {
		try {
			const serviceResponse = await baby.post('/addshoppaymentdetail', paymentInfo);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		} 
		catch (error) {
			console.error("addShopPaymentDetail Service Failed with Error : "+error);
			throw error;
		}
	}

	async updateShopPaymentDetailService(paymentInfo) {
		try {
			const serviceResponse = await baby.post('/updateshoppaymentdetail', paymentInfo);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		} 
		catch (error) {
			console.error("updateShopPaymentDetail Service Failed with Error : "+error);
			throw error;
		}
	}

	async removeShopPaymentDetailService(paymentInfo) {
		try {
			const serviceResponse = await baby.post('/removeshoppaymentdetail', paymentInfo);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		} 
		catch (error) {
			console.error("RemoveShopPaymentDetail Service Failed with Error : "+error);
			throw error;
		}
	}
	
	async getShopPaymentDetailService(paymentInfo) {
		try {
			const response = await baby.post('/read',paymentInfo);
			return response.data.SUBJECT;
		} 
		catch (error) {
			console.error("getShopPaymentDetail Service Failed with Error : "+error);
			throw error;
		}
	}

	async verifyPaymentAccountCredentialService(credentialInfo){
		try {
			const response = await baby.post('/verifypaymentaccountcredential',credentialInfo);
			return response.data.STATUS;
		} 
		catch (error) {
			console.error("getShopPaymentDetail Service Failed with Error : "+error);
			throw error;
		}
	}

	async getPaymentSettingsConfigService() {
		try {
			const response = await baby.post('/getpaymentsettingsconfig', {});
			return response.data;
		} 
		catch (error) {
			console.error("getPaymentSettingsConfig Service Failed with Error : "+error);
			throw error;
		}
	}

	async getThemesService(){
		try {
			const response = await baby.post('/getthemes', {});
			return response.data;
		} 
		catch (error) {
			console.error("getThemesService Failed with Error : "+error);
			throw error;
		}
	}

	async addSectionToPageService(params){
		try{
			const serviceResponse = await baby.post('/addsectionstopage', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't addSectionToPageService", "On addSectionToPageService Error");
			throw error;
		}
	}

	async deleteSectionFromPageService(params){
		try{
			const serviceResponse = await baby.post('/deletesectionfrompage', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't deleteSectionFromPageService", "On deleteSectionFromPageService Error");
			throw error;
		}
	}

	async addPagesToShopService(params){
		try{
			const serviceResponse = await baby.post('/addpagestoshop', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't addPagesToShopService", "On addPagesToShopService Error");
			throw error;
		}
	}

	async deletePageService(params){
		try{
			const serviceResponse = await baby.post('/deletepagefromshop', params);
			return super.processResponseDataForCreateUpdateRead(serviceResponse.data);
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't deletePageService", "On deletePageService Error");
			throw error;
		}
	}

	async addContentImageForSectionService(sectionImageInfoParam){
		try{
			const response = await baby.post('/addcontentimageforsection', sectionImageInfoParam);
			return response.data;
		} 
		catch (error) {
			console.error("addContentImageForSectionService Failed with Error : "+error);
			throw error;
		}
	}

	async removeContentImageForSectionService(sectionImageInfoParam) {
		try{
			const response = await baby.post('/removecontentimageforsection', sectionImageInfoParam);
			return response.data;
		} 
		catch (error) {
			console.error("removeContentImageForSectionService Failed with Error : "+error);
			throw error;
		}
	}
}
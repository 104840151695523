import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import { action, autorun, observable, toJS } from 'mobx';
import { languagePack } from '../../app/utils/LanguagePack';
import storeFactory from '../utils/StoreFactory';
import VerticalFactory from '../utils/vertical/VerticalFactory';

//Added for stringify in deHydrateStore
String.prototype.escapeSpecialChars = function() {
    return this.replace(/[']/g, "\\'")
			   .replace(/\\"/g, '\\\\"')
			   .replace(/\\n/g, '\\n')
			   .replace(/\\r/g, '\\r')
			   .replace(/\\t/g, '\\t')
			   .replace(/\\b/g, '\\b')
			   .replace(/\\f/g, '\\f');
};
export class LanguageStore {
	
	@observable currentPack = observable.map();
	@observable currentDigitPack = [];
	typePack = [];
	categoryPack = {};

	constructor(){
		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_SHOP_LANGUAGE_STORE__) &&
			(window.__INITIAL_STATE_SHOP_LANGUAGE_STORE__ !== "__INITIAL_STATE_SHOP_LANGUAGE_STORE__") )
		{
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_SHOP_LANGUAGE_STORE__ FOUND ");
			this.hydrateStore(window.__INITIAL_STATE_SHOP_LANGUAGE_STORE__);
			delete window.__INITIAL_STATE_SHOP_LANGUAGE_STORE__;
		}else{
			const newLanguage = storeFactory.NavigationStore.currentLanguageName;
        	this.reloadLanguagePack(newLanguage);
		}
	}

	getTypeDictonary(){
		return this.typePack;
	}
	
	getCategoryDictonary(){
		return this.categoryPack;	
	}

	setTypeDictonary(typePack){
		this.typePack = typePack;
	}
	
	setCategoryDictonary(categoryPack){
		this.categoryPack = categoryPack;	
	}

	@action.bound
	setCurrentLanguagePack(slanguagePack){
		if(slanguagePack)
			this.currentPack.merge(slanguagePack);
	}

	@action.bound
	async reloadLanguagePack(lang){
		if(lang){
			const clientLanguagePack = languagePack(lang);
			const clientLanguageDigitPack = languagePack(lang+"-"+"digit");
			if(clientLanguagePack){
				this.currentPack.clear();
				this.currentPack.merge(clientLanguagePack);
				this.currentDigitPack = clientLanguageDigitPack;
			}

			const vertical = process.env.vertical;
			const verticalConfig = VerticalFactory.fetchVertical(vertical);
			const verticalLanguagePack = verticalConfig.getLanguagePack(lang);
			if(verticalLanguagePack){
				this.currentPack.merge(verticalLanguagePack);
			}
		}
	}

	deHydrateStore(){
		return JSON.stringify({
            currentPack : toJS(this.currentPack),
            currentDigitPack : toJS(this.currentDigitPack),
			typePack : this.typePack,
			categoryPack : this.categoryPack
        }).escapeSpecialChars();
	}

	hydrateStore(storeData){
		const languageStoreData = JSON.parse(storeData);
        this.currentPack.merge(languageStoreData.currentPack);
        this.currentDigitPack = languageStoreData.currentDigitPack;
		this.typePack = languageStoreData.typePack,
		this.categoryPack = languageStoreData.categoryPack
	}

	languageChangeDisposer = autorun(() => {
		console.log("==== Language Store Autorun | Language Change Check Activated.....");	
        const newLanguage = storeFactory.NavigationStore.currentLanguageName;
        this.reloadLanguagePack(newLanguage);
    }, {name: "languageChangeDisposer"});
}

function getValue(key, ...args){
	let value = this.currentPack.get(key) ? this.currentPack.get(key) : "###";
	_forEach(args, (arg, index) => {
		const replaceCounter = index+1;
		const regExString = "{["+replaceCounter+"]}";
		let regExp = new RegExp(regExString,"gm");
		value = _replace(value, regExp, arg);
	})
	return value;
}

function getNumber(key){
	const regExp = new RegExp("[0-9]","g");
	return _replace(key, regExp, (digi) => {
		return this.currentDigitPack[digi];
	});
}

const store = new LanguageStore();
export default store;

export const value = getValue.bind(store); 

export const number = getNumber.bind(store); 

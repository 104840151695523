import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';

export default class CustomersStore extends ItemStore {
	FACTORY_STORE_TYPE = 'USER';

	//@override
	async createItem(itemCreateInfo) {
		try {
			this.isLoading = true;
			itemCreateInfo.SourceID = itemCreateInfo.Email;
			itemCreateInfo.SECRET = '';
			const response = await this.createCustomerService(itemCreateInfo);
			if (response.status) {
				return response.SUBJECT;
			} else {
				return response.error;
			}
			//this.NavigationStore.changeModule(response.SUBJECT.DATA.Type);
			//emitter.emit(EVENTS.ITEM.OPEN, EVENTS.ITEM.OPEN, uid, response.SUBJECT.DATA.Type, response.SUBJECT.DATA.Category);
		} catch (error) {
			this.isLoading = false;
			//this.ErrorStore.log(error, "Couldn't create", "On Create Item Error");
			throw error;
		} finally {
			this.isLoading = false;
		}
	}

	async refreshUsersForSeller(userId) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
			const itemInfo = {
				uid: userId,
				Type: 'USER',
			};
			const response = await super.getSubjectByType(itemInfo);
			this.saveItem_Success(response);
		} catch (error) {
			this.isLoading = false;
			this.ErrorStore.log(
				error,
				"Couldn't Refresh users for Seller",
				'On Refresh users For Seller Error'
			);
		} finally {
			this.isLoading = false;
		}
	}

	/* ==========================================  Service Layer  ==========================================  */

	//@Override
	async takeWorkflowActionAndFindService(
		actionName,
		objectID,
		subjectInfo,
		page,
		filter,
		searchString,
		sortOption,
		patch
	) {
		try {
			const param = {
				...subjectInfo,
				FIND_WITH_FILTERS: true,
				FIND_WITHIN: true,
				ObjectID: objectID,
				Action: actionName,
				PATCH: patch,
			};
			if (filter) param.FILTER = filter;
			if (page) param.REQUESTED_PAGE = page;
			if (searchString) {
				param.SEARCHSTRING = searchString;
				param.OR = true;
			}
			if (sortOption) {
				param.ORDER = sortOption.value;
				param.IS_ASC = sortOption.sortMode === 'asc';
			}
			const response = await baby.post(
				'/takeorderworkflowactionandfind',
				param
			);
			return response.data;
		} catch (error) {
			console.error(
				'Customer takeWorkflowActionAndFindService For Customer Failed with Error : ' +
					error
			);
			throw error;
		}
	}

	async createCustomerService(itemCreateInfo) {
		try {
			const response = await baby.post('/createcustomer', itemCreateInfo);
			const subject = response.data.SUBJECT;
			return {
				status: true,
				SUBJECT: subject,
			};
		} catch (error) {
			console.error(
				'createCustomerService Failed with Error : ' +
					error.response.data
			);

			return {
				status: false,
				error: error.response ? error.response.data : error,
				SUBJECT: null,
			};
		}
	}
}

const SubscriptionPlanList = [
    {
        id: "standard",
        title: "Basic",
        name: "Basic Plan",
        plan: {
            "Year" : {
                retailPrice : "1999",
                discountedPrice : "999"
            },
            "Month" : {
                retailPrice : "199",
                discountedPrice : "99"
            }
        }
    },
    {
        id: "pro",
        title: "Pro",
        name: "Pro Plan",
        plan: {
            "Year" : {
                retailPrice : "34999",
                discountedPrice : "24999"
            },
            "Month" : {
                retailPrice : "3499",
                discountedPrice : "2499"
            }
        }
    },
    {
        id: "premium",
        title: "Premium",
        name: "Premium Plan",
        plan: {}
    }
  ];
  
  const SubscriptionFeatureList = [
    {
        id: "products",
        label: "Products",
        type: "text",
        data: {
            "standard" : "100",
            "pro" : "1000",
            "premium" : "Unlimited",
        }
    },
    {
        id: "collections",
        label: "Collections",
        type: "text",
        data: {
            "standard" : "100",
            "pro" : "100",
            "premium" : "Unlimited",
        }
    },
    {
        id: "catalogs",
        label: "Catalogs",
        type: "text",
        data: {
            "standard" : "100",
            "pro" : "100",
            "premium" : "Unlimited",
        }
    },
    {
        id: "shop-website",
        label: "Shop/Website",
        type: "text",
        data: {
            "standard" : "1",
            "pro" : "1",
            "premium" : "Unlimited",
        }
    },
    {
        id: "storeAnalytics",
        label: "Store Analytics",
        type: "flag",
        data: {
            "standard" : "No",
            "pro" : "Yes",
            "premium" : "Yes",
        }
    },
    {
        id: "dashboard",
        label: "Dashboard",
        type: "flag",
        data: {
            "standard" : "No",
            "pro" : "Yes",
            "premium" : "Yes",
        }
    }
  ];

  export default {
    SubscriptionPlanList,
    SubscriptionFeatureList
  }
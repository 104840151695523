import fmcgVertical from "./fmcg/config";
import realEstateVertical from "./realestate/config";
import lifestyleVertical from "./lifestyle/config";
import servicesVertical from "./services/config";
import autoVertical from './auto/config';

export const VERTICALS = {
    FMCG : "fmcg",
    REALESTATE : "realestate",
    LIFESTYLE : "lifestyle",
    SERVICES : "services",
    AUTO : "auto"
}

class VerticalFactory {

    _verticalRepository = {};

    constructor(){
        this._verticalRepository[VERTICALS.FMCG] = fmcgVertical;
        this._verticalRepository[VERTICALS.REALESTATE] = realEstateVertical;
        this._verticalRepository[VERTICALS.LIFESTYLE] = lifestyleVertical;
        this._verticalRepository[VERTICALS.SERVICES] = servicesVertical;
        this._verticalRepository[VERTICALS.AUTO] = autoVertical;
    }

    fetchVertical(vertical){
        return this._verticalRepository[vertical];
    }
}

export default new VerticalFactory();
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import { action, computed, observable, runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';
import VerticalFactory from '../../0-common/utils/vertical/VerticalFactory';
import ShopSearchStore from './Shop-SearchStore';

const defaultShopPage = "HomePage";

export default class PublicShopStore extends ItemStore{

	FACTORY_STORE_TYPE = "PUBLICWEBSHOPS";

	constructor(subjectInfo){
		super(subjectInfo);
	}

	@observable shopOwnerInfo = {};
	@observable showSideBar = false;
	@observable showFilterSideBar = false;
	@observable sendMessageLoading = false;
	searchStore = null;

	get PseudoPages(){
		return [
			{
				uid: this.NavigationStore.SUBVIEW.BUYER.PROFILE,
				Type: "WEPAGES",
				Name: "Profile",
				CategorySourceID: "AccountPage",
				SourceID: this.NavigationStore.SUBVIEW.BUYER.PROFILE,
				ShowOnHeader: false,
				ShowOnFooter: false
			},
			{
				uid: this.NavigationStore.SUBVIEW.BUYER.ORDER,
				Type: "WEPAGES",
				Name: "Orders",
				CategorySourceID: "AccountPage",
				SourceID: this.NavigationStore.SUBVIEW.BUYER.ORDER,
				ShowOnHeader: false,
				ShowOnFooter: false
			}
		];
	}

	@computed
	get ShopCategory(){
		const shop = super.getCurrentItem();
		if(shop){
			return shop.DATA.CategorySourceID;
		} else {
			return null;
		}
	}

	@computed
	get DefaultShopPageID(){
		const shop = super.getCurrentItem();
		if(shop){
			const pageList = this.Pages;
			const pageRef = _find(pageList, {"CategorySourceID" : defaultShopPage});
			return pageRef.uid;
		}
	}

	get DefaultShopPageCategorySourceID(){
		return defaultShopPage;
	}

	@computed
	get ActivePage(){
		const shopParam = this.NavigationStore.getContextualParam("SHOP");
        if(shopParam && shopParam.pageID){
			return shopParam.pageID;
		} else {
			/*if(this.NavigationStore.currentViewName === this.NavigationStore.VIEW.BUYERACCOUNT){
				return this.NavigationStore.currentSubView;
			} else */
			if(this.NavigationStore.currentViewName === this.NavigationStore.VIEW.CHECKOUT){
				return null;
			} else {
				return this.DefaultShopPageID;
			}
		}
	}

	set ActivePage(pageID){
		const shopParam = this.NavigationStore.getContextualParam("SHOP");
		if(shopParam){
			shopParam.pageID = pageID;
			shopParam.itemID = null;
			shopParam.itemCategory = null;
		} else {
			const newShopParam = {
				pageID : pageID,
				itemID : null,
				itemCategory : null
			};
			this.NavigationStore.setContextualParam("SHOP", newShopParam);
		}
	}

	getUniquePageName(uniquePageNameList, originalPageName){
		let pageUID = "";
		const pageName = originalPageName.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(/[^A-Za-z0-9-]/g, "");
		if(uniquePageNameList[pageName]){
			let pageCounter = uniquePageNameList[pageName];
			pageUID = `${pageName}-${pageCounter}`;
			uniquePageNameList[pageName] = ++pageCounter;
		} else {
			pageUID = pageName;
			uniquePageNameList[pageName] = 1;
		}
		return pageUID;
	}

	@computed
	get Pages(){
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			const uniquePageNameList = {};
			const pageList = _map(shop.DATA.PagesInShop, (pageID) => {
				const pageInfo = shop.REFERENCES[pageID];
				const generatedPageUID = this.getUniquePageName(uniquePageNameList, pageInfo.Name);
				return {...pageInfo, "uid" : generatedPageUID, "key" : pageID};
			});
			pageList.push(...this.PseudoPages);
			return pageList;
		} else {
			return [];
		}
	}

	@computed
	get SectionInShop(){//eg:- Footer, Header, ProductsSection
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			const sectionsInShop = _map(shop.DATA.SectionsInShop, (sectionID) => {
				return shop.REFERENCES[sectionID];
			});
			return sectionsInShop;
		} else {
			return [];
		}
	}

	@computed
	get FooterSection(){
		let footerSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'FooterSection'){
					footerSection = sectionInfo;
					return false;
				}
			});
		}
		return footerSection;
	}

	@computed
	get HeaderSection(){
		let headerSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'HeaderSection'){
					headerSection = sectionInfo;
					return false;
				}
			});
		}
		return headerSection;
	}

	@computed
	get ProductSection(){//Looking for ProductsSection in SectionsInShop, For CardView Template
		let productSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'ProductsSection'){
					productSection = sectionInfo;
					return false;
				}
			});
		}
		return productSection;
	}

	@computed
	get CollectionSection(){//Looking for AllCollectionsSection in SectionsInShop, For CardView Template
		let collectionSection = {};
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			_forEach(shop.DATA.SectionsInShop, (sectionID) => {
				const sectionInfo = shop.REFERENCES[sectionID];
				if(sectionInfo && sectionInfo.CategorySourceID === 'AllCollectionsSection'){
					collectionSection = sectionInfo;
					return false;
				}
			});
		}
		return collectionSection;
	}

	getSectionByID(sectionID){
		if(this.getCurrentItem()){
			const shop = this.currentItem;
			return { DATA: shop.REFERENCES[sectionID], REFERENCE_IMAGES: shop.REFERENCE_IMAGES, OPTIONS : shop.OPTIONS};
		} else {
			return null;
		}
	}

	@computed
	get OwnerInfo(){
		if(this.shopOwnerInfo && this.shopOwnerInfo.DATA){
			let profileImage = this.shopOwnerInfo.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.shopOwnerInfo.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/auto/-/quality/smart/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
			return {...this.shopOwnerInfo.DATA, "ProfileImage" : profileImage};
		} else {
			return null;
		}
	}

	set OwnerInfo(shopOwner){
		this.shopOwnerInfo = shopOwner;
		this.StoreFactory.CurrencyStore.registerCurrency(shopOwner);
	}

	get OwnerSubscriptionPlan(){
		let subscriptionPlan = 'standard';
		if(this.shopOwnerInfo && this.shopOwnerInfo.DATA){
			const activeSubscriptionId = this.shopOwnerInfo.DATA.ActiveSubscription;
			if(activeSubscriptionId){
				const activeSubscription = this.shopOwnerInfo.REFERENCES[activeSubscriptionId];
				if(activeSubscription){
					subscriptionPlan = activeSubscription.SubscriptionPlan;
				}
			}
		}
		return subscriptionPlan;
	}

	@computed
	get ShopLogo(){
		let profileImage = null;
		if(this.getCurrentItem()){
			profileImage = this.currentItem.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.currentItem.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/auto/-/quality/smart/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
		}

		return profileImage;
	}

	get ShopFavIcon(){
		let profileImage = null;
		if(this.getCurrentItem()){
			profileImage = this.currentItem.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.currentItem.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/png/-/quality/smart/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
		}

		return profileImage;
	}

	@computed
	get ShopName(){
		let shopName = "";
		if(this.getCurrentItem()){
			shopName = this.currentItem.DATA.Name;
		}

		return shopName;
	}

	@computed
	get FooterTagline(){
		let footerTagline = null;
		if(this.getCurrentItem()){
			const footerSection = this.FooterSection;
			if(footerSection)
				footerTagline = footerSection.Description;
		}

		return footerTagline;
	}

	get isPreviewModeOn(){
		if(this.getCurrentItem()){
			const contextualParam = this.NavigationStore.getContextualParam("SHOP");
			const shopViewMode = contextualParam ? contextualParam.shopMode : "";
			if(shopViewMode === "preview" || this.currentItem.DATA.Visibility === "Private" ){
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	get ShopID(){
		if(this.getCurrentItem()){
			return this.getCurrentItem().DATA.uid;
		} else {
			return null;
		}
	}

	get ShopTheme(){
		return this.StoreFactory.ShopThemeStore;
	}

	get ShopName(){
		//Preparing Shop Title
		let shopURL = "";
		const shop = this.getCurrentItem();
		return shop.DATA.Name;
	}

	get ShopURLType(){
		//Preparing ShopURL
		let shopURL = "";
		const shop = this.getCurrentItem();
		return shop.DATA.URLType;
	}

	get ShopURL(){
		//Preparing ShopURL
		let shopURL = "";
		const shop = this.getCurrentItem();
		const shopLinkType = shop.DATA.URLType;
		if(shopLinkType === 'Standard'){
			const vertical = process.env.vertical;
			const verticalConfig = VerticalFactory.fetchVertical(vertical);
			const domainConfig = verticalConfig.getDomainConfig();
			const verticalShopDomain = domainConfig.domain;
			const verticalShopSubDomain = domainConfig.shop_subdomain;
			shopURL = `https://www.${verticalShopSubDomain}.${verticalShopDomain}/s/${shop.DATA.URL}`;
		} else {
			shopURL = `https://${shop.DATA.URL}`;
		}

		return shopURL;
	}

	get ShopDescription(){
		const shop = this.getCurrentItem();
		return shop.DATA.Description;
	}

	get ShopMetaData(){
		let shopTitle = "";
		let shopDescription = "";
		let metaRobotData = "noindex,nofollow";
		
		const shop = this.getCurrentItem();
		if(shop){
			shopTitle = shop.DATA.Name;
			shopDescription = shop.DATA.Description;

			const shopParams = this.NavigationStore.getContextualParam("SHOP"); 
            const pageName = shopParams.pageID;
            if(pageName){
                const pageRef = _find(this.Pages, ['uid',pageName]);
                if(pageRef){
					if(!_isEmpty(pageRef.Title)){
						shopTitle = `${shopTitle} | ${pageRef.Title}`;
					}else{
						shopTitle = `${shopTitle} | ${pageRef.Name}`;
					}
					if(!_isEmpty(pageRef.Description))
						shopDescription = pageRef.Description;
                }
            } else { //case: '/' or '/shop'
				const pageRef = _find(this.Pages, {"CategorySourceID" : this.DefaultShopPageCategorySourceID});
				if(pageRef){
					if(!_isEmpty(shop.DATA.Title)){
						shopTitle = `${shopTitle} | ${shop.DATA.Title}`;
					}else if(!_isEmpty(pageRef.Title)){
						shopTitle = `${shopTitle} | ${pageRef.Title}`;
					}else{
						shopTitle = `${shopTitle} | ${pageRef.Name}`;
					}
					if(_isEmpty(shopDescription) && !_isEmpty(pageRef.Description))
						shopDescription = pageRef.Description;
				}
            }

			//Preparing Robot Meta tag data
			if(this.ShopURLType === 'Standard'){
				metaRobotData = "noindex,nofollow";
			}else{
				metaRobotData = "index,follow";
			}
		}

		return{
			og_title_data : shopTitle,
			og_desc_data :  shopDescription,
			og_image_data : `${this.ShopLogo}-/preview/600x800/`,
			og_site_name_data : shopTitle,
			og_url_data : this.ShopURL,
			meta_robot_data: metaRobotData
		}
	}

	get ShopSchemaJSON_LD(){
		
		const websiteSchemaData = {
      		"@type": "WebSite",
			"name": this.ShopName,
			"url" : this.ShopURL
		};

		const WebPageSchemaData = {
			"@type": "WebPage",
			"name": this.ShopName,
			"url": this.ShopURL
	    };

		const shop = this.getCurrentItem();

		let sameAsURL = [];
		(shop && shop.DATA.facebookurl) ? sameAsURL.push(shop.DATA.facebookurl) : null;
        (shop && shop.DATA.instagramurl) ? sameAsURL.push(shop.DATA.instagramurl) : null;
        (shop && shop.DATA.twitterurl) ? sameAsURL.push(shop.DATA.twitterurl) : null;
        (shop && shop.DATA.linkedin) ? sameAsURL.push(shop.DATA.linkedin) : null;


		let organizationAddress = {
			"@type": "PostalAddress"
		};
		(shop && shop.DATA.streetaddress) ? organizationAddress.streetAddress = shop.DATA.streetaddress : null;
        (shop && shop.DATA.city) ? organizationAddress.addressLocality = shop.DATA.city : null;
        (shop && shop.DATA.pincode) ? organizationAddress.postalCode = shop.DATA.pincode : null;
        (shop && shop.DATA.state) ? organizationAddress.addressRegion = shop.DATA.state : null;
        (shop && shop.DATA.country) ? organizationAddress.addressCountry = shop.DATA.country : null;

		let organizationContact = {
			"@type" : "ContactPoint",
			"contactType": "contact"
		};

		(shop && shop.DATA.Email) ? organizationContact.email = shop.DATA.Email : null;
        (shop && shop.DATA.MobileNumber) ? organizationContact.telephone = shop.DATA.MobileNumber : null;

		const organizationSchemaData = {
			"@type": "Organization",
			"name": this.ShopName,
			"url": this.ShopURL,
			"logo": this.ShopLogo,
			"description" : this.ShopDescription,
			"sameAs": sameAsURL,
			"address" : organizationAddress,
			"contactPoint" : organizationContact
	    };

		return {
			"@context": "http://schema.org",
			"@graph": [organizationSchemaData, websiteSchemaData, WebPageSchemaData]
	    };
	}
    
	get DeliveryCharge(){
	   	const shop = this.getCurrentItem();
	  	if(shop)
		{	 
			const currency = this.StoreFactory.CurrencyStore.Currency;
			if(shop.DATA.DeliveryType=="StandardShipping")
				return currency.format(shop.DATA.DeliveryCharge);
			else
				return "Free"
		}
     	else{
			return "Free";
		}  
 	}

	get PaymentSettings(){
		let paymentSettingsInfo = [];
		const shop = this.getCurrentItem();
		if(shop){
			paymentSettingsInfo = _map(shop.DATA.PaymentSetting, (paymentSettingID) => {
				return shop.REFERENCES[paymentSettingID];
			});
		}

		return paymentSettingsInfo;
	}

	initializeSearchStoreForShop(){
		const shopID = this.ShopID;
		const sellerID = this.OwnerInfo.SourceID;
		const SEARCH_SUBJECT_TYPE = {
			"uid":shopID,
			"Type":"PRODUCTS",
			"Category":"",
			"PREDICATE_TYPE":"ProductsInShop",
			"FIND_WITHIN":true,
			"FIND_WITH_FILTERS":true,
			"REQUESTED_PAGE":1,
			"APP_OWNER": sellerID,
			"ShopID":shopID
		};
		this.searchStore = new ShopSearchStore(SEARCH_SUBJECT_TYPE);
	}

	@action
	async executeFetchShopWithPublicAccess(shopId, shopLink){

		try{
			this.isLoading = true;
			this.currentItem = {};
			const itemInfo = {"Type" : "WEBSHOPS"};
			if(shopId)
				itemInfo["uid"] = shopId;
			if(shopLink)
				itemInfo["URL"] = shopLink;

			const response = await this.fetchShopWithPublicAccessService(itemInfo);
			await this.StoreFactory.ThemeFactory.loadTheme(response.SUBJECT.DATA.Theme);
			runInAction("On Fetch Public Shop Success",() => {
				this.currentItem = response.SUBJECT;
				//Update Shop Theme Store
				this.ShopTheme.ThemeID = response.SUBJECT.DATA.Theme;
				this.ShopTheme.Theme = response.THEME;
				this.OwnerInfo = response.USER;
				this.ModuleStore.processModuleResponseData(response.TRANSLATIONS, response.MODULES);
				response.onComplete();
				this.initializeSearchStoreForShop();
			});
		} 
		catch (error){
		runInAction("On Fetch Public Shop Error", () => {
			this.isLoading = false;
			this.ErrorStore.log(error, "Couldn't perform Fetch Public Shop", "On Fetch Public Shop Error"); 
			throw error;
		});
		} 
		finally {
			this.isLoading = false;
		}
	}

	async executeFetchPageSectionData(pageID){
		try{
			const pageList = this.Pages;
			const activePage = _find(pageList, ['uid', pageID]);
			const sectionsInPage = activePage.SectionsInPage;

			await Promise.all(_map(sectionsInPage, async (sectionId) => {
				try{
					const sectionRef = this.getSectionByID(sectionId);
					if(sectionRef){
						if(sectionRef.DATA.CategorySourceID === 'CollectionsSection') {
							const sectionTheme = this.ShopTheme.Sections[sectionRef.DATA.ThemeSectionID];

							const X_SUBJECT_TYPE = {
								"uid":sectionRef.DATA.uid,
								"Type":"COLLECTIONS",
								"Category":"",
								"PREDICATE_TYPE":"CollectionsInSection",
								"FIND_WITHIN":true,
								"PAGE_SIZE":-1,
								"FIND_WITH_FILTERS":true,
								"REQUESTED_PAGE":1,
								"APP_OWNER": this.OwnerInfo.SourceID,
								"ShopID": this.ShopID
							};
							const shopSectionStore = this.StoreFactory.fetchStore("WEBSECTIONS", X_SUBJECT_TYPE);
							if(sectionTheme.SHOW_CHILDREN){
								await shopSectionStore.loadItems();
								const collectionsInSection = shopSectionStore.ItemList;
								if(collectionsInSection && collectionsInSection.length > 0){
									const collection = collectionsInSection[0];
									const X_SUBJECT_TYPE = {
										"uid":collection.DATA.uid,
										"Type":"PRODUCTS",
										"Category":"",
										"PREDICATE_TYPE":"ProductsInCollection",
										"FIND_WITHIN":true,
										"PAGE_SIZE":-1,
										"FIND_WITH_FILTERS":false,
										"REQUESTED_PAGE":1,
										"APP_OWNER": this.OwnerInfo.SourceID,
										"ShopID": this.ShopID
									};
									const shopCollectionStore = this.StoreFactory.fetchStore("SHOPCOLLECTIONS", X_SUBJECT_TYPE);
									await shopCollectionStore.loadItems();
									const categoryDictionary = this.ModuleStore.getModuleCategories("PRODUCTS");
									//Added to set CategoryName(resolved name of category) for products in collection.
									_forEach(shopCollectionStore.ItemList, product => {
										const category = _find(categoryDictionary, ['key', product.DATA.Category]);
										const categoryName = category ? category.text : "$$$";
										product.DATA.CategoryName = categoryName;
									});
								}
								return Promise.resolve();
							}else{
								return shopSectionStore.loadItems();
							}
						}
					}
				}catch(error){
					return Promise.reject(error.message);
				}
			}));

			return true;
		}
		catch (error){
			this.ErrorStore.log(error, "Couldn't perform Fetch Home Page Section Data", "On executeFetchHomePageSectionData"); 
			return false;
		} 
	}

	async executeSendMessage(name, email, message){
		try {
			this.sendMessageLoading = true;
			const params = {
				"From" : name,
				"FromEmail" : email,
				"Message" : message,
				"To" : this.currentItem.DATA.Name,
				"ToEmail" : this.currentItem.DATA.Email
			}
			await this.sendMessageService(params);
			return true;

		} catch (error) {
			this.ErrorStore.log(error, "Couldn't perform Send Message", "On executeSendMessage Error"); 
			return false;
		} finally{
			this.sendMessageLoading = false;
		}
	}

	@action
	async executeFetchShopSiteXML(shopId, shopLink){
		const shopURLs = [];
		try{
			this.isLoading = true;
			const itemInfo = {"Type" : "WEBSHOPS"};
			if(shopId)
				itemInfo["uid"] = shopId;
			if(shopLink)
				itemInfo["URL"] = shopLink;
			const response = await this.fetchPublicGenerateShopSiteXMLService(itemInfo);
			const pagesInShop =	response.PagesInShop;
			let uniquePageNameList = {};
			let productPageID = null;
			let collectionPageID = null;
			
			_forEach(pagesInShop, (page) => {
				const pageCategorySourceID = page.DATA.CategorySourceID;
				const generatedPageUID = this.getUniquePageName(uniquePageNameList, page.DATA.Name);
				
				if(pageCategorySourceID === 'ProductPage'){
					productPageID = generatedPageUID;
				}
				if(pageCategorySourceID === 'CollectionPage'){
					collectionPageID = generatedPageUID;
				}
				if(page.DATA.ShowOnFooter || page.DATA.ShowOnHeader){
					shopURLs.push(`/shop/${generatedPageUID}`);
				}
			});

			if(productPageID){
				const productsInShopURL = response.ProductsInShopURL;
				_forEach(productsInShopURL, (productURL) => {
					shopURLs.push(`/shop/${productPageID}/${productURL}`);
				});
			}
			
			if(collectionPageID){
				const collectionsInShopURL = response.CollectionsInShopURL;
				_forEach(collectionsInShopURL, (collectionURL) => {
					shopURLs.push(`/shop/${collectionPageID}/${collectionURL}`);
				});
			}
		}
		catch (error){
			runInAction("On Fetch Shop Site XML Error", () => {
				this.isLoading = false;
				this.ErrorStore.log(error, "Couldn't perform Fetch Shop Site XML", "On Fetch Shop Site XML Error"); 
			});
		} 
		finally {
			this.isLoading = false;
			return shopURLs;
		}
	}

	/* ==========================================  Service Layer  ==========================================  */

	async fetchShopWithPublicAccessService(itemInfo) {
		try {
			const serviceResponse = await baby.post('/publicreadshop', itemInfo);
			const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
			return response;
		} 
		catch (error) {
			console.error("fetchShopWithPublicAccessService Failed with Error : "+error);
			throw error;
		}       
	}

	async sendMessageService(messageInfo) {
		try {
			const serviceResponse = await baby.post('/publiccontactus', messageInfo);
			return serviceResponse;
		} 
		catch (error) {
			console.error("sendMessageService Failed with Error : "+error);
			throw error;
		}       
	}

	async fetchPublicGenerateShopSiteXMLService(itemInfo){
		try {
			const serviceResponse = await baby.post('/publicgenerateshopsitexml', itemInfo);
			return serviceResponse.data;
		} 
		catch (error) {
			console.error("fetchPublicGenerateShopSiteXMLService Failed with Error : "+error);
			throw error;
		}
	}
	
}
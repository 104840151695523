import { value } from "../../../store/LanguageStore";
import { VerticalConfig } from "../VerticalConfig";
import {languagePack} from './language-pack';

const ph_profileImageURL        =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
const ph_profileImageURL_mini   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/lightest/-/preview/250x100/'; 
const ph_logoImageURL           =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';

const ph_logoImageURL_slider1   =   'https://ucarecdn.com/1cc07375-734f-46a5-8f18-2205dc18b43c/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider2   =   'https://ucarecdn.com/bfe9eee2-e146-4b34-b7e0-8729379ae1aa/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider3   =   'https://ucarecdn.com/21b3a9e5-15ef-4205-99f8-532271d83c4f/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider4   =   'https://ucarecdn.com/2c6906a2-31ac-49df-9424-bdfcd9fe77ef/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider5   =   'https://ucarecdn.com/320ace92-ea60-4253-99a7-3f3e51e21afa/-/format/auto/-/quality/smart/';

class FmcgVerticalConfig extends VerticalConfig{

    //@override
    getSliderConfig(){
        return {
            header : value('lb_app_logo_header'),
            images: [ph_logoImageURL_slider1, ph_logoImageURL_slider2, ph_logoImageURL_slider3, ph_logoImageURL_slider4, ph_logoImageURL_slider5],
            caption: `${value('lb_app_logo_slider1_caption')} | ${value('lb_app_logo_slider2_caption')} | ${value('lb_app_logo_slider3_caption')} | ${value('lb_app_logo_slider4_caption')} | ${value('lb_app_logo_slider5_caption')}`,
        }
    }

    //@override
    getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "fmcg",
            shop_subdomain : "fmcgshop",
        }
    }

    //@override
    getLogoConfig(){
        return {
            profileImage : ph_profileImageURL,
            profileImage_mini : ph_profileImageURL_mini,
            logoImage : ph_logoImageURL
        }
    }

    //@override
    getInventoryConfig(){
        return {
            visible : true,
        }
    }

    //@override
    getLanguagePack(lang){
        return languagePack(lang);
    }
}

export default new FmcgVerticalConfig();
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { computed, observable, toJS } from 'mobx';

//Added for stringify in deHydrateStore
String.prototype.escapeSpecialChars = function() {
    return this.replace(/[']/g, "\\'")
			   .replace(/\\"/g, '\\\\"')
			   .replace(/\\n/g, '\\n')
			   .replace(/\\r/g, '\\r')
			   .replace(/\\t/g, '\\t')
			   .replace(/\\b/g, '\\b')
			   .replace(/\\f/g, '\\f');
};
export default class ShopThemeStore{
    
    @observable currentTheme = {};
    @observable currentThemeID = "";

    constructor(){
		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_SHOP_THEME_STORE__) &&
			(window.__INITIAL_STATE_SHOP_THEME_STORE__ !== "__INITIAL_STATE_SHOP_THEME_STORE__") )
		{
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_SHOP_THEME_STORE__ FOUND ");
			this.hydrateStore(window.__INITIAL_STATE_SHOP_THEME_STORE__);
			delete window.__INITIAL_STATE_SHOP_THEME_STORE__;
		}
	}

    set Theme(shopTheme){
        this.currentTheme = shopTheme;
    }

    @computed
    get Theme(){
        return this.currentTheme;
    }

    set ThemeID(shopThemeID){
        this.currentThemeID = shopThemeID;
    }

    @computed
    get ThemeID(){
        return this.currentThemeID
    }

    get Sections(){
        return this.Theme.SECTIONS;
    }

    get Pages(){
        return this.Theme.PAGES;
    }

    getHeaderThemes(){
        return _compact(_map(this.Theme.SECTIONS, (section, sectionThemeID) => {
            if(section.Category === "HeaderSection"){
                const collectionSection = {...section};
                collectionSection["headerThemeID"] = sectionThemeID;
                return collectionSection;
            }
        }));
    }

    getHeaderTemplateID(themeSectionID){
        let headerTemplateID = null;
        const headerSection = this.Theme.SECTIONS[themeSectionID];
        if(headerSection){
            headerTemplateID = headerSection.LAYOUT;
        }
        return headerTemplateID;
    }

    getFooterThemes(){
        return _compact(_map(this.Theme.SECTIONS, (section, sectionThemeID) => {
            if(section.Category === "FooterSection"){
                const collectionSection = {...section};
                collectionSection["footerThemeID"] = sectionThemeID;
                return collectionSection;
            }
        }));
    }

    getFooterTemplateID(themeSectionID){
        let footerTemplateID = null;
        const footerSection = this.Theme.SECTIONS[themeSectionID];
        if(footerSection){
            footerTemplateID = footerSection.LAYOUT;
        }
        return footerTemplateID;
    }

    getSectionThemes(){
        return _compact(_map(this.Theme.SECTIONS, (section, sectionThemeID) => {
            if(section.Category === "CollectionsSection"){
                const collectionSection = {...section};
                collectionSection["sectionThemeID"] = sectionThemeID;
                return collectionSection;
            }
        }));
    }

    getSectionTemplateID(themeSectionID){
        let sectionTemplateID = null;
        const sectionInfo = this.Theme.SECTIONS[themeSectionID];
        if(sectionInfo){
            sectionTemplateID = sectionInfo.LAYOUT;
        }
        return sectionTemplateID;
    }

    getCardViewTemplateID(themeSectionID){
        let cardViewTemplateID = null;
        const sectionInfo = this.Theme.SECTIONS[themeSectionID];
        if(sectionInfo){
            cardViewTemplateID = sectionInfo.LAYOUT;
        }
        return cardViewTemplateID;
    }

    getBannerTemplateID(themeSectionID){
        let bannerTemplateID = null;
        const bannerInfo = this.Theme.SECTIONS[themeSectionID];
        if(bannerInfo){
            bannerTemplateID = bannerInfo.LAYOUT;
        }
        return bannerTemplateID;
    }

    deHydrateStore(){
		return JSON.stringify({
            Theme : toJS(this.currentTheme),
            ThemeID : toJS(this.currentThemeID),
        }).escapeSpecialChars();
	}

	hydrateStore(storeData){
		const shopThemeStoreData = JSON.parse(storeData);
        this.Theme = shopThemeStoreData.Theme;
        this.ThemeID = shopThemeStoreData.ThemeID;
	}
}
import _assignWith from 'lodash/assignWith';
import { observable, action, computed, toJS } from 'mobx';
import WindowUtil from '../../shop/utils/WindowUtil';

export class NavigationStore {

	VIEW = {
	    HOME: 'home',
	    OPEN: 'open',
	    NONE: 'none',
	    SIGNUP : 'signup',
		USERVERIFICATION : 'signup_userVerification',
	    FORGOTPASSWORD : 'forgotPassword',
		USER : 'user',
		LOGIN : 'login',
		CATALOG : 'catalog',
		CHECKOUT : 'checkout',
		SHOP: 'shop',
		SHOPLOGIN: 'shoplogin',
		//BUYERACCOUNT: 'account'
	};

	SUBVIEW = {
	    DESIGN: 'design',
		NONE: 'none',
		SHOP : {
			SIGNUP : 'shop_signup',
	    	FORGOTPASSWORD : 'shop_forgotPassword',
			LOGIN : 'shop_login',
			RESETPASSWORD : 'shop_resetpassword',
			PRODUCT : {
				LIST : "shop_product_list_view",
				OPEN : "shop_product_open_view",
			},
			COLLECTION : {
				LIST : "shop_collection_list_view",
				OPEN : "shop_collection_open_view",
				OPEN_VIA_SECTION : "shop_collection_open_via_section_view",
			}
		},
		BUYER : {
			PROFILE : "profile-information",
			ADDRESS : "manage-address",
			PASSWORD : "change-password",
			ORDER : "orders-and-returns"
		},
	};

	SCREEN = {
    	LOGIN: 'login',
    	HOME: 'home',
    	RESETPASSWORD : 'resetpassword',
    	USER : 'user',
    	NONE: 'none',
    	CATALOG: "catalog",
    	PRODUCT: "public/product",
		SUBSCRIPTION: "subscription",
		PAYMENT: "payment",
		SHOP: "shop",
		PAGE_NOT_FOUND: "pageNotFound",
		SERVER_UNDER_MAINTENANCE: "serverUnderMaintenance"
	};

	@observable currentModule = {"moduleName" : ""};
	@observable currentScreen = {"screenName" : this.SCREEN.NONE};
	@observable currentView = {"viewMode" : this.VIEW.NONE, "subView" : this.SUBVIEW.NONE};
	@observable currentLanguage = {"lang" : "en"};
	@observable activeItem = {
								"uid" : "",
								"Type" : "",
								"Category" : ""
							};
	@observable activePage = observable.box("1");
	@observable contextualParams = {};
	
	resetCode = "";

	@computed get currentViewName() {
        const viewMode = this.currentView.viewMode;
        return viewMode;
    }

    @computed get currentSubView() {
        const subViewMode = this.currentView.subView;
        return subViewMode;
    }

    @computed get currentModuleName() {
        const moduleName = this.currentModule.moduleName;
        return moduleName;
    }

    @computed get currentScreenName() {
        const screenName = this.currentScreen.screenName;
        return screenName;
    }

	@computed get currentLanguageName() {
        const lang = this.currentLanguage.lang;
        return lang;
    }

    @action changeView(viewMode, subViewMode = this.SUBVIEW.NONE) {
		console.log(" NavigationStore. << View Change Request >> ");
		console.log(" Prev view name : "+this.currentView.viewMode);
		console.log(" New view name : "+viewMode);
		this.currentView.viewMode = viewMode;
		console.log(" Prev sub-view name : "+this.currentView.subView);
		console.log(" New sub-view name : "+subViewMode);
		this.currentView.subView = subViewMode;
	}

	@action changeModule(moduleName) {
		console.log(" NavigationStore. << Module Change Request >> ");
		console.log(" Prev module name : "+this.currentScreen.moduleName);
		console.log(" New module name : "+moduleName);
		this.currentModule.moduleName = moduleName;
	}

	@action changeScreen(screenName) {
		console.log(" NavigationStore. << Screen Change Request >> ");
		console.log(" Prev screen name : "+this.currentScreen.screenName);
		console.log(" New screen name : "+screenName);
		this.currentScreen.screenName = screenName;
	}

	@action setActvieItem(itemId, itemType, itemCategory) {
		this.activeItem.uid = itemId;
		this.activeItem.Type = itemType;
		this.activeItem.Category = itemCategory;
	}

	@action resetActvieItem() {
		this.activeItem.uid = "";
		this.activeItem.Type = "";
		this.activeItem.Category = "";
	}

	@action setActviePage(page) {
		this.activePage.set(page);
	}

	@action getActviePage() {
		return this.activePage.get();
	}

	@action setCurrentLang(lang) {
		 this.currentLanguage.lang = lang;
	}

	getResetCode() {
		return this.resetCode;
	}

	setResetCode(code) {
		 this.resetCode = code;
	}

	getContextualParam(context) {
		return this.contextualParams[context];
	}

	setContextualParam(context, params) {
		 this.contextualParams[context] = params;
	}

	removeContextualParam(context) {
		delete this.contextualParams[context];
	}

	@action resetView(moduleName, viewMode = this.VIEW.HOME, pageNo = 1, itemId = 1, itemType = "", itemCategory = "") {
		this.currentModule.moduleName = moduleName;
		this.currentView.viewMode = viewMode;
		this.currentView.subView = this.SUBVIEW.NONE;
		this.activePage.set(pageNo);
		this.setActvieItem(itemId, itemType, itemCategory);
	}

	get ShopLink(){
		const shopParams = this.getContextualParam("SHOP"); 
		if(shopParams && shopParams.shopLink){ // case : Standard URL
			return shopParams.shopLink;
		} else { // case : Custom Domain URL || Preview Mode
			const shopLink_hostName = WindowUtil.HostName;
			return shopLink_hostName;
		}
	}
}

//Added for stringify in deHydrateStore
String.prototype.escapeSpecialChars = function() {
    return this.replace(/[']/g, "\\'")
			   .replace(/\\"/g, '\\\\"')
			   .replace(/\\n/g, '\\n')
			   .replace(/\\r/g, '\\r')
			   .replace(/\\t/g, '\\t')
			   .replace(/\\b/g, '\\b')
			   .replace(/\\f/g, '\\f');
};

export default class NavigationRepository{

	_currentContext = "GLOBAL";
	_stack = ["GLOBAL"];
	_navigationContext = {
		"GLOBAL" : new NavigationStore()
	};

	constructor(){
		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_NAVIGATOR__) &&
			(window.__INITIAL_STATE_NAVIGATOR__ !== "__INITIAL_STATE_NAVIGATOR__") )
		{
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_NAVIGATOR__ FOUND ");
			this.hydrateStore(window.__INITIAL_STATE_NAVIGATOR__);
			delete window.__INITIAL_STATE_NAVIGATOR__;
		}
	}
	
	set = function(context, moduleName) {
		const localNavigationStore = new NavigationStore();
		localNavigationStore.setCurrentLang(this._navigationContext[this._currentContext].currentLanguageName);
		localNavigationStore.changeModule(moduleName);
		this._currentContext = context;
		this._navigationContext[this._currentContext] = localNavigationStore;
		this._stack.push(context);
	}

	unset = function() {
		this._stack.pop();
		this._currentContext = this._stack[this._stack.length-1];
	}

	currentNavigationStore = (isGlobal) => {
		if(isGlobal)
			return this._navigationContext["GLOBAL"];
		else
			return this._navigationContext[this._currentContext];
	}

	reset = () => {
		this._navigationContext = {
			"GLOBAL" : new NavigationStore()
		};
	}

	deHydrateStore(){
		return JSON.stringify(toJS(this._navigationContext["GLOBAL"])).escapeSpecialChars();
	}

	hydrateStore(storeData){
		const hydratedNavStore = JSON.parse(storeData);
		let navStoreToBeHydrated = this._navigationContext["GLOBAL"];
		navStoreToBeHydrated = _assignWith(navStoreToBeHydrated, hydratedNavStore,  (objValue, srcValue, key) => {
			switch (key) {
				case "activePage" :
					return observable.box(srcValue);

				default :
					return srcValue;
			}
		});
	}

}

import _isEmpty from 'lodash/isEmpty';
import ThemeLibrary from "../theme/Theme-Library";
/**
 * Note : Cannot hydrate-dehydrate ThemeFactory instance, because the _themeRepository holds styled-react-component.
 * Not recommended to serialize-deserialize react components.
 * 
 * Sol'n for SSR:  
 *  1. Serialize themeID (__INITIAL_STATE_THEME__) 
 *  2. In shop/index.js call storeFactory.initializeThemeFactory(), before mounting React DOM.
 */
export default class ThemeFactory {

    _defaultThemeID = "Default";
    
    _themeRepository = {};

    /*_InitializeFactory(){
        
        _forEach(ThemeLibrary.themes, theme => {
            const theme_Meta_Info = theme.themeInfo.MetaData;
            this._themeRepository[theme_Meta_Info.id] = theme;
        });
    };*/

    get DefaultTheme(){
        return this._themeRepository[this._defaultThemeID];
    };

    getTheme(themeID){
        return this._themeRepository[themeID];
    };

    getThemeStyleMap(themeID){
        const theme = this.getTheme(themeID);
        if(theme)
            return theme.styleMap;
        else
            return null;
    }

    getThemeInfo(themeID){
        const theme = this.getTheme(themeID);
        if(theme && !_isEmpty(theme.themeInfo))
            return theme.themeInfo;
        else
            return null;
    }

    getThemeGlobalStyle(themeID){
        const theme = this.getTheme(themeID);
        if(theme)
            return theme.globalStyle;
        else
            return null;
    }

    getThemeFontStyle(themeID){
        const theme = this.getTheme(themeID);
        if(theme)
            return theme.fontStyle;
        else
            return null;
    }

    async loadTheme(themeID){
        const theme = await ThemeLibrary.fetchTheme(themeID);
        const theme_Meta_Info = theme.themeInfo.MetaData;
        this._themeRepository[theme_Meta_Info.id] = theme;
    }
};

import jsonPatch from '@libs/fast-json-patch';
import _isEmpty from 'lodash/isEmpty';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import imageStore from '../../0-common/store/ImageStore';
import SubjectStore from '../../0-common/store/SubjectStore';
import baby from '../../0-common/utils/baby';
import storeFactory from '../../0-common/utils/StoreFactory';

const SUBJECT_TYPE = "USER";

export class ShopUserStore extends SubjectStore {
	
	@observable currentReadUser = {};
	@observable currentEditUser = {};
	@observable currentUserTypeInfo = {};
	confirmPasswordValue = null;
	@observable confirmPasswordError = false;
	@observable isLoading = false;
	@observable isProfileImageLoading = false;
	
	addressStore = null;

	constructor(){
		super();
		this.imageStore = imageStore;
	}	

	getType(){
		return SUBJECT_TYPE;
	}	
	
	@computed
	get User(){
		return this.currentReadUser;
	}

	@computed
	get UserForEdit(){
		return this.currentEditUser;
	}

	get UserTypeInfo(){
		return _isEmpty(this.currentUserTypeInfo) ? null : this.currentUserTypeInfo;		
	}

	get AddressStore(){
		return this.addressStore;
	}

	set CurrentUser(currentUser){
		this.currentReadUser = currentUser;
		this.currentEditUser = toJS(currentUser);
		this.patchObserver = jsonPatch.observe(this.currentEditUser.DATA);
		if(!this.addressStore){
			const addressStore_X_SUBJECT_TYPE = {
				"uid": currentUser.DATA.uid,
				"Type": "ADDRESS",
				"Category":"",
				"FIND_WITHIN":true,
				"PAGE_SIZE":-1,
				"FIND_WITH_FILTERS":true,
				"REQUESTED_PAGE":1,
			}
			this.addressStore = storeFactory.fetchStore("SHOPADDRESS", addressStore_X_SUBJECT_TYPE);
		}
	}

	set CurrentUserTypeInfo(currentUserTypeInfo){
		this.currentUserTypeInfo = currentUserTypeInfo;
	}

	setConfirmPassword(cPassword){
		this.confirmPasswordValue = cPassword;
	}

	@action
	reset(){
		this.currentReadUser = {};
		this.currentEditUser = {};
		this.currentUserTypeInfo = {};
		this.confirmPasswordValue = null;
		this.confirmPasswordError = false;
	}

	@action
	initializeBuyer(response){
		if(response.SUBJECT) {
			this.CurrentUser = response.SUBJECT;
			this.CurrentUserTypeInfo = response.SUBJECT_TYPE;
		}
		if(response.ORDER){
			const orderStore = storeFactory.fetchStore('PUBLICORDERSTORE', {"Type" : "ORDERS"});
			orderStore.initializeCart(response.ORDER);
		}
		return this.User;
	}

	async fetchCurrentSessionBuyer(sellerID, shopID){
		try{
			const response = await this.getBuyerBySession(sellerID, shopID);
			return this.initializeBuyer(response);
		}
		catch (error){
		    throw error;
		}
	}

	async loadCurrentBuyer(sellerID, shopID){
		try{
			this.isLoading = true;
			if(_isEmpty(this.User)){
				return await this.fetchCurrentSessionBuyer(sellerID, shopID);
			}
		}
		catch (error){
			storeFactory.ErrorStore.log(error, "Couldn't load Buyer", "On load Current Buyer Error");
			return null;
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action
	updateProperty(propertyName, propertyValue) {
		this.currentEditUser.DATA[propertyName] = propertyValue;
		console.log(this.currentEditUser.DATA);
  	}

  	checkConfirmPassword(){
  		try{
  			this.isLoading = true;	
			if(this.confirmPasswordValue){
				if(this.confirmPasswordValue !== this.currentEditUser.DATA.SECRET) {	
					throw "Your password confirmation did not match your password.";
				} else {
					this.confirmPasswordError = false;
				}
			}
			return true;
  		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, error, "On Check confirm password Error");
		    this.confirmPasswordError = true;
		    return false;
		}
		finally {
			this.isLoading = false;	
		}		
  	}


  	async saveUserProfileChanges(sellerID, shopID){
  		try{
  			this.isLoading = true;
	  		const patch = jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
				const itemInfo = {
					"Type":SUBJECT_TYPE, 
					"uid":this.currentEditUser.DATA.uid, 
					"Category" : "",
					"APP_OWNER" : sellerID,
					"ShopID" : shopID
				};	
		  		const response = await this.saveUser(itemInfo, patch);		
				runInAction("ON Save User Profile", () => {
					this.isLoading = true;
					this.CurrentUser = response.SUBJECT;
					response.onComplete();
				});
			}
  		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't save", "On Save User Profile Error");
		}
		finally {
			this.isLoading = false;	
		}
  	}

  	cancelUserProfileChanges(){
  		try{
  			this.isLoading = true;
		  	this.currentEditUser = toJS(this.currentReadUser);
			this.patchObserver = jsonPatch.observe(this.currentEditUser.DATA);
			this.confirmPasswordValue = null;
			this.confirmPasswordError = false;
  		}
  		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't revert user changes", "ON Cancel User Profile Changes");
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action
    async markAddressAsDefault(addressID, sellerID, shopID){
        try{
			this.isLoading = true;
			const patch = [
				{
                    "op":"add", 
                    "path":"/DefaultAddress", 
                    "value": addressID,
                }
			]
			const itemInfo = {
				"Type":SUBJECT_TYPE, 
				"uid":this.currentEditUser.DATA.uid, 
				"Category" : "",
				"APP_OWNER" : sellerID,
				"ShopID" : shopID
			};	
            const response = await this.markAddressAsDefaultService(itemInfo, patch);
            runInAction("ON mark Address As Default", () => {
				this.isLoading = true;
				this.CurrentUser = response.SUBJECT;
				response.onComplete();
			});
        }
        catch (error){
            storeFactory.ErrorStore.log(error, "Couldn't mark Address As Default", "On mark Address As Default Error");
        }
        finally {
            this.isLoading = false;	
        }
	}


  	//============================== Service Layer ===============================//

	async getBuyerBySession(sellerID, shopID) {
		try {
			const params = {
				'APP_OWNER': sellerID,
				"ShopID": shopID
			};
			const response = await baby.post('/getbuyer', params);
			return response.data;
		} 
		catch (error) {
			if(error.response && error.response.status === 401){
				return error.response.data;
			} else {
				console.error("getbuyer Failed with Error : "+error);
				throw error;
			}
		}
	}

  	async saveUser(itemInfo, userJsonPatch) {
		try {
			const response = await baby.post('/updateuser', {...itemInfo, "PATCH" : userJsonPatch} );
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("saveUser Failed with Error : "+error);
			throw error;
		}
	}

	async markAddressAsDefaultService(itemInfo, defaultAddressUserJsonPatch) {
		try {
			const response = await baby.post('/setaddressasdefault', { ...itemInfo, "PATCH" : defaultAddressUserJsonPatch}  );
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("addNewAddressService Failed with Error : "+error);
			throw error;
		}
	}
}

export default new ShopUserStore();
import { value } from '../../../store/LanguageStore';
import { VerticalConfig } from "../VerticalConfig";
import {languagePack} from './language-pack';

const ph_profileImageURL        =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
const ph_profileImageURL_mini   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/lightest/-/preview/250x100/'; 
const ph_logoImageURL           =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';

const ph_logoImageURL_slider1   =   'https://ucarecdn.com/17c2727f-4dda-432b-9617-f2dd7409aa37/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider2   =   'https://ucarecdn.com/c2afdd8e-3515-4bbb-af36-a1eae281c237/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider3   =   'https://ucarecdn.com/17c2727f-4dda-432b-9617-f2dd7409aa37/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider4   =   'https://ucarecdn.com/00b7ef91-85e5-446a-bb9e-d79245ddfe9b/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider5   =   'https://ucarecdn.com/c4169ff1-fd32-421a-bcaa-65031621f9ab/-/format/auto/-/quality/smart/';

class RealEstateVerticalConfig extends VerticalConfig{

    //@override
    getSliderConfig(){
        return {
            header : value('lb_app_logo_header'),
            images: [ph_logoImageURL_slider1, ph_logoImageURL_slider2, ph_logoImageURL_slider3, ph_logoImageURL_slider4, ph_logoImageURL_slider5],
            caption: `${value('lb_app_logo_slider1_caption')}  | ${value('lb_app_logo_slider3_caption')} |  ${value('lb_app_logo_slider5_caption')}`,
        }
    }

    //@override
    getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "realestatedemo",
            shop_subdomain : "realestatedemosite",
        }
    }

    //@override
    getLogoConfig(){
        return {
            profileImage : ph_profileImageURL,
            profileImage_mini : ph_profileImageURL_mini,
            logoImage : ph_logoImageURL
        }
    }

    //@override
    getInventoryConfig(){
        return {
            visible : false,
        }
    }

    //@override
    getLanguagePack(lang){
        return languagePack(lang);
    }
}

export default new RealEstateVerticalConfig();

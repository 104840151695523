import { autorun, computed, observable, runInAction } from 'mobx';
import storeFactory from '../utils/StoreFactory';
import SubscriptionConfigData from '../utils/Config-Subscription';
import _find from 'lodash/find';

/**
 * Limitation :
 * 1. Doesn't get populated for Public API's (For eg:- fetchShopWithPublicAccessService -> /publicreadshop)
 *    - for the above scenario the PublicShopStore has a getter 'OwnerSubscriptionPlan' which loads subscription related data from Shop Owner Object
 *
 */

class SubscriptionStore {
	@observable subscriptionInfoRepository = {};

	set SubscriptionInfo(subscriptionInfo) {
		this.subscriptionInfoRepository = subscriptionInfo;
	}

	@computed get SubscriptionInfo() {
		return this.subscriptionInfoRepository;
	}

	@computed get isUserSubscribed() {
		return this.subscriptionInfoRepository.USER_CATEGORY === 'Subscriber';
	}

	@computed get isUserSubscriptionPaid() {
		return this.subscriptionInfoRepository.SUBSCRIPTION_CYCLE && this.subscriptionInfoRepository.SUBSCRIPTION_CYCLE !== 'Trial';
	}

	@computed get isUserSubscriptionActive() {
		if (this.subscriptionInfoRepository.SUBSCRIPTION_STATUS) {
			return (
				this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'Active' ||
				this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'PendingCancellation' ||
				this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'PendingDowngrade'
			);
		} else {
			// No Info found in subscriptionInfoRepository, hence till info is set assume default behaviour as active.
			return true;
		}
	}

	@computed get isUserSubscriptionExpired() {
		if (this.subscriptionInfoRepository.SUBSCRIPTION_STATUS) {
			return (
				this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'Expired' ||
				this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'Cancelled'
			);
		} else {
			// No Info found in subscriptionInfoRepository, hence till info is set assume default behaviour as active.
			return false;
		}
	}

	@computed get isUserSubscriptionDowngraded() {
		if (this.subscriptionInfoRepository.SUBSCRIPTION_STATUS) {
			return this.subscriptionInfoRepository.SUBSCRIPTION_STATUS === 'PendingDowngrade';
		} else {
			// No Info found in subscriptionInfoRepository, hence till info is set assume default behaviour as active.
			return false;
		}
	}

	@computed get subscriptionEndDays() {
		return this.subscriptionInfoRepository.SUBSCRIPTION_END_DAYS;
	}

	@computed get subscriptionPlan() {
		return this.subscriptionInfoRepository.SUBSCRIPTION_PLAN;
	}

	@computed get subscriptionCycle() {
		return this.subscriptionInfoRepository.SUBSCRIPTION_CYCLE;
	}

	clearRepository() {
		this.subscriptionInfoRepository = {};
	}

	invalidSubscriptionCheckDisposer = autorun(
		() => {
			console.log('==== Subscription Store Autorun | Invalid Subscription Check Activated.....');
			if (!this.isUserSubscriptionActive) {
				console.error('Subscription Expired !!!!');
				const navigationStoreREF = storeFactory.GlobalNavigationStore;
				switch (navigationStoreREF.currentScreenName) {
					case navigationStoreREF.SCREEN.CATALOG:

					case navigationStoreREF.SCREEN.PRODUCT:

					case navigationStoreREF.SCREEN.SHOP:

					case navigationStoreREF.SCREEN.PAYMENT:

					case navigationStoreREF.SCREEN.PAGE_NOT_FOUND:

					case navigationStoreREF.SCREEN.SERVER_UNDER_MAINTENANCE:
						break;

					default:
						runInAction(' invalidSubscriptionCheckDisposer default case ', () => {
							navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SUBSCRIPTION);
						});
						break;
				}
			}
		},
		{ name: 'invalidSubscriptionCheckDisposer' }
	);

	/**
	 * Subscription Master Data
	 *
	 */
	get SubscriptionPlanList() {
		return SubscriptionConfigData.SubscriptionPlanList;
	}

	get SubscriptionFeatureList() {
		return SubscriptionConfigData.SubscriptionFeatureList;
	}

	IsFeatureAuthorizedFor(subscriptionPlan, featureId) {
		const featureInfo = _find(this.SubscriptionFeatureList, ['id', featureId]);
		if (featureInfo) {
			const feature_plan_authorization = featureInfo.data[subscriptionPlan];
			return feature_plan_authorization === 'Yes' ? true : false;
		} else {
			return false;
		}
	}
}

export default new SubscriptionStore();

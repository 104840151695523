import axios from 'axios';

try{
    const deployMode = process.env.deployMode;
    const servicePort = JSON.parse(process.env.servicePort);
    let serviceUrl = '';
    if(typeof window === "undefined"){//Case : SSR
        const serviceHost = process.env.serviceHost;
        serviceUrl = `http://${serviceHost}:${servicePort}`;
    } else {//Case : CSR
        if(deployMode === "development"){
            serviceUrl = `${window.location.protocol}//${window.location.hostname}:${servicePort}`;
        } else { //(deployMode === 'production')
            serviceUrl = `${window.location.protocol}//${window.location.hostname}` ;
        }
    }

    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = `${serviceUrl}/api/`;

    axios.interceptors.request.use(request => {
        console.log(`Server Request : URL : ${request.baseURL}${request.url}`);
        console.log(`Server Request : DATA : ${JSON.stringify(request.data)}`);
        return request
    });
    
    axios.interceptors.response.use(response => {
        console.log('Server Response Received:')
        return response
    });
}catch(error){
    console.error(error);
}

export default axios;
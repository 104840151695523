import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _join from 'lodash/join';
import _map from 'lodash/map';
import { computed, observable, runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';
import { emitter } from '../../0-common/utils/EventEmitter';


export default class ProductStore extends ItemStore {

	FACTORY_STORE_TYPE = "PRODUCTS";

	@observable productOwnerInfo = {};

	set OwnerInfo(productOwner){
		this.productOwnerInfo = productOwner;
		this.StoreFactory.CurrencyStore.registerCurrency(productOwner);
	}

	@computed
	get OwnerInfo(){
		if(this.productOwnerInfo && this.productOwnerInfo.DATA){
		let profileImage = this.productOwnerInfo.DATA.ProfileImage;
		if(profileImage && profileImage !== ""){
			const profileImageRef = this.productOwnerInfo.REFERENCE_IMAGES[profileImage];
			if(profileImageRef){
			profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/`;
			} else {
			profileImage = null;
			}
		} else {
			profileImage = null;
		}
		return {...this.productOwnerInfo.DATA, "ProfileImage" : profileImage};
		} else {
		return null;
		}
	}

	getOpenContentTabIDs(){
		return ["Details", "Photos", "Collection"];
	}

	async executeFetchProductWithPublicAccess(productId, productCategory, productRefContext) {
  		try {
  			this.isLoading = true;
	  		this.currentItem = {};
	  		const itemInfo = {
								"uid" : productId,
								"Type" : "PRODUCTS",
								"Category" : productCategory,
							};
			if(productRefContext){
				itemInfo[productRefContext.contextType] = productRefContext.contextId;
			}		
			const response = await this.fetchProductWithPublicAccessService(itemInfo);
		    runInAction("On Fetch Public Catalog Success",() => {    
				this.currentItem = response.SUBJECT;
				this.OwnerInfo = response.USER;
		        this.ModuleStore.processModuleResponseData(response.TRANSLATIONS, response.MODULES);
		        response.onComplete();
		    });
  		}
		catch (error){
	      runInAction("On Fetch Public Product Error", () => {
	        this.isLoading = false;
	        this.ErrorStore.log(error, "Couldn't perform Fetch", "On Fetch Public Product Error"); 
	      });
	    } 
	    finally {
	      this.isLoading = false; 
	    }
	}

	async executeFetchProductForShop(productId, productCategory, sellerID, shopID) {
		try {
			this.isLoading = true;
			this.currentItem = {};
			const itemInfo = {
				"uid" : productId,
				"Type" : "PRODUCTS",
				"Category" : productCategory,
				"APP_OWNER" : sellerID,
				"ShopID" : shopID
			};
		  const response = await this.fetchProductForShopService(itemInfo);
		  runInAction("On Fetch Product For Shop Success",() => {
			  this.currentItem = response.SUBJECT;
			  this.ModuleStore.processModuleResponseData(response.TRANSLATIONS, response.MODULES);
			  response.onComplete();
		  });
		}
	  catch (error){
		runInAction("On Fetch Product For Shop Error", () => {
		  this.isLoading = false;
		  this.ErrorStore.log(error, "Couldn't perform Fetch Product For Shop", "On Fetch Product For Shop Error"); 
		});
	  } 
	  finally {
		this.isLoading = false; 
	  }
  }

	closePublicItem(){
  		this.isLoading = true;
  		runInAction("ON Close Public Item", () => {
	  		this.currentItem = {};
			this.isLoading = false;
		});
  	}

  	async saveReversePredicateRelationship(patch, predicateName, eventName){
  		try{
				const itemInfo = this.NavigationStore.activeItem;	
		  		const response = await this.addorremoveforreverse(predicateName, itemInfo, patch);
  		}
  		catch (error){
		    this.ErrorStore.log(error, "Couldn't perform Reverse Relationship save", "On SaveReversePredicateRelationship Error");
		}	
		finally {
			emitter.emit(eventName+'#'+predicateName);
		}
  	}

	get ProductProfileImage(){
		let profileImage = null;
		if(this.getCurrentItem()){
			profileImage = this.currentItem.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.currentItem.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/auto/-/quality/smart/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
		}
		return profileImage;
	}

	get ProductImages(){
		let productImageList = []
		if(this.getCurrentItem()){
			productImageList = this.currentItem.DATA.ImageGallery.map( imageRef => {
				const productImageRef = this.currentItem.REFERENCE_IMAGES[imageRef];
				let profileImage = null;
				if(productImageRef){
					profileImage = `https://ucarecdn.com/${productImageRef.SourceID}/-/format/auto/-/quality/smart_retina/`;
				}
				return profileImage;
			});
		}

		return _compact(productImageList);
	}

	get ProductCategorySource(){
		let categoryName = "";
		if(this.getCurrentItem()){
			categoryName = this.currentItem.DATA.CategorySourceID;
		}
		return categoryName;
	}

	get ProductID(){
		let productID = "";
		if(this.getCurrentItem()){
			productID = this.currentItem.DATA.uid;
		}
		return productID;
	}

	get ProductName(){
		let productName = "";
		if(this.getCurrentItem()){
			productName = this.currentItem.DATA.Name;
		}
		return productName;
	}

	get ProductDescription(){
		let productDescription = "";
		if(this.getCurrentItem()){
			productDescription = this.currentItem.DATA.Description;
		}
		return productDescription;
	}

	get ProductPrice(){
		let productPrice = "";
		if(this.getCurrentItem()){
			productPrice = this.currentItem.DATA.DiscountedPrice;
		}
		return productPrice;
	}

	get IsProductInStock(){
		if(this.getCurrentItem()){
			return this.currentItem.DATA.InStock;
		}else{
			return false;
		}
	}

	getProductMetaData(shopName, shopURL, shopURLType, productPageID){
		const productURL = `${shopURL}/shop/${productPageID}/${this.ProductCategorySource}/${this.ProductID}`;
		let metaRobotData = "index,follow";

		if(shopURLType === 'Standard'){
			metaRobotData = "noindex,nofollow";
		}

		const categoryDic = this.ModuleStore.getModuleCategories("PRODUCTS");
		const categoryNameList = _compact(_map(this.currentItem.DATA.Categories, (itemCategoryID) => {
			const category = _find(categoryDic, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));

		return {
			og_title_data : `${this.ProductName} | ${_join(categoryNameList, ' | ')} | ${shopName}`,
			og_desc_data :  this.ProductDescription,
			og_image_data : `${this.ProductProfileImage}-/preview/600x800/`,
			og_site_name_data : this.ProductName,
			og_url_data : productURL,
			meta_robot_data: metaRobotData
		}
	}

	getProductSchemaJSON_LD(shopURL, productPageID){
		const productPageURL = `${shopURL}/shop/${productPageID}`;
		const productURL = `${shopURL}/shop/${productPageID}/${this.ProductCategorySource}/${this.ProductID}`;

		const categoryDic = this.ModuleStore.getModuleCategories("PRODUCTS");
		const productCategoryNameList = _compact(_map(this.currentItem.DATA.Categories, (itemCategoryID) => {
			const category = _find(categoryDic, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));

		let breadcrumbItemListElement = _map(productCategoryNameList, (productCategoryName, index) => {
			return {
				"@type": "ListItem",
				"position": (index + 1),
				"name" : productCategoryName,
				"item": productPageURL
			}
		});
		breadcrumbItemListElement.push(
			{
				"@type": "ListItem",
				"position": (breadcrumbItemListElement.length + 1),
				"name" : this.ProductName,
				"item": productURL
			}
		);

		const breadcrumbSchemaData = {
			"@context": "http://schema.org",
			"@type": "BreadcrumbList",
			"itemListElement": breadcrumbItemListElement
		};

		let productSchemeData = {
			"@context": "http://schema.org",
      		"@type": "Product",
			"name": this.ProductName,
			"image": this.ProductImages,
			"description": this.ProductDescription
		};
		if(this.ProductPrice){
			productSchemeData["offers"] = {
				"@type": "Offer",
				"url": productURL,
				"priceCurrency": "INR",
				"price": this.ProductPrice+"",
				"itemCondition": "https://schema.org/NewCondition",
				"availability": this.IsProductInStock ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
			}
		}
		
		return {breadcrumbSchemaData, productSchemeData};
	}


  	/* ==========================================  Service Layer  ==========================================  */

	async fetchProductWithPublicAccessService(itemInfo) {
	    try {
	      const serviceResponse = await baby.post('/publicreadproduct', itemInfo);
	      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
	      return response;
	    } 
	    catch (error) {
	      console.error("fetchProductWithPublicAccessService Failed with Error : "+error);
	      throw error;
	    }       
	}

	async fetchProductForShopService(itemInfo) {
	    try {
	      const serviceResponse = await baby.post('/publicreadproductinshop', itemInfo);
	      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
	      return response;
	    } 
	    catch (error) {
	      console.error("fetchProductForShopService Failed with Error : "+error);
	      throw error;
	    }       
	}

	async addorremoveforreverse(predicateName, itemInfo, patch) {
	    try {
	      const requestParam = {
	      	"Type":itemInfo.Type, 
	      	"uid":itemInfo.uid, 
	      	"Category" : itemInfo.Category,
	      	"PREDICATE_TYPE" : predicateName,
			"FIND_WITHIN": true,
			"PAGE_SIZE": -1,
			"FIND_WITH_FILTERS": true,
			"REQUESTED_PAGE": 1,
	      	"PATCH" : patch,
	      };

	      const serviceResponse = await baby.post('/addorremoveforreverse', requestParam);
	      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
	      return response;
	    } 
	    catch (error) {
	      console.error("addorremoveforreverse Failed with Error : "+error);
	      throw error;
	    }       
	}
}
const theme = {
    meta_info :{
        id: "RealEstateStandard",
        name: "RealEstate Standard Theme",
        folder_name: "realestate-standard"
    },
    root : {

        primaryFont : "Lato,sans-serif",//--primary-font
        secondaryFont : "Cormorant,serif",//--secondary-font

        primaryColor : "black",//--primary-color
        secondaryColor : "white",//--secondary-color

        primaryColorDark : "black",//--primary-color-dark
        secondaryColorDark : "white",//--secondary-color-dark

        backgroundColor : "white",//--bg-color
        
        buttonPrimaryColor : "black",
        buttonPrimaryBackgroundColor : "white",
        buttonPrimaryBorderColor : "black",
        
        buttonPrimaryHoverColor : "white",
        buttonPrimaryHoverBackgroundColor : "black",
        buttonPrimaryHoverBorderColor : "black",

        buttonSecondaryColor : "white",
        buttonSecondaryBackgroundColor : "black",
        buttonSecondaryBorderColor : "black",

        buttonSecondaryHoverColor : "black",
        buttonSecondaryHoverBackgroundColor : "white",
        buttonSecondaryHoverBorderColor : "black",

        documentPaddingTop : "2rem", //--padding-top
        documentPaddingRight : "4rem", //--padding-right
        documentPaddingBottom : "2rem", //--padding-bottom
        documentPaddingLeft : "4rem", //--padding-left
    },
    header : {
        height : '6.43rem',
        backgroundColor : 'white',
        fontSize : '0.85rem',
        primaryColor : 'black',
        secondaryColor : 'black'
    },
    footer : {
        height : 'auto',
        backgroundColor : 'black',
        fontSize : '0.85rem',
        primaryColor : '#cacaca',
        secondaryColor : 'white'
    }
}

export default {

    get RootTheme(){
        return theme.root;
    },

    get HeaderTheme(){
        return theme.header;
    },

    get FooterTheme(){
        return theme.footer;
    },

    get Theme(){
        return theme;
    },

    get MetaData(){
        return theme.meta_info;
    }
};

import { value } from '../../../store/LanguageStore';
import { VerticalConfig } from "../VerticalConfig";
import {languagePack} from './language-pack';

const ph_profileImageURL        =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';
const ph_profileImageURL_mini   =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/lightest/-/preview/250x100/'; 
const ph_logoImageURL           =   'https://ucarecdn.com/5474b8ea-4d98-4e99-b2a8-9f325b55c8b0/-/format/auto/-/quality/smart/';

const ph_logoImageURL_slider1   =   'https://ucarecdn.com/3b7ebfab-1341-467a-ae47-b95279bb6754/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider2   =   'https://ucarecdn.com/4d95b0d8-8f41-4f16-b9ce-6a2f2cdd9afb/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider3   =   'https://ucarecdn.com/3cd4d6e6-d548-4239-9966-27343dabc9b0/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider4   =   'https://ucarecdn.com/96327361-c6b8-42c3-925d-805fbc960d61/-/format/auto/-/quality/smart/';
const ph_logoImageURL_slider5   =   'https://ucarecdn.com/938cf597-6d98-43d4-aed6-6502024e1b62/-/format/auto/-/quality/smart/';

class LifestyleVerticalConfig extends VerticalConfig{

    //@override
    getSliderConfig(){
        return {
            header : value('lb_app_logo_header'),
            images: [ph_logoImageURL_slider1, ph_logoImageURL_slider2, ph_logoImageURL_slider3, ph_logoImageURL_slider4, ph_logoImageURL_slider5],
            caption: `${value('lb_app_logo_slider1_caption')} | ${value('lb_app_logo_slider2_caption')} | ${value('lb_app_logo_slider3_caption')} | ${value('lb_app_logo_slider4_caption')} | ${value('lb_app_logo_slider5_caption')}`,
        }
    }

    //@override
    /*getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "test",
            shop_subdomain : "testshop"
        }
    }*/

    getDomainConfig(){
        return {
            domain: "tridasha.in",
            subdomain : "auto",
            shop_subdomain : "autoshop"
        }
    }

    //@override
    getLogoConfig(){
        return {
            profileImage : ph_profileImageURL,
            profileImage_mini : ph_profileImageURL_mini,
            logoImage : ph_logoImageURL
        }
    }

    //@override
    getInventoryConfig(){
        return {
            visible : true,
        }
    }

    //@override
    getLanguagePack(lang){
        return languagePack(lang);
    }
}

export default new LifestyleVerticalConfig();
export class VerticalConfig {
    
    getSliderConfig(){
        throw new Error("Not Implemented");
    }

    getDomainConfig(){
        throw new Error("Not Implemented");
    }

    getLogoConfig(){
        throw new Error("Not Implemented");
    }

    getInventoryConfig(){
        throw new Error("Not Implemented");
    }

    getLanguagePack(lang){
        throw new Error("Not Implemented");
    }
}
import baby from '../utils/baby';
import storeFactory from '../utils/StoreFactory';

class AuthStore {
	async login(username, password, language) {
		try {
			const response = await baby.post('/login', {
				LOGIN: username,
				SECRET: password,
				LANGUAGE: language,
			});
			const userObj = response.data;
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't login",
				'on Login Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async register(name, username, password, mobileNumber, language) {
		try {
			const response = await baby.post('/register', {
				Name: name,
				SourceID: username,
				SECRET: password,
				MobileNumber: mobileNumber,
				LANGUAGE: language,
			});
			const userObj = response.data;
			console.log('<<<<<<< User >>>>>>');
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't register",
				'on Register Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async registerForInvite(
		name,
		username,
		password,
		mobileNumber,
		refSourceId,
		language
	) {
		try {
			const response = await baby.post('/register', {
				Name: name,
				SourceID: username,
				SECRET: password,
				MobileNumber: mobileNumber,
				INVITE_SOURCE: refSourceId,
				LANGUAGE: language,
			});
			const userObj = response.data;
			console.log('<<<<<<< User >>>>>>');
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't register with invite ",
				'on Register Invite Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async registerForBeta(name, username, mobileNumber, userUID, language) {
		try {
			const registerParam = {};
			registerParam.Name = name;
			registerParam.SourceID = username;
			registerParam.MobileNumber = mobileNumber;
			registerParam.uid = userUID;
			registerParam.SECRET = '';
			registerParam.LANGUAGE = language;

			const response = await baby.post('/register', registerParam);
			const userObj = response.data;
			console.log('<<<<<<< User >>>>>>');
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't register",
				'on Register Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async forgotPassword(username, language) {
		try {
			await baby.post('/forgotpassword', {
				LOGIN: username,
				LANGUAGE: language,
			});
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't send email",
				'on Forgot Password Error'
			);
			return false;
		}
	}

	async resetPassword(username, newPassword, language) {
		try {
			await baby.post('/resetpassword', {
				LOGIN: username,
				SECRET: newPassword,
				LANGUAGE: language,
			});
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't reset password",
				'on Reset Password Error'
			);
			return false;
		}
	}

	async logout() {
		try {
			await baby.get('/logout');
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't logout",
				'on logout Error'
			);
			return false;
		}
	}

	async verifyUserAccount(emailID, resetCode, context, language) {
		try {
			const response = await baby.post(
				'/verifyaccount?code=' + resetCode,
				{ LANGUAGE: language, LOGIN: emailID, VERIFY_CONTEXT: context }
			);
			const userObj = response.data;
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't verify account",
				'on Verify Account Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async resendCode(emailID, context) {
		try {
			await baby.post('/resendcode', {
				LOGIN: emailID,
				RESEND_CONTEXT: context,
			});
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't resend verification code",
				'on ResendCode Error'
			);
			return false;
		}
	}

	/**************************** SHOP API *************************************/

	async shopLogin(username, password, language, sellerID, shopID) {
		try {
			const params = {
				LOGIN: username,
				SECRET: password,
				LANGUAGE: language,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			const response = await baby.post('/publicshoplogin', params);
			const userObj = response.data;
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't login to shop ",
				'on Shop Login Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async shopRegister(
		name,
		username,
		password,
		mobileNumber,
		language,
		sellerID,
		shopID
	) {
		try {
			const params = {
				Name: name,
				SourceID: username,
				SECRET: password,
				MobileNumber: mobileNumber,
				LANGUAGE: language,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			const response = await baby.post('/publicshopregister', params);
			const userObj = response.data;
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't register to shop",
				'on Shop Register Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}

	async shopForgotPassword(username, language, sellerID, shopID) {
		try {
			const params = {
				LOGIN: username,
				LANGUAGE: language,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			await baby.post('/forgotpassword', params);
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't send email",
				'on Shop Forgot Password Error'
			);
			return false;
		}
	}

	async shopResetPassword(username, newPassword, language, sellerID, shopID) {
		try {
			const params = {
				LOGIN: username,
				SECRET: newPassword,
				LANGUAGE: language,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			await baby.post('/resetpassword', params);
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't reset password for shop",
				'on Shop Reset Password Error'
			);
			return false;
		}
	}

	async shopLogout(sellerID, shopID) {
		try {
			const params = {
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			await baby.post('/logout', params);
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't logout from shop ",
				'on shop logout Error'
			);
			return false;
		}
	}

	async shopVerifyUserAccount(
		emailID,
		resetCode,
		context,
		language,
		sellerID,
		shopID
	) {
		try {
			const params = {
				LANGUAGE: language,
				LOGIN: emailID,
				VERIFY_CONTEXT: context,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			await baby.post('/verifyaccount?code=' + resetCode, params);
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't verify account",
				'on shop Verify Account Error'
			);
			return false;
		}
	}

	async shopResendCode(emailID, context, sellerID, shopID) {
		try {
			const params = {
				LOGIN: emailID,
				RESEND_CONTEXT: context,
				APP_OWNER: sellerID,
				ShopID: shopID,
			};
			await baby.post('/resendcode', params);
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't resend verification code",
				'on shopResendCode Error'
			);
			return false;
		}
	}

	/**************************** CATALOG API *************************************/

	async catalogLogin(username, password, language, catalogOwner) {
		try {
			const params = {
				LOGIN: username,
				SECRET: password,
				LANGUAGE: language,
			};
			if (catalogOwner) {
				params['APP_OWNER'] = catalogOwner;
			}
			const response = await baby.post('/login', params);
			const userObj = response.data;
			console.log(userObj);
			return {
				status: true,
				user: userObj,
			};
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't login to catalog ",
				'on Catalog Login Error'
			);
			return {
				status: false,
				error: error.response ? error.response.data : error,
				user: null,
			};
		}
	}
}

export default new AuthStore();

import jsonPatch from '@libs/fast-json-patch';
import _assign from 'lodash/assign';
import _defaults from 'lodash/defaults';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import _valuesIn from 'lodash/valuesIn';
import _keyBy from 'lodash/keyBy';
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx';
import { emitter, EVENTS } from '../utils/EventEmitter';
import FilterStore from './FilterStore';
import imageStore from './ImageStore';
import SearchStore from './SearchStore';
import SubjectStore from './SubjectStore';


const PER_PAGE_LIMIT = 12;
export default class ItemStore extends SubjectStore {

	storeFactoryRef = null;

	constructor(subjectInfo){
		super();
		this.SUBJECT_INFO = subjectInfo;
		this.filterStore  = new FilterStore(subjectInfo);
		this.searchStore  = new SearchStore(subjectInfo);
		this.imageStore   = imageStore;
	}

	@observable currentItem = {};
	@observable totalItemCount = observable.box("0");
	@observable itemError = {"errorMsg" : ""};

	//UI Status 
	@observable isLoading = false;
	@observable isProfileImageLoading = false;
	@observable isReferenceOptionLoading = false;
	@observable dirtyStatus = {"isDirty" : false, "confirmDiscard" : false, "pendingAction" : null};
	@observable createStatus = {"isActive" : false};
	@observable deleteStatus = {"confirmDelete" : false, "pendingAction" : null};
	@observable predicateActivePage = 1;
	@observable limitReachedError = false;
	pureFindCall = true;

	//Secondary Action status
	@observable secondaryActionStatus = {"isAvailable" : false};
	@observable renderActionStatus = {};
	
	@computed get ItemList() {
		return super.Subjects; 	
	}

	@computed get totalPagesCount() {
		const totalPages = Math.ceil(this.totalItemCount / PER_PAGE_LIMIT);
		//return  (totalPages === 0 ? 1 : totalPages);
		return totalPages;
	}

	@computed get selectedCount(){
		const selectedItems = _filter(this.ItemList, 'selected');
		return selectedItems ? selectedItems.length : 0;
	}

	get SubjectInfo(){
		return this.SUBJECT_INFO;
	}

	set StoreFactory(storeFactoryRef){
		this.storeFactoryRef = storeFactoryRef;
	}

	get StoreFactory(){
		return this.storeFactoryRef;
	}

	get NavigationStore(){
		return this.storeFactoryRef.NavigationStore;
	}

	get ModuleStore(){
		return this.storeFactoryRef.ModuleStore;
	}

	get ErrorStore(){
		return this.storeFactoryRef.ErrorStore;
	}

	getRenderActionStatus(tabId){
		//return  _isUndefined(this.renderActionStatus[tabId]) ? false : this.renderActionStatus[tabId];
		return true;
	}

	toggleRenderActionStatus(tabId){
	    //this.renderActionStatus[tabId] = _isUndefined(this.renderActionStatus[tabId]) ? true : !this.renderActionStatus[tabId];
	}

	@action
	resetRenderActionStatus(){
	    _map(this.renderActionStatus, (value, key) => {
	      this.renderActionStatus[key] = false;
	    });
	}

	getCurrentItem() {
		return this.currentItem.DATA ? this.currentItem : null;		
	}

	getCurrentItemTypeInfo() {
		const typeId = this.currentItem ? (this.currentItem.DATA ? this.currentItem.DATA.Type : null) : null;
		return typeId ? super.getSubjectTypeById(typeId) : null;		
	}

	getItemById(pid) {
		return super.getSubjectById(pid);
	}

	getItemTypeInfoById(typePID) {
		return super.getSubjectTypeById(typePID);
	}

	getSelectedItems(){
		let ids = [];
		let refs = {};
		let imgRefs = [];
		try{
			_forEach( _filter(this.ItemList, 'selected'), (item) => {
				ids.push(item.DATA.uid);
				refs[item.DATA.uid] = {
					"uid":item.DATA.uid,
		            "Name":item.DATA.Name,
		            "Description":item.DATA.Description,
		            "Owner":item.DATA.Owner,
		            "SourceID":item.DATA.SourceID,
		            "ProfileImage" : item.DATA.ProfileImage,
		            "Type" : item.DATA.Type,
		            "Predicate" : item.PREDICATE
				};
				if(item.REFERENCE_IMAGES)
					imgRefs[item.DATA.ProfileImage] = item.REFERENCE_IMAGES[item.DATA.ProfileImage];
			});
		}
		catch (error){
			console.error("On getSelectedItems Error");
    		console.log(error);
		}
		finally {
			return {"selectdItem":ids, "selectedItemsRef":refs, "selectedImageRef":imgRefs};
		}
	}

	getSelectedItemObj(){
		return _map(_filter(this.ItemList, 'selected'), "DATA");
	}

	_getPatch(){
		return jsonPatch.generate_new(this.patchObserver);

	}

	resetSecActionStatus(){
		this.secondaryActionStatus = {"isAvailable" : false};
	}

	//============================== Action Layer ===============================//
	@action
	executeAction(actionName) {
		console.log("Action Triggeres : "+actionName);
		switch(actionName) {
			case EVENTS.ACTION.SELECTALL : {
				//this.toggleActionButtonList("Select *", "Deselect *");
				this.toggleAllSelectionTo(true);
				break; 
			}
			case EVENTS.ACTION.DESELECTALL : {
				//this.toggleActionButtonList("Deselect *", "Select *");
				this.toggleAllSelectionTo(false);
				this.processSecondaryActionStatus();
				break;
			}
		}
	}
	
	@action
	executeItemAction(mode, itemId, itemType, itemCategory, itemModule){
		console.log("Action : "+mode+" >> "+itemId);
		switch(mode) {
			case EVENTS.ITEM.OPEN : {
				this.NavigationStore.setActvieItem(itemId, itemType, itemCategory);
				this.NavigationStore.changeView(this.NavigationStore.VIEW.OPEN);
				if(itemModule)
					this.NavigationStore.changeModule(itemModule);
				//this.openItem();
				break;	
			}
			case EVENTS.ITEM.SELECT : {
				this.toggleItemSelection(itemId);
				this.processSecondaryActionStatus();
				break;
			}
			case EVENTS.ITEM.DELETE : {
				console.error("Delete pending..... "+itemId);
				this.deleteItem(itemId, itemType);
				break;
			}
		}	
	}

	@action
	executePagination(activePage){
		this.loadItems(activePage);
	}

	@action
	updateProperty(propertyName, propertyValue) {
		this.currentItem.DATA[propertyName] = propertyValue;
  	}

	@action
  	updateCardPredicateProperty(propertyName, propertyValues, mode){
  		/*if(_hasIn(this.currentItem.DATA, propertyName)) {
			_forEach(propertyValues, (value) => {
				this.currentItem.DATA[propertyName].push(value);
			});
  		} else 
			this.currentItem.DATA[propertyName] = propertyValues;*/
		let patch = [];
		_forEach(propertyValues, (value) => {
			const patchEntry = {op : "add", path : "/" + propertyName, value : value };
			patch.push(patchEntry);
		});

		if(mode && mode === "REVERSE")
			this.saveReversePredicateRelationship(patch, propertyName, EVENTS.PREDICATE.ADDITEM.DONE);	
		else 
			this.savePredicateRelationship(patch, propertyName, EVENTS.PREDICATE.ADDITEM.DONE);	
  	}

  	@action
  	removeFromCardPredicateProperty(propertyName, propertyValues, mode){
		//const index = _indexOf(this.currentItem.DATA[propertyName], propertyValue);
		//this.currentItem.DATA[propertyName][index] = null;
		const patch =_map(propertyValues, (propertyValue) => {
			let predicateName = ""
			let predicateValue = null;
			if(_isObject(propertyValue)){
				predicateName = propertyValue.Predicate;
				predicateValue = propertyValue.uid;
			} else {
				predicateName = propertyName;
				predicateValue = propertyValue;
			}
			return {op : "remove", path : "/" + predicateName, value : predicateValue };		
		});
		
		if(mode && mode === "REVERSE")
			this.saveReversePredicateRelationship(patch, propertyName, EVENTS.PREDICATE.REMOVEITEM.DONE);
		else 
			this.savePredicateRelationship(patch, propertyName, EVENTS.PREDICATE.REMOVEITEM.DONE);
  	}

	@action
  	updateImagePredicateProperty(propertyName, propertyValues){
		_forEach(propertyValues, (value) => {
			const predicateValue = this.currentItem.DATA[propertyName];
			if(_isArray(predicateValue))
				this.currentItem.DATA[propertyName].push(value);
			else
				this.currentItem.DATA[propertyName] = value;
		});
  	}

	@action
  	updateReferenceData(selectedItemsRef){
  		_defaults(this.currentItem.REFERENCES, selectedItemsRef);
  	}

  	@action
  	updateImageReferenceData(selectedImages){
  		const selectedItemsRef = _keyBy(selectedImages, 'uid');
  		_defaults(this.currentItem.REFERENCE_IMAGES, selectedItemsRef);
  	}

  	@action
  	updateImageReferenceDataForCardPredicate(selectedImageRef){
  		_defaults(this.currentItem.REFERENCE_IMAGES, selectedImageRef);
	}
	  
	@action
	addNewCharacteristicOption(characteristicName, optionValue){
		const newOptionData = {
			key : optionValue,
			text : optionValue,
			value : optionValue
		}
		this.currentItem.OPTIONS[characteristicName].push(newOptionData);
	}

  	@action.bound
	runDirtyCheckParser(){
		if(this.patchObserver){
			const patch = jsonPatch.doDirtyCheck(this.patchObserver);
			if(patch && patch.length > 0){
				this.dirtyStatus.isDirty = true;
				//console.log("Dirty Marked !!!!")
				//console.log(patch);
			}else{
				this.dirtyStatus.isDirty = false;
				//console.log("Nothing to SAVE --------");
			}
		}
	}

	toggleItemSelection(itemId){
		const itemToBeMarked = super.getSubjectById(itemId);
		itemToBeMarked.selected = !itemToBeMarked.selected;
  	}

  	toggleAllSelectionTo(isSelected){
  		super.Subjects.map((item) => {
			item.selected = isSelected;
  		})
  	}

  	processSecondaryActionStatus(){
  		const itemFound = _find(this.ItemList, 'selected');
  		if(itemFound)
  			this.secondaryActionStatus.isAvailable = true;
  		else
  			this.secondaryActionStatus.isAvailable = false;
	}
	  



	//==================================================== Service Layer =====================================================//



		/******************************************************* IMAGE Handlers START ***********************************************************/

		/*onImageCreateSuccess = (saveImageResponseList) => {
			this.saveImageResponseList = saveImageResponseList;
			this.saveImagesInGallery_callback();
		}

		onImageCreateFailure = (saveImageError, imageRef) => {
			this.isProfileImageLoading = false;
			this.ErrorStore.log(saveImageError, "Couldn't create image", "On Add Image Error");	
		}
		
		async saveImagesInGallery_callback(){
			console.log(" >saveImagesInGallery")
			try{
				const patch = _map(this.saveImageResponseList, (saveResponse) => {
					return {op : "add", path : "/ImageGallery", value : saveResponse.DATA.uid };
				});
				const itemInfo = this.NavigationStore.activeItem;	
					const saveItemResponse = await super.saveSubject(itemInfo, patch)
					this.saveItem_Success(saveItemResponse);
				}
			catch (error){
				this.ErrorStore.log(error, "saveImagesInGallery >> Couldn't perform save after Add Image ", "On Save Item with Image Error");
			}
			finally {
				this.isProfileImageLoading = false;
				this.saveImageResponseList = null	//Reset the stale response from previous operation.
			}
		}
	
		async _addImageToStore(imageList){
			try{
				this.imageStore.createImage(imageList, this.onImageCreateSuccess, this.onImageCreateFailure);
			}
			catch (error){
				this.isLoading = false;
				this.ErrorStore.log(error, "Couldn't add image to store", "On Add Image To Store Error");
			}
			finally {
				this.isLoading = false;	
			}
		}

		async _deleteImageFromStore(imageId){
			try{
				this.imageStore.deleteImage(imageId);
			}
			catch (error){
				this.isLoading = false;
				this.ErrorStore.log(error, "Couldn't delete image from store", "On Delete Image From Store Error");
			}
			finally {
				this.isLoading = false;	
			}
		}*/

		async addImagesInGallery(imageList){
			console.log(" >addImagesInGallery")
			try{
				this.isProfileImageLoading = true;
				const itemInfo = this.NavigationStore.activeItem;
				const response = await super.addImagesInGallery_Subject(itemInfo, imageList);
				this.saveItem_Success(response);
				
				//this._addImageToStore(imageList);
				//A callback is attached in '_addImageToStore' method which eventually call 'saveImagesInGallery';
			}
			catch (error){
				this.ErrorStore.log(error, "addImagesInGallery >> Couldn't perform Add Image to Gallery", "On Add Image to gallery error");
			}
			finally{
				this.isProfileImageLoading = false;
			}
		}

		async removeImageInGallery(imageId){
			console.log(" >removeImageInGallery")
			try{
				this.isProfileImageLoading = true;	
				const itemInfo = this.NavigationStore.activeItem;	
				const response = await super.removeImageInGallery_Subject(itemInfo, imageId);
				this.saveItem_Success(response);
			}
			catch (error){
				this.ErrorStore.log(error, "removeImageInGallery >> Couldn't perform remove Image in gallery ", "On Remove Image In Gallery Error");
			}
			finally{
				this.isProfileImageLoading = false;
			}
		}

		async editImageInGallery(imageInfo, imageID){
			console.log(" >editImageInGallery")
			try{
				this.isProfileImageLoading = true;
				await this.imageStore.editImage(imageInfo, imageID);
				const itemInfo = this.NavigationStore.activeItem;		
				const updatedItemResponse = await super.getSubjectByType(itemInfo);
				this.saveItem_Success(updatedItemResponse);
			}
			catch (error){
				this.isProfileImageLoading = false;
				this.ErrorStore.log(error, "Couldn't edit image in gallery ", "On Edit Image In Gallery Error");
			}
		}

		async addProfileImage(imageInfo){
			console.log(" >addProfileImage")
			try{
				this.isProfileImageLoading = true;
				const itemInfo = this.NavigationStore.activeItem;
				const response = await super.addProfileImage_Subject(itemInfo, imageInfo);
				this.saveItem_Success(response);
			}
			catch (error){
				this.ErrorStore.log(error, "Couldn't add profile image", "On Add Profile Image Error");
			}
			finally{
				this.isProfileImageLoading = false;
			}
		}

		async removeProfileImage(){
			console.log(" >removeProfileImage")
			try{
				this.isProfileImageLoading = true;
				const itemInfo = this.NavigationStore.activeItem;
				const response = await super.removeProfileImage_Subject(itemInfo);
				this.saveItem_Success(response);
			}
			catch (error){
				this.ErrorStore.log(error, "Couldn't remove profile image", "On remove Profile Image Error");
			}
			finally{
				this.isProfileImageLoading = false;
			}
		}

		/******************************************************* IMAGE Handlers END ***********************************************************/

	
  	async createItem(itemCreateInfo){
  		try{
  			this.isLoading = true;
  			const createData = {...itemCreateInfo};
	  		const response = await super.createSubjectByType(createData);		
			response.onComplete();
			return response.SUBJECT;
			//this.NavigationStore.changeModule(response.SUBJECT.DATA.Type);
			//emitter.emit(EVENTS.ITEM.OPEN, EVENTS.ITEM.OPEN, uid, response.SUBJECT.DATA.Type, response.SUBJECT.DATA.Category);

  		}
  		catch (error){
  			this.isLoading = false;
		    //this.ErrorStore.log(error, "Couldn't create", "On Create Item Error");
		    throw error;
		}
		finally {
			this.isLoading = false;	
		}
  	}

	async loadItems(currentPage = 1) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
			this.filterStore.isLoading = true;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
			const sortOption = this.filterStore.ActiveSortOption;
		    if(filter || searchString || sortOption)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await super.loadSubjectsByType(this.SUBJECT_INFO, currentPage, filter, searchString, sortOption);
		    runInAction("On Load Item", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
				//if(!filter)
				const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
				this.filterStore.loadSortOptions(response.SUBJECT_TYPE);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
			this.filterStore.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch", "On Load All Item Error");
		}
		finally {
			this.isLoading = false;	
			this.filterStore.isLoading = false;
		}
  	}

  	async loadItemsWithReverse(currentPage = 1) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
		    if(filter || searchString)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await super.loadSubjectsByTypeWithReverse(this.SUBJECT_INFO, currentPage, filter, searchString);
		    runInAction("On Load Item With Reverse", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	/*const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);*/
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch", "On Load All Item With Reverse Error");
		}
		finally {
			this.isLoading = false;	
		}
  	}

  	async loadProspectWithReverse(currentPage = 1) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
		    if(filter || searchString)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await super.loadProspectByTypeWithReverse(this.SUBJECT_INFO, currentPage, filter, searchString);
		    runInAction("On Load Prospect With Reverse", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't fetch", "On Load All Item With Reverse Error");
		}
		finally {
			this.isLoading = false;	
		}
  	}

  	async openItem() {
  		try {
  			this.isLoading = true;
	  		this.currentItem = {};
	  		const itemInfo = this.NavigationStore.activeItem;		
			const response = await super.getSubjectByType(itemInfo);
			runInAction("On Open Item",() => {		
				this.currentItem = toJS(response.SUBJECT);
				this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
				response.onComplete();
			});
  		}
		catch (error){
			runInAction("On Open Item Error", () => {
				if(!this.ErrorStore.is401Error(error)){
					this.NavigationStore.resetActvieItem();
					this.NavigationStore.changeView(this.NavigationStore.VIEW.HOME);
				}
	  			this.isLoading = false;
	  			this.ErrorStore.log(error, "Couldn't perform open", "On Open Item Error");		
		    });
		}
		finally {
			this.isLoading = false;	
		}
	}

  	async saveItem(){
  		try{
  			this.isLoading = true;
	  		const patch =  jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
				const itemInfo = this.NavigationStore.activeItem;	
		  		const response = await super.saveSubject(itemInfo, patch);		
				this.saveItem_Success(response);
			}
  		}
  		catch (error){
  			this.isLoading = false;	
		    this.ErrorStore.log(error, "Couldn't perform save", "On Save Item Error");	
		}	
		finally {
			//_delay( () => this.isLoading = false, 1000);
			this.isLoading = false;
			this.dirtyStatus.isDirty = false;	
		}
  	}

	@action
  	saveItem_Success(response){
		if(response.SUBJECT) {
			this.currentItem = toJS(response.SUBJECT);
			this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
		}
		response.onComplete();
  	}

  	async savePredicateRelationship(patch, predicateName, eventName){
  		try{
				const itemInfo = this.NavigationStore.activeItem;	
		  		const response = await super.saveSubject(itemInfo, patch);
  		}
  		catch (error){
		    this.ErrorStore.log(error, "Couldn't perform Relationship save", "On SavePredicateRelationship Error");
		}	
		finally {
			emitter.emit(eventName+'#'+predicateName);
		}
  	}

  	closeItem(){
  		this.isLoading = true;
  		runInAction("ON Save Item Cancel", () => {
			this.NavigationStore.resetActvieItem();
	  		jsonPatch.unobserve(this.currentItem.DATA, this.patchObserver);
	  		this.currentItem = {};
	  		this.NavigationStore.changeView(this.NavigationStore.VIEW.HOME);
	  		this.dirtyStatus.isDirty = false;
			this.isLoading = false;
		});
  	}

  	async deleteItem(itemId, itemType){
  		try{
  			this.isLoading = true;
	  		const itemInfo = {"Type" : itemType, "uid" : itemId};
	  		const response = await super.deleteSubject(itemInfo);
			runInAction("ON Delete Item", () => {
				response.onComplete();
			});	
  		}
  		catch (error){
  			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't perform delete", "On Delete Item Error");	
		}	
		finally {
			this.isLoading = false;	
		}
  	}

  	async bulkDeleteItem(itemType, itemIds){
  		try{
  			this.isLoading = true;
	  		const itemInfo = {"Type" : itemType, "SUBJECTS" : itemIds};
	  		const response = await super.bulkDeleteSubject(itemInfo);
			runInAction("ON Delete Item", () => {
				response.onComplete();
			});	
  		}
  		catch (error){
  			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't perform delete", "On Delete Item Error");	
		}	
		finally {
			this.isLoading = false;	
		}
  	}

  	async discardChangesToCurrentItem(){
  		try{
  			//this.isLoading = false;	
  			const discardItemUID = this.currentItem.DATA.uid;
  			const originalItem = this.getItemById(discardItemUID);
  			jsonPatch.unobserve(this.currentItem.DATA, this.patchObserver);
			this.currentItem = toJS(originalItem);
			this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
			this.dirtyStatus.isDirty = false;		
  		}
  		catch (error){
  			this.isLoading = false;	
		    this.ErrorStore.log(error, "Couldn't perform save", "On Save Item Error");	
		}	
		finally {
			this.isLoading = false;	
		}
  	}

  	async loadPrefetchedItems(items) {
		try {
			this.isLoading = true; // should be outside action/transaction to trigger UI;
	    	this.totalItemCount.set(items.length);
	    	super.loadRepository(null, items);
		}
		catch (error){
			this.isLoading = false;
		    this.ErrorStore.log(error, "Couldn't load fetched Item", "On load Prefetched Items Error");
		}
		finally {
			this.isLoading = false;	
		}
  	}

  	async clone(itemIds){
  		try{
  			this.isLoading = true;
	  		const cloneInfo = {"PRODUCTS_TO_CLONE" : itemIds};
	  		const response = await super.bulkCloneSubject(cloneInfo);
			this.loadItems();
  		}
  		catch (error){
			if(this.checkLimitReachedError(error)){
				runInAction("Clone > On Clone Error !! Limit Reached !!",() => {
					this.limitReachedError = true;
					this.isLoading = false;
					//this.ErrorStore.log(error, "Couldn't perform Clone - Limit Reached", "On clone");		
				});		
			} else{
				this.isLoading = false;
		   		this.ErrorStore.log(error, "Couldn't perform clone", "On clone Item Error");	
			}
		}	
		finally {
			this.isLoading = false;	
		}
	}

	checkLimitReachedError(error){
		if(error.response) {
			if(error.response.data){
				const errorData = error.response.data;
				if(errorData.EXCEPTION){
					if(errorData.EXCEPTION.ClassName === "LimitReachedException"){
						return true;
					}
				}
			}
		}
		return false;
	}
	
	async changeCategory(categoryChangePatchData){
		try{
			this.isLoading = true;
			const patch =  categoryChangePatchData;
			const itemInfo = this.NavigationStore.activeItem;	
			const response = await super.changeCategory(itemInfo, patch);		
			this.saveItem_Success(response);
		}
		catch (error){
			this.isLoading = false;		
			this.ErrorStore.log(error, "Couldn't perform change category", "On change category Error");	
		}	
		finally {
			this.isLoading = false;	
		}
	}

	async executeWorkflowAction(actionName, objectID, patch){
		try{
			this.isLoading = true;
			const objectType = this.SUBJECT_INFO.Type ? this.SUBJECT_INFO.Type : this.SUBJECT_INFO;
			const params = {
				"Type": objectType,
				"ObjectID": objectID,
                "Action": actionName,
				"PATCH" : patch
			};
			const response = await super.takeWorkflowAction(params);
			this.saveItem_Success(response);
		}
		catch (error){
			this.isLoading = false;	
		  	this.ErrorStore.log(error, "Couldn't execute workflow action", "On Execute Workflow Action");	
	  }	
	  finally {
		  //_delay( () => this.isLoading = false, 1000);
		  this.isLoading = false;
	  }
	}

	async executeWorkflowActionAndFind(actionName, objectID, currentPage = 1, patch){
		try{
			this.isLoading = true;
			const filter = this.filterStore.getFilterQuery();
		    const searchString = this.searchStore.query;
			const sortOption = this.filterStore.ActiveSortOption;
		    if(filter || searchString || sortOption)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
			const response = await super.takeWorkflowActionAndFind(actionName, objectID, this.SUBJECT_INFO, currentPage, filter, searchString, sortOption, patch);
			runInAction("On executeWorkflowActionAndFind", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
				this.filterStore.loadSortOptions(response.SUBJECT_TYPE);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;	
		  	this.ErrorStore.log(error, "Couldn't execute workflow action and find", "On Execute Workflow Action And Find");	
	  }	
	  finally {
		  //_delay( () => this.isLoading = false, 1000);
		  this.isLoading = false;
	  }
	}

	@action
	async executeGetReferenceOptions(characteristicPredicateIDs){
		try{
			this.isReferenceOptionLoading = true;
			const requestParams = {
				'CHARACTERISTIC_PREDICATE_TYPES' : characteristicPredicateIDs
			}
			const response = await super.getReferenceOptions(requestParams);
			runInAction("On executeGetReferenceOptions", () => {
				if(response.OPTIONS){
					_assign(this.currentItem.OPTIONS, response.OPTIONS);
				}
				this.isReferenceOptionLoading = false;	
			});
		}catch (error){
			this.isReferenceOptionLoading = false;	
			this.ErrorStore.log(error, "Couldn't execute GetReferenceOptions", "On Execute GetReferenceOptions");
		}	
		finally {
			this.isReferenceOptionLoading = false;
		}
	}

	//@override
	@action
	clearRepository() {
		this.currentItem = {}
		super.clearRepository();
	}

  	dirtyCheckDisposer = autorun(() => {
		//console.log("==== Item Store Autorun | DirtyCheck Activated.....");	
        _valuesIn(this.currentItem.DATA);
        if(this.currentItem.DATA)
        	_valuesIn(this.currentItem.DATA.ImageGallery);
        emitter.emit(EVENTS.DIRTYCHECK);
    },{ name: "dirtyCheckDisposer" });

}
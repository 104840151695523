import _isEmpty from 'lodash/isEmpty';
import { runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';

export default class ShopCollectionStore extends ItemStore{

	FACTORY_STORE_TYPE = "SHOPCOLLECTIONS";

	constructor(subjectInfo){
		super(subjectInfo);
	}

	//@Override
    async loadItems(currentPage = 1) {
		try {
			this.isLoading = true; 
			this.filterStore.isLoading = true;
		    const filter = this.filterStore.getFilterQuery();
		    const searchString = null;
			//const shopStore = this.StoreFactory.fetchStore('PUBLICWEBSHOPS');
			//const searchString = shopStore.searchStore.query; // override block
			const sortOption = this.filterStore.ActiveSortOption;
		    if(filter || searchString || sortOption)
		    	this.pureFindCall = false;
		    else
		    	this.pureFindCall = true;
		    
		    const response = await super.loadSubjectsByType(this.SUBJECT_INFO, currentPage, filter, searchString, sortOption);
		    runInAction("On Load Item", () => {
		    	this.totalItemCount.set(response.TOTAL_ELEMENT_COUNT);
		    	//if(!filter)
		    	const filterList = [];
				if(!_isEmpty(response.CATEGORIES)){
					filterList.push(response.CATEGORIES);
				}
		    	filterList.push(...response.FILTERS);
		    	this.filterStore.loadFilters(filterList);
				this.filterStore.loadSortOptions(response.SUBJECT_TYPE);
		    	response.onComplete();
		    });
		}
		catch (error){
			this.isLoading = false;
			this.filterStore.isLoading = false;
		    this.ErrorStore.log(error, "ShopCollectionStore > Couldn't fetch", "ShopCollectionStore > On Load All Item Error");
		}
		finally {
			this.isLoading = false;	
			this.filterStore.isLoading = false;
		}
	}
	
	/* ==========================================  Service Layer  ==========================================  */
	

	//@Override
	async getSubjectsService(subjectInfo, page, filter, searchString, sortOption) {
		try {
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
			if(searchString){
				param = {...param, "SEARCHSTRING": searchString, "OR" : true};
			}
			if(sortOption){
				param = {...param, "ORDER": sortOption.value, "IS_ASC" : (sortOption.sortMode === "asc")};
			}
			const response = await baby.post('/publicfindinshop', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getSubject's Service Failed with Error : "+error);
			throw error;
		}				
	}
}
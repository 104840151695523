import jsonPatch from '@libs/fast-json-patch';
import _find from 'lodash/find';
import { action, computed, observable, runInAction, toJS } from "mobx";
import ItemStore from "../../0-common/store/ItemStore";
import baby from '../../0-common/utils/baby';

const SUBJECT_TYPE = "ADDRESS";

export default class AppAddressStore extends ItemStore {

    FACTORY_STORE_TYPE = "APPADDRESS";

    @observable newAddress = {};
    @observable currentEditAddress = {};

    constructor(subjectInfo){
		super(subjectInfo);
    }
    
	@computed
	get AddressForEdit(){
		return this.currentEditAddress;
    }
    
    @action
    setAddressForEdit(addressID){
        const addressToBeEdited = _find(this.ItemList, {"DATA":{"uid":addressID}} );
        if(addressToBeEdited){
            this.currentEditAddress = toJS(addressToBeEdited);
            this.patchObserver = jsonPatch.observe(this.currentEditAddress.DATA);
        }
	}

    initializeNewAddress(){
        this.newAddress.DATA = {};
        this.patchObserver = jsonPatch.observe(this.newAddress.DATA);
    }

    @action
	updateNewAddressProperty(propertyName, propertyValue) {
		this.newAddress.DATA[propertyName] = propertyValue;
    }

    @action
    editAddressProperty(propertyName, propertyValue) {
		this.currentEditAddress.DATA[propertyName] = propertyValue;
    }
    
    @action
    async addNewAddress(){
        try{
            this.isLoading = true;
            const patch =  jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
                const ownerUserID = this.SUBJECT_INFO.uid;
                const addressOwnerPatch = {
                    "op":"add", 
                    "path":"/Addresses", 
                    "value": ownerUserID
                };
                patch.push(addressOwnerPatch);
				const itemInfo = {
                    "Name" : this.newAddress.DATA.Name,
                    "Type" : "ADDRESS"
                };
                const response = await this.addNewAddressService(itemInfo, patch);
                runInAction("ON Add New Address", () => {
                    jsonPatch.unobserve(this.newAddress.DATA, this.patchObserver);
                    this.newAddress = {};
                    this.dirtyStatus.isDirty = false;
					response.onComplete();
                });
                return response.SUBJECT.DATA.uid;
			}
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    @action
    async updateAddress(){
        try{
            this.isLoading = true;
            const patch =  jsonPatch.generate_new(this.patchObserver);
			if(patch.length > 0) {
				const itemInfo = {
                    "Type" : "ADDRESS",
                    "uid" : this.currentEditAddress.DATA.uid
                };
                const response = await this.updateAddressService(itemInfo, patch);
                runInAction("ON Update Address", () => {
                    jsonPatch.unobserve(this.currentEditAddress.DATA, this.patchObserver);
                    this.currentEditAddress = {};
                    this.dirtyStatus.isDirty = false;
					response.onComplete();
				});
			}
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    @action
    cancelAddressChanges(mode){
        try{
            this.isLoading = true;
            if(mode === "EDIT"){
                jsonPatch.unobserve(this.currentEditAddress.DATA, this.patchObserver);
                this.currentEditAddress = {};
            } else {
                jsonPatch.unobserve(this.newAddress.DATA, this.patchObserver);
                this.newAddress = {};
            }
        }
        catch (error){
          this.ErrorStore.log(error, "Couldn't cancelAddressChanges", "ON cancelAddressChanges");
      }
      finally {
          this.isLoading = false;	
      }
  }

    @action
    async removeAddress(addressID){
        try{
            this.isLoading = true;
            const itemInfo = {
                "Type" : "ADDRESS",
                "uid" : addressID
            };
            const response = await this.removeAddressService(itemInfo);
            runInAction("ON Remove Address", () => {
                response.onComplete();
            });
        }
        catch (error){
            this.ErrorStore.log(error, "Couldn't add NewAddress", "On Add NewAddress Error");
        }
        finally {
            this.isLoading = false;	
        }
    }

    //============================== Service Layer ===============================//

    async addNewAddressService(itemInfo, newAddressJsonPatch) {
		try {
			const response = await baby.post('/createaddress', {"Type":itemInfo.Type, "Name":itemInfo.Name, "Category" : itemInfo.Category, "PATCH" : newAddressJsonPatch} );
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("addNewAddressService Failed with Error : "+error);
			throw error;
		}
    }

    async updateAddressService(itemInfo, updateAddressJsonPatch) {
		try {
			const response = await baby.post('/updateaddress', {"Type":itemInfo.Type, "uid":itemInfo.uid, "Category" : itemInfo.Category, "PATCH" : updateAddressJsonPatch} );
			return this.processResponseDataForCreateUpdateRead(response.data);
		} 
		catch (error) {
			console.error("updateAddressService Failed with Error : "+error);
			throw error;
		}
    }

    async removeAddressService(itemInfo) {
		try {
			const response = await baby.post('/deleteaddress', itemInfo );
			return this.processResponseDataForDelete(response.data, itemInfo);
		} 
		catch (error) {
			console.error("removeAddressService Failed with Error : "+error);
			throw error;
		}
    }
    
    //@Override
    async getSubjectsService(subjectInfo, page, filter, searchString) {
		try {
			let param = {...subjectInfo, "FIND_WITH_FILTERS": true};
			if(filter)
				param = {...param, "FILTER": filter};
			if(page)
				param = {...param, "REQUESTED_PAGE": page};
            if(searchString){
                param = {...param, "SEARCHSTRING": searchString, "OR" : true};
            }
                
			const response = await baby.post('/getaddresses', param);
			
			return response.data;
		} 
		catch (error) {
			console.error("getSubject's Service Failed with Error : "+error);
			throw error;
		}				
	}
}
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import { action, observable, runInAction } from 'mobx';
import baby from '../utils/baby';
import { ph_emptyImageURL } from '../utils/DefaultImage';
import storeFactory from '../utils/StoreFactory';
import languageStore from "./LanguageStore";
import subscriptionStore from './SubscriptionStore';

export default class SearchStore {
	
	@observable isOpen = false;
	@observable isLoading = false;
	@observable query = "";
	@observable finalSearchQuery = "";
	@observable results = [];
	@observable searchApplied = false;
	@observable expandSearchBox = false;
	subjectInfo = {};
	

	constructor(subjectInfo){
		this.subjectInfo = subjectInfo;
		this.executeSearch = _debounce(this.executeSearch, 1000, {leading:false, trailing:true});

	}

	setSearchQuery(){
		this.finalSearchQuery = this.query;
	}

	clearSearchQuery(){
		this.finalSearchQuery = "";
		this.query = "";
	}

	updateSubscriptionRepository(subscriptionInfo, userCategory){
		if(subscriptionInfo)
			subscriptionStore.SubscriptionInfo = subscriptionInfo;
		if(userCategory)
			subscriptionStore.SubscriptionInfo.USER_CATEGORY = userCategory;
	}

	async doSearch(searchString, filterQuery) {
		console.log(" > Firing doSearch");
		try {
			let params = {...this.subjectInfo};
			params.REQUESTED_PAGE = "1";
			params.SEARCHSTRING = searchString;
			if(filterQuery && filterQuery !== "")
				params.FILTER = filterQuery;

			const response = await baby.post('/search', params);
			const {SUBSCRIPTION_INFO, USER_CATEGORY} = response.data;
			this.updateSubscriptionRepository(SUBSCRIPTION_INFO, USER_CATEGORY);
			return response.data.SUBJECTS;
		} 
		catch (error) {
			throw error;
		}
	}

	async executeSearch(searchQuery, filterQuery){
		try {
			const searchResult = await this.doSearch(searchQuery, filterQuery);
			runInAction("Post Search Result",() => {
				this.results = _map( _sortBy(searchResult, 'DATA.Category'), (subject) => {
					return {
						"title": subject.DATA.Name,
						"description": fetchCategoryText(subject.DATA.Type, subject.DATA.Category),
						"image": prepareImageUrl(subject),
						"uid": subject.DATA.uid,
						"type": subject.DATA.Type,
						"category": subject.DATA.Category,
						"key" : subject.DATA.uid
					}
				});
				this.isLoading = false;
			});
		}	
		catch (error) {
			this.isLoading = false;
			storeFactory.ErrorStore.log(error, "Couldn't Load Search", "On Execute Search");
		}
	}

	@action
	async fireSearch(searchQuery, filterQuery) {
		try{
			this.isLoading = true;
			this.query = searchQuery;
			if (this.query.length < 1) {
				this.results = [];
				this.isLoading = false;
				return;
			}
			this.executeSearch(searchQuery, filterQuery);
		}
		catch (error){
			this.isLoading = false;
		    storeFactory.ErrorStore.log(error, "Couldn't Load Search", "On Fire Search");
		}
		finally {
		}
  	}
}

function prepareImageUrl(item){
	let imageUrl = ph_emptyImageURL;
	const imgRefId = item.DATA.ProfileImage;
	if(imgRefId) {
		const imgRef = item.REFERENCE_IMAGES[imgRefId];
		if(imgRef)
			imageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/-/preview/250x100/"; 
	}
	return imageUrl;
}

function fetchTypeText(typeID){
	const type = _find(languageStore.getTypeDictonary(), ['key', typeID]);
	const typeName = type ? type.text : "$$$";
	return typeName;
}

//CategoryDictonary is mapped with moduleId - list of Categories
//the typeID is not same as mdouleId
//need to restructer the getAllModule response w.r.t category structuring
function fetchCategoryText(typeID, categoryID){
	const category = _find(languageStore.getCategoryDictonary()[typeID], ['key', categoryID]);
	const categoryName = category ? category.text : "";
	return categoryName;
}
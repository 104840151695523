import { emitter, EVENTS } from "../../0-common/utils/EventEmitter";
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _compact from 'lodash/compact';

export default class ShopAnalyticsStore{

    _emitterBindList = {};
    _storeFactory = null;

    constructor(storeFactoryRef){
        if( (typeof window !== "undefined") ){
            window.dataLayer = window.dataLayer || [];
        }
        this._storeFactory = storeFactoryRef;
    }

    _bindEvents = () => {
        this._emitterBindList[EVENTS.CART.ADD] = emitter.on(EVENTS.CART.ADD, this.pushAddProductToCartEvent);
        this._emitterBindList[EVENTS.CART.REMOVE] = emitter.on(EVENTS.CART.REMOVE, this.pushRemoveProductFromCartEvent);
        this._emitterBindList[EVENTS.CART.UPDATE] = emitter.on(EVENTS.CART.UPDATE, this.handleUpdateOperationInCart);
        this._emitterBindList[EVENTS.CART.OPEN_DONE] = emitter.on(EVENTS.CART.OPEN_DONE, this.pushCartOpenEvent);

        this._emitterBindList[EVENTS.CART.CHECKOUT.BEGIN] = emitter.on(EVENTS.CART.CHECKOUT.BEGIN, this.pushCartCheckoutEvent.bind(this, "begin_checkout"));
        this._emitterBindList[EVENTS.CART.CHECKOUT.ADD_SHIPPING_INFO] = emitter.on(EVENTS.CART.CHECKOUT.ADD_SHIPPING_INFO, this.pushCartCheckoutEvent.bind(this, "add_shipping_info"));
        this._emitterBindList[EVENTS.CART.CHECKOUT.MAKE_PAYMENT] = emitter.on(EVENTS.CART.CHECKOUT.MAKE_PAYMENT, this.pushCartCheckoutEvent.bind(this, "add_payment_info"));
        this._emitterBindList[EVENTS.CART.CHECKOUT.PURCHASE] = emitter.on(EVENTS.CART.CHECKOUT.PURCHASE, this.pushCartCheckoutEvent.bind(this, "purchase"));
        
        this._emitterBindList[EVENTS.SHOP.PRODUCT.VIEW] = emitter.on(EVENTS.SHOP.PRODUCT.VIEW, this.pushViewProductEvent);

        this._emitterBindList[EVENTS.SHOP.USER.LOGIN] = emitter.on(EVENTS.SHOP.USER.LOGIN, this.pushShopUserLoginEvent);
        this._emitterBindList[EVENTS.SHOP.USER.SIGNUP] = emitter.on(EVENTS.SHOP.USER.SIGNUP, this.pushShopUserSignUpEvent);

        this._emitterBindList[EVENTS.SHOP.ERROR] = emitter.on(EVENTS.SHOP.ERROR, this.pushShopErrorEvent);
        
	}

	_unBindEvents = () => {
		_forEach(this._emitterBindList, (unbindRef, key) => {
			unbindRef();
		})
		this._emitterBindList = {};
	}

    register(){
        this._bindEvents();
    }

    deRegister(){
        this._unBindEvents();
    }

    get Currency(){
        return this._storeFactory.CurrencyStore.Currency;
    } 

    pushAddProductToCartEvent = (productInfo) => {
        const {productId, productOption, productOptionText, productName, productRetailPrice, productDiscountedPrice, productBrand, productCategoryList} = productInfo;
        let productData = {};
        productData.item_id = productId;
        productData.item_name = productName;
        productData.item_brand = productBrand || "Proprietary";
        if(productOption !== 'ONE_SIZE'){
            productData.item_variant = productOptionText;
        }
        if(productCategoryList && productCategoryList.length > 0){
            if(productCategoryList[0]){
                productData.item_category = productCategoryList[0];
            }
            if(productCategoryList[1]){
                productData.item_category2 = productCategoryList[1];
            }
            if(productCategoryList[2]){
                productData.item_category3 = productCategoryList[2];
            }
            if(productCategoryList[3]){
                productData.item_category4 = productCategoryList[3];
            }
            if(productCategoryList[4]){
                productData.item_category5 = productCategoryList[4];
            }
        }
        if(productInfo.context){
            productData.item_list_name = productInfo.context;
        }
        productData.price = productDiscountedPrice;
        productData.quantity = '1';

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: this.Currency.source.toUpperCase(),
                value: productDiscountedPrice,
                items: [productData]
            }
        });
    }

    pushRemoveProductFromCartEvent = (productInfo) => {
        const {productId, productOption, productOptionText, productName, productRetailPrice, productDiscountedPrice, productBrand, productCategoryList} = productInfo;
        let productData = {};
        productData.item_id = productId;
        productData.item_name = productName;
        productData.item_brand = productBrand || "Proprietary";
        if(productOption !== 'ONE_SIZE'){
            productData.item_variant = productOptionText;
        }
        if(productCategoryList && productCategoryList.length > 0){
            if(productCategoryList[0]){
                productData.item_category = productCategoryList[0];
            }
            if(productCategoryList[1]){
                productData.item_category2 = productCategoryList[1];
            }
            if(productCategoryList[2]){
                productData.item_category3 = productCategoryList[2];
            }
            if(productCategoryList[3]){
                productData.item_category4 = productCategoryList[3];
            }
            if(productCategoryList[4]){
                productData.item_category5 = productCategoryList[4];
            }
        }
        if(productInfo.context){
            productData.item_list_name = productInfo.context;
        }
        productData.price = productDiscountedPrice;
        productData.quantity = '1';

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: this.Currency.source.toUpperCase(),
                value: productDiscountedPrice,
                items: [productData]
            }
        });
    }

    handleUpdateOperationInCart = (productInfo, productQuantity, ops) => {
        if(ops === "add"){
            this.pushAddProductToCartEvent(productInfo);
        } else {//Case of subtract
            this.pushRemoveProductFromCartEvent(productInfo)
        }
    }

    pushCartOpenEvent = () => {
        const orderStore = this._storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        const categoryDictionary = this._storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
        const orderItems = orderStore.ItemsInCart;
        const orderItemCount = orderStore.ItemCountInCart;
        const order = orderStore.getCurrentItem();
         
        let totalOrderAmount = orderItemCount === 0 ? "0" : order.DATA.OrderAmount;

        const productsInCart = _map(orderItems, (orderItem, index) => {
            
            let selectedSizeText = "ONE_SIZE";
            if(orderItem.Option !== 'ONE_SIZE'){
                const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
                const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
                if(sizeOption)
                    selectedSizeText  = sizeOption.text;
            }

            const productCategoryList = _compact(_map(orderItem.DATA.Categories, (itemCategoryID) => {
                const category = _find(categoryDictionary, ['key', itemCategoryID]);
                return (category ? category.text : null);
            }));

            const productRetailPrice = (orderItem.DATA.RetailPrice || orderItem.DATA.DiscountedPrice);
            const productDiscountedPrice = orderItem.DATA.DiscountedPrice;

            let productData = {};
            productData.item_id = orderItem.DATA.uid;
            productData.item_name = orderItem.DATA.Name;
            productData.item_brand = orderItem.DATA.Brand || "Proprietary";
            if(selectedSizeText !== 'ONE_SIZE'){
                productData.item_variant = selectedSizeText;
            }
            if(productCategoryList && productCategoryList.length > 0){
                if(productCategoryList[0]){
                    productData.item_category = productCategoryList[0];
                }
                if(productCategoryList[1]){
                    productData.item_category2 = productCategoryList[1];
                }
                if(productCategoryList[2]){
                    productData.item_category3 = productCategoryList[2];
                }
                if(productCategoryList[3]){
                    productData.item_category4 = productCategoryList[3];
                }
                if(productCategoryList[4]){
                    productData.item_category5 = productCategoryList[4];
                }
            }
            productData.index = (index + 1);
            productData.price = productDiscountedPrice;
            productData.quantity = orderItem.Quantity;

            return productData;
        });

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'view_cart',
            ecommerce: {
                currency: this.Currency.source.toUpperCase(),
                value: totalOrderAmount,
                items: productsInCart
            }
        });
    }

    pushCartCheckoutEvent = (checkout_stage, checkout_data) => {
        const orderStore = this._storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        const categoryDictionary = this._storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
        const orderItems = orderStore.ItemsInCart;
        const orderItemCount = orderStore.ItemCountInCart;
        const order = orderStore.getCurrentItem();
         
        let totalOrderAmount = orderItemCount === 0 ? "0" : order.DATA.OrderAmount;

        const productsInCart = _map(orderItems, (orderItem, index) => {
            
            let selectedSizeText = "ONE_SIZE";
            if(orderItem.Option !== 'ONE_SIZE'){
                const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
                const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
                if(sizeOption)
                    selectedSizeText  = sizeOption.text;
            }

            const productCategoryList = _compact(_map(orderItem.DATA.Categories, (itemCategoryID) => {
                const category = _find(categoryDictionary, ['key', itemCategoryID]);
                return (category ? category.text : null);
            }));

            const productRetailPrice = (orderItem.DATA.RetailPrice || orderItem.DATA.DiscountedPrice);
            const productDiscountedPrice = orderItem.DATA.DiscountedPrice;

            let productData = {};
            productData.item_id = orderItem.DATA.uid;
            productData.item_name = orderItem.DATA.Name;
            productData.item_brand = orderItem.DATA.Brand || "Proprietary";
            if(selectedSizeText !== 'ONE_SIZE'){
                productData.item_variant = selectedSizeText;
            }
            if(productCategoryList && productCategoryList.length > 0){
                if(productCategoryList[0]){
                    productData.item_category = productCategoryList[0];
                }
                if(productCategoryList[1]){
                    productData.item_category2 = productCategoryList[1];
                }
                if(productCategoryList[2]){
                    productData.item_category3 = productCategoryList[2];
                }
                if(productCategoryList[3]){
                    productData.item_category4 = productCategoryList[3];
                }
                if(productCategoryList[4]){
                    productData.item_category5 = productCategoryList[4];
                }
            }
            productData.index = (index + 1);
            productData.price = productDiscountedPrice;
            productData.quantity = orderItem.Quantity;

            return productData;
        });

        const ecommerceInfo = {
            currency: this.Currency.source.toUpperCase(),
            value: totalOrderAmount,
            items: productsInCart
        }

        switch (checkout_stage) {
            case "add_shipping_info":
                ecommerceInfo.shipping_tier = "Shipping";
                break;
                
            case "add_payment_info":
                ecommerceInfo.payment_type = checkout_data;
                break;

            case "purchase":
                const shopStore = this._storeFactory.fetchStore("PUBLICWEBSHOPS");
                const shop = shopStore.getCurrentItem();
                const shippingCharge = (shop.DATA.DeliveryCharge || 0);
                const totalPayableAmount = totalOrderAmount + shippingCharge;

                ecommerceInfo.transaction_id = checkout_data;
                ecommerceInfo.affiliation = "Online Store";
                ecommerceInfo.shipping = shippingCharge;
                ecommerceInfo.value = totalPayableAmount;
                break;

            default:
                break;
        }

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: checkout_stage,
            ecommerce: ecommerceInfo
        });
    }

    pushViewProductEvent = (productInfo) => {
        const {productId, productName, productRetailPrice, productDiscountedPrice, productBrand, productCategoryList} = productInfo;
        let productData = {};
        productData.item_id = productId;
        productData.item_name = productName;
        productData.item_brand = productBrand || "Proprietary";
        if(productCategoryList && productCategoryList.length > 0){
            if(productCategoryList[0]){
                productData.item_category = productCategoryList[0];
            }
            if(productCategoryList[1]){
                productData.item_category2 = productCategoryList[1];
            }
            if(productCategoryList[2]){
                productData.item_category3 = productCategoryList[2];
            }
            if(productCategoryList[3]){
                productData.item_category4 = productCategoryList[3];
            }
            if(productCategoryList[4]){
                productData.item_category5 = productCategoryList[4];
            }
        }
        if(productInfo.context){
            productData.item_list_name = productInfo.context;
        }
        productData.price = productDiscountedPrice;
        productData.quantity = '1';

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
                currency: this.Currency.source.toUpperCase(),
                value: productDiscountedPrice,
                items: [productData]
            }
        });
    }

    pushShopUserLoginEvent = (userEmail) => {
        window.dataLayer.push({
            'user_id': userEmail,
            'shop_user_id': userEmail
        });
        window.dataLayer.push({
            'event' : 'login',
	        'loginMethod' : 'email',
            'loginEmail' : userEmail
        });
    }

    pushShopUserSignUpEvent = (userEmail) => {
        window.dataLayer.push({
            'event' : 'sign_up',
	        'signUpMethod' : 'email',
            'signUpEmail' : userEmail
        });
    }

    pushShopErrorEvent = (errorDesc, errorScreen) => {
        window.dataLayer.push({
            'event' : 'exception',
	        'description': errorDesc,
            'screen' : errorScreen,
            'fatal': false
        });
    }
}
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';

import { computed, observable, toJS } from 'mobx';
import baby from '../utils/baby';
import StoreFactory from '../utils/StoreFactory';
import languageStore from "./LanguageStore";


async function getModules() {
	try {
		const response = await baby.post('/getallmodules',{});
		return response;
	} 
	catch (error) {
		throw error;
	}	
}

//Added for stringify in deHydrateStore
String.prototype.escapeSpecialChars = function() {
    return this.replace(/[']/g, "\\'")
			   .replace(/\\"/g, '\\\\"')
			   .replace(/\\n/g, '\\n')
			   .replace(/\\r/g, '\\r')
			   .replace(/\\t/g, '\\t')
			   .replace(/\\b/g, '\\b')
			   .replace(/\\f/g, '\\f');
};

export default class ModuleStore {

	@observable _moduleList = [];
	@observable _application_Support_Number = null;

	constructor(){
		if( (typeof window !== "undefined") && 
			(window.__INITIAL_STATE_MODULE_STORE__) &&
			(window.__INITIAL_STATE_MODULE_STORE__ !== "__INITIAL_STATE_MODULE_STORE__") )
		{
			console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_MODULE_STORE__ FOUND ");
			this.hydrateStore(window.__INITIAL_STATE_MODULE_STORE__);
			delete window.__INITIAL_STATE_MODULE_STORE__;
		}
	}

	@computed 
	get allModuleList(){
		return this._moduleList;
	}

	@computed 
	get moduleList(){
		const visibleModules = _filter(this._moduleList, 'IsVisible');
		return visibleModules;
	}

	@computed
	get SupportNumber(){
		return this._application_Support_Number;
	}

	set SupportNumber(application_Support_Number){
		return this._application_Support_Number = application_Support_Number;
	}

	getModule(moduleName){
		const module = this.getModuleByName(moduleName);
		if(module)
			return module;
		else 
			return this.getModuleByURL(moduleName);
	}

	getModuleCategories(moduleName){
		const module = this.getModule(moduleName);
		if(module)
			return module.Categories;
		else 
			null;
	}

	getModuleTypes(){
		return _flatten(_map(this._moduleList, "Types"));
	}

	getModuleByName(moduleName){
		return _find(this._moduleList, ['uid', moduleName]);
	}

	getModuleByURL(moduleURL){
		return _find(this._moduleList, ['url', moduleURL]);
	}

	getModuleURLByName(moduleName){
		 const module = this.getModuleByName(moduleName);
		 if(module)
		 	return module.url;
		 else 
		 	return moduleName;
	}

	getModuleByType(type){
		const moduleRef = this.getModuleByName(type);
		if(moduleRef) {
			return moduleRef;
		} else {
			const module = _find(this._moduleList, (module) =>{
				return _find(module.Types, ['key', type]);
			});
			return module;
		}
	}

	getModuleCategoriesByType(type){
		const module = this.getModuleByType(type);
		if(module){
			return module.Categories;
		}
		else 
			return null;
	}

	getDefaultModuleName(){
		const module = _find(this._moduleList, 'selected');
		return module.uid;
	}

	setModules(modules){
		this._moduleList = modules;
	}

	processModuleResponseData(translationPack, rawModuleList){
		languageStore.setCurrentLanguagePack(translationPack)
		let modules = rawModuleList.map((module) => {
			module.url = module.uid;
			module.Categories = _map(module.Categories, (category) => {
				category.count = 0;
				return category;
			});
			return module;
		});
		const typeDic = _flatten(_map(modules, "Types"));
		const categoryDic = _mapValues(_keyBy(modules, "uid"), "Categories");
		languageStore.setTypeDictonary(typeDic);
		languageStore.setCategoryDictonary(categoryDic);
		this._moduleList = modules; 
	}
	
	async loadModules(){
		try{
			console.log("ModuleStore >> Load Modules Called....");
			return await getModules();
		}
		catch (error) {
			console.log("Couldn't fetch modules");
			console.log("On Load Modules Error");
			console.error(error);
			StoreFactory.ErrorStore.log(error, "Couldn't fetch modules", "On Load Modules Error"); 	
		}
	}

	deHydrateStore(){
		return JSON.stringify(toJS(this._moduleList)).escapeSpecialChars();
	}

	hydrateStore(storeData){
		this._moduleList = JSON.parse(storeData);
	}
}
const englishPack = {
	"lb_app_logo_header":"Transform your product line into an online store or website in minutes!",
	"lb_app_logo_slider1_caption":"S1",
	"lb_app_logo_slider2_caption":"S2",
	"lb_app_logo_slider3_caption":"S3",
	"lb_app_logo_slider4_caption":"S4",
	"lb_app_logo_slider5_caption":"S5"
};

const hindiPack = {
	"lb_app_logo_header":"एक ऑनलाइन स्टोर या मिनटों में वेबसाइट में अपने उत्पाद लाइन को बदलने!",
	"lb_app_logo_slider1_caption":"परिधान",
	"lb_app_logo_slider2_caption":"सामान",
	"lb_app_logo_slider3_caption":"जूते",
	"lb_app_logo_slider4_caption":"आभूषण",
	"lb_app_logo_slider5_caption":"असबाब"
};

const languagePackRepository = {"en" : englishPack, "hi" : hindiPack};

export const languagePack = (lang) => {
	return languagePackRepository[lang];
}
const englishPack = {
	"lb_app_logo_header":"Welcome to Cookieland",
	"lb_app_logo_slider1_caption":"Nuts Range",
	"lb_app_logo_slider2_caption":"Snack Bars",
	"lb_app_logo_slider3_caption":"Choco & Indulgence Range",
	"lb_app_logo_slider4_caption":"Daily Favorites",
	"lb_app_logo_slider5_caption":"Breakfast Special"
};

const hindiPack = {
	"lb_app_logo_header":"एक ऑनलाइन स्टोर या मिनटों में वेबसाइट में अपने उत्पाद लाइन को बदलने!",
	"lb_app_logo_slider1_caption":"परिधान",
	"lb_app_logo_slider2_caption":"सामान",
	"lb_app_logo_slider3_caption":"जूते",
	"lb_app_logo_slider4_caption":"आभूषण",
	"lb_app_logo_slider5_caption":"असबाब"
};

const languagePackRepository = {"en" : englishPack, "hi" : hindiPack};

export const languagePack = (lang) => {
	return languagePackRepository[lang];
}
import jsonPatch from '@libs/fast-json-patch';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import { action, runInAction } from 'mobx';
import ItemStore from '../../0-common/store/ItemStore';
import baby from '../../0-common/utils/baby';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';

export default class CollectionStore extends ItemStore {

	FACTORY_STORE_TYPE = "COLLECTIONS";

	getOpenContentTabIDs(){
		return ["ProductInCollection","Details"];
	}

	@action
	executeAddToCollectionAction(selectedItemsToBeAddedInCollection){
		this.addProductToSelectedCollection(selectedItemsToBeAddedInCollection);
	}

	addProductToSelectedCollection(selectedItemsToBeAddedInCollection){
		const selectedColls = this.getSelectedItems();
		_forEach(selectedColls.selectdItem, this.executeAddProductsToCollection.bind(this, selectedItemsToBeAddedInCollection.selectdItem));
	}

	/*async executeCollectionUpdate(productIds, collectionId){
		try{
			const predicateID = "ProductsInCollection";
			const collectionItem = this.getItemById(collectionId);
			const patchObserver = jsonPatch.observe(collectionItem.DATA);
			
			if(_hasIn(collectionItem.DATA, predicateID)) {
				_forEach(productIds, (productID) => {
					collectionItem.DATA[predicateID].push(productID);
				});
	  		} else {
				collectionItem.DATA[predicateID] = productIds;
	  		}
			
			const patch = jsonPatch.generate_new(patchObserver);
			const itemInfo = {
								"uid" : collectionItem.DATA.uid,
								"Type" : collectionItem.DATA.Type,
								"Category" : collectionItem.DATA.Category
							};
			const response = await this.saveSubject(itemInfo, patch);
		}
		catch (error){
		    this.ErrorStore.log(error, "Couldn't Update Collection for ProductsInCollection", "On executeCollectionUpdate Error");
		}
		finally {
		}
	}*/

	@action
	async executeAddProductsToCollection(productIds, collectionId){
		try{
			this.isLoading = true;
			const patch = _map(productIds, (productId) => {
				return {op: "add", path: "/ProductsInCollection", value: productId};
			})
			const responseData = await this.addProductsToCollectionService(patch, collectionId);
			runInAction("On Add Products To Collection", () => {
				this.currentItem = responseData.SUBJECT;
				this.patchObserver = jsonPatch.observe(this.currentItem.DATA);
				responseData.onComplete();
			});
		} catch(error){
			this.ErrorStore.log(error, "Couldn't execute executeAddProductsToCollection", "On executeAddProductsToCollection");
		} finally {
			this.isLoading = false;
			emitter.emit(EVENTS.PREDICATE.ADDITEM.DONE+'#'+"ProductsInCollection");
		}
	}

	async executeFetchCollectionForShop(collectionId, collectionCategory, sellerID, shopID) {
		try {
			this.isLoading = true;
			this.currentItem = {};
			const itemInfo = {
				"uid" : collectionId,
				"Type" : "COLLECTIONS",
				"Category" : collectionCategory,
				"APP_OWNER" : sellerID,
				"ShopID" : shopID
			};
			const response = await this.fetchCollectionForShopService(itemInfo);
			runInAction("On Fetch Collection For Shop Success",() => {
				this.currentItem = response.SUBJECT;
				this.ModuleStore.processModuleResponseData(response.TRANSLATIONS, response.MODULES);
				response.onComplete();
			});
		}
		catch (error){
			runInAction("On Fetch Collection For Shop Error", () => {
				this.isLoading = false;
				this.ErrorStore.log(error, "Couldn't perform Fetch Collection For Shop", "On Fetch Collection For Shop Error"); 
			});
		} 
		finally {
			this.isLoading = false; 
		}
	}

	get CollectionProfileImage(){
		let profileImage = null;
		if(this.getCurrentItem()){
			profileImage = this.currentItem.DATA.ProfileImage;
			if(profileImage && profileImage !== ""){
				const profileImageRef = this.currentItem.REFERENCE_IMAGES[profileImage];
				if(profileImageRef){
					profileImage = `https://ucarecdn.com/${profileImageRef.SourceID}/-/format/auto/-/quality/smart/`;
				} else {
					profileImage = null;
				}
			} else {
				profileImage = null;
			}
		}
		return profileImage;
	}

	get CollectionCategorySource(){
		let categoryName = "";
		if(this.getCurrentItem()){
			categoryName = this.currentItem.DATA.CategorySourceID;
		}
		return categoryName;
	}

	get CollectionID(){
		let collectionID = "";
		if(this.getCurrentItem()){
			collectionID = this.currentItem.DATA.uid;
		}
		return collectionID;
	}

	get CollectionName(){
		let collectionName = "";
		if(this.getCurrentItem()){
			collectionName = this.currentItem.DATA.Name;
		}
		return collectionName;
	}

	get CollectionDescription(){
		let collectionDescription = "";
		if(this.getCurrentItem()){
			collectionDescription = this.currentItem.DATA.Description;
		}
		return collectionDescription;
	}

	getCollectionMetaData(shopURL, shopURLType, collectionPageID){
		const collectionURL = `${shopURL}/shop/${collectionPageID}/${this.CollectionCategorySource}/${this.CollectionID}`;
		let metaRobotData = "index,follow";

		if(shopURLType === 'Standard'){
			metaRobotData = "noindex,nofollow";
		}
		return {
			og_title_data : this.CollectionName,
			og_desc_data :  this.CollectionDescription,
			og_image_data : this.CollectionProfileImage,
			og_site_name_data : this.CollectionName,
			og_url_data : collectionURL,
			meta_robot_data: metaRobotData
		}
	}

	/* ==========================================  Service Layer  ==========================================  */


	async addProductsToCollectionService(patch, collectionId){
		try{
		  const collectionItem = this.getItemById(collectionId);
		  const param = {
			  "uid" : collectionItem.DATA.uid,
			  "Type" : collectionItem.DATA.Type,
			  "Category" : collectionItem.DATA.Category,
			  "PATCH" : patch
		  };
		  const serviceResponse = await baby.post('/addproductstocollection', param);
		  const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data)
		  return response;
		}
		catch (error){
		  this.ErrorStore.log(error, "Couldn't Add Product To Collection", "On addProductsToCollectionService Error");
		}
		finally {
		}
	}

	async fetchCollectionForShopService(itemInfo) {
	    try {
	      const serviceResponse = await baby.post('/publicreadproductinshop', itemInfo);
	      const response = super.processResponseDataForCreateUpdateRead(serviceResponse.data);
	      return response;
	    } 
	    catch (error) {
	      console.error("fetchCollectionForShopService Failed with Error : "+error);
	      throw error;
	    }       
	}
	
}